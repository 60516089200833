import React, { useEffect, useState } from "react";
import { Button, Card, OverlayTrigger, Popover } from "react-bootstrap";
import { BellFill } from "react-bootstrap-icons"; // assuming you're using bootstrap-icons
import { apiHalper } from "../../../helpers/ApiHelper";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const NotificationsComp = () => {
  const [show, setShow] = useState(false);
  const handleToggle = () => setShow((prev) => !prev);
  const [notifications, setNotifications] = useState([]);
  const { push } = useHistory();
  async function getNotifications() {
    try {
      const response = await apiHalper(
        "notifications/?limit=1000&page=1&isRead=0",
        "GET",
        null
      );
      const res = response.data;
      setNotifications(res.data);
    } catch (error) {}
  }
  async function handleNotificationClick(id) {
    try {
      await apiHalper(`notifications/${id}`, "PATCH");
      push("/mawthooqRequestList");
      handleToggle();
    } catch (error) {}
  }
  const popover = (props) => (
    <Popover id="popover-notifications" {...props}>
      {notifications.map((notification, index) => (
        <NotificationsItem
          key={index}
          notification={notification}
          onClick={() => handleNotificationClick(notification.id)}
        />
      ))}
    </Popover>
  );
  useEffect(() => {
    !show && getNotifications();
  }, [show]);
  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      show={show}
      overlay={popover}
      rootClose
      onToggle={handleToggle}
    >
      <Button variant="dark" onClick={() => setShow(true)}>
        <BellFill size={24} />
      </Button>
    </OverlayTrigger>
  );
};

export default NotificationsComp;
function NotificationsItem(props) {
  const { notification, onClick } = props;
  return (
    <Card
      onClick={onClick}
      key={notification.id}
      className={`mb-2 text-dark `}
      style={{
        backgroundColor: !notification.isRead ? "#e0e0e0" : "#ffffff", // Custom middle gray for read items
        cursor: "pointer", // Make it look clickable
      }}
    >
      <Card.Body>
        <Card.Title>{notification.title}</Card.Title>
        <Card.Text>{moment(notification.date).format("MM/DD/YY")}</Card.Text>
      </Card.Body>
    </Card>
  );
}
