import React, { useState, useEffect } from "react";
import DataTable from "../JqueryDataTable";
import { toast } from "react-toastify";
import { getAllCategories } from "./CategoriesApiHelper";
import CategoriModal from "./CategoriModal";
import DeleteCategoriModal from "./DeleteCategoriModal";
import "react-intl-tel-input/dist/main.css";
import { apiHalper } from "../../helpers/ApiHelper";
import StatusField from "./StatusField";
import ActionField from "./ActionField";
import HeaderSpan from "./HeaderSpan";
import NoDataIndication from "../TableLoader";

const Categories = () => {
  const [categoryList, setCategoryList] = useState([]);
  const [updateData, setUpdateData] = useState();
  const [loading, setLoading] = useState(true);
  const [isCategoryModal, setIsCategoryModal] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [deleModal, setDeleModal] = useState({
    isDeleModal: false,
    deleteId: "",
  });

  const getPage = async (page) => {
    setLoading(true);
    const res = await getAllCategories(
      `getAllServiceCategories?page_no=${page}`,
      "GET"
    );
    if (res?.data?.status === true) {
      setCategoryList(res?.data?.data?.categories);
      setTotalRecord(res?.data?.pagination?.totalItems);
    }
    setLoading(false);
  };

  useEffect(() => {
    getPage(1);
  }, []);

  const handleToggle = async (e, id) => {
    const target = categoryList.find((data) => data.id === id);
    if (target.status === e) {
      return;
    }
    const headers = {
      Authorization: localStorage.getItem("token"),
    };

    const res1 = await apiHalper(
      "toggleServiceCategorystatus",
      "PATCH",
      { id: id },
      headers
    );
    if (res1.data.status === true) {
      toast.success("Status Update Successfully!");
      const NewData = categoryList.map((data) => {
        if (data.id === id) {
          data.status = e;
        }
        return data;
      });
      setCategoryList(NewData);
    } else {
      toast.error("Status Update  Failed");
    }
  };

  const openDeleModal = (id) => {
    setDeleModal({
      isDeleModal: true,
      deleteId: id,
    });
  };

  const cancelDelete = () => {
    setDeleModal({
      isDeleModal: false,
    });
  };
  const closeModal = () => {
    setIsCategoryModal(false);
  };
  const handleTableChange = (
    type,
    { page, sizePerPage, searchText, sortField, sortOrder, data }
  ) => {
    setLoading(true);

    let result;
    if (type === "search") {
      if (!searchText) {
        getPage(1);
      } else {
        const getdata = async () => {
          const res = await getAllCategories(
            `searchServiceCategories?search_text=${searchText}`,
            "GET"
          );
          if (res.data.status === true) {
            setCategoryList(res?.data?.data?.categories);
            setTotalRecord(res?.data?.pagination?.totalItems);
          }
        };
        getdata();
      }
    }
    if (sortOrder === "asc") {
      result = data.sort((a, b) => {
        if (a[sortField] > b[sortField]) {
          return 1;
        } else if (b[sortField] > a[sortField]) {
          return -1;
        }
        return 0;
      });
    } else {
      result = data.sort((a, b) => {
        if (a[sortField] > b[sortField]) {
          return -1;
        } else if (b[sortField] > a[sortField]) {
          return 1;
        }
        return 0;
      });
    }
    setCategoryList(result);
    if (type === "pagination") {
      setPageNumber(page);
      getPage(page);
    }
    setLoading(false);
  };

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      headerFormatter: (column) => <HeaderSpan column={column} />,
    },
    {
      dataField: "name_ar",
      text: "Name Ar",
      sort: true,
      headerFormatter: (column) => <HeaderSpan column={column} />,
    },
    {
      dataField: "tag_count",
      text: "Tag Count",
      sort: true,
      headerFormatter: (column) => <HeaderSpan column={column} />,
    },

    {
      dataField: "status",
      text: "Status",
      sort: true,
      headerFormatter: (column) => <HeaderSpan column={column} />,
      formatter: (cell, row) => (
        <StatusField cell={cell} row={row} handleToggle={handleToggle} />
      ),
    },
    {
      dataField: "action",
      text: "Action",
      headerFormatter: (column) => <HeaderSpan column={column} />,
      formatter: (cell, row) => (
        <ActionField
          row={row}
          setUpdateData={setUpdateData}
          setIsCategoryModal={setIsCategoryModal}
          setIsAdd={setIsAdd}
          openDeleModal={openDeleModal}
        />
      ),
    },
  ];

  const tableDatas = {
    columns: columns,
    data: categoryList,
    keyId: "id",
    loading: loading,
    message: "Table is Empty",
    noDataIndication: <NoDataIndication />,
    page: pageNumber,
    totalRecord: totalRecord,
    sizePerPage: 10,
    handleTableChange: handleTableChange,
  };

  return (
    <>
      <section className="bg-balance">
        <div className="balance_wrap">
          <div className="btn_groupsB">
            <button
              type="button"
              className="btn_addUsers"
              onClick={() => {
                setIsCategoryModal(!isCategoryModal);
                setIsAdd(true);
              }}
            >
              Add category
            </button>
          </div>
          <DataTable TableData={tableDatas} />
          <DeleteCategoriModal
            isModal={deleModal.isDeleModal}
            id={deleModal.deleteId}
            handleClose={cancelDelete}
            getPage={getPage}
            pageNumber={pageNumber}
          />
          <CategoriModal
            setUpdateData={setUpdateData}
            updateData={updateData}
            isCategoryModal={isCategoryModal}
            closeModal={closeModal}
            isAdd={isAdd}
            getPage={getPage}
            pageNumber={pageNumber}
            setIsAdd={setIsAdd}
            categoryList={categoryList}
            setCategoryList={setCategoryList}
          />
        </div>
      </section>
    </>
  );
};

export default Categories;
