import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import { apiHalper } from "../../helpers/ApiHelper";
import { jwtDecode } from "../../helpers/jwt_helper";
import Select from "react-select";
import moment from "moment";
import DatePicker from "react-datepicker";
import Resume_icon from "../../assets/images/resume_icon.svg";
import LoaderImg from "../../assets/images/user-loader.gif";
import DeleteIcon from "../../assets/images/delete_icon.svg";
import EditIcon from "../../assets/images/edit_icon.svg";
import ConfirmModal from "../ConfirmModal";
import { toster_error, toster_sucess } from "../../helpers/toster_helper";
import { Badge, Button, Col, Row } from "react-bootstrap";
import * as ACTION_TYPES from "../../redux/actions/types";
import DeleteModal from "./DeleteModal";
import CategoryTagJSX from "./CategoryTagJSX";

class ProfileTab extends Component {
  _unmounted = false;
  constructor(props) {
    super(props);
    this.state = {
      isType: "",
      isUserProModal: false,
      isUserProModalClient: false,
      isUserLoader: false,
      userError: "",
      isEdit: false,
      genderOptions: [
        { value: 2, label: "Male" },
        { value: 1, label: "Female" },
      ],
      gender: "",
      bussiness_email: "",
      bussiness_email_status: "",
      profile_invisible: 1,
      userDetails: {},
      allLang: [],
      allLangBack: [],
      selectedLanguages: [
        {
          selectedLang: "",
          selectedLevel: "",
        },
      ],
      skillsCount: 0,
      isSkillSLoader: true,
      isSkillAPILoader: false,
      isPrevSearchSkill: false,
      allSkill: [],
      allSkillBack: [],
      pageSkill: 1,
      searchSkill: "",
      agentSkills: [],
      editSkill: null,
      editLang: null,
      isLangLoader: false,
      langErr: "",
      selectedLanglist: [],
      degree: "",
      university: "",
      startDate: "",
      endDate: "",
      firstname: "",
      lastname: "",
      username: "",
      email: "",
      password: "",
      minprice: "",
      maxprice: "",
      countryCode: "966",
      mobile: "",
      company: "",
      brand: "",
      mobileError: "",
      mobileValid: false,
      eduFormError: {
        degree: "",
        university: "",
      },
      eduFormValid: {
        degree: false,
        university: false,
      },
      formError: {
        firstname: "",
        lastname: "",
        username: "",
        email: "",
        password: "",
        gender: "",
        bussiness_email: "",
        brand: "",
        company: "",
      },
      formValid: {
        firstname: false,
        lastname: false,
        gender: false,
        bussiness_email: false,
        username: false,
        email: false,
        password: false,
        brand: false,
        company: false,
      },
      isAddLang: false,
      isAddCategory: false,
      isEditLang: false,
      isEditLangID: "",
      langArray: [],
      selectLang: [],
      langLevel: [],
      eduLevel: [],
      isExpertise: false,
      headline: "",
      summary: "",
      isHeadline: false,
      isSummary: false,
      isELoader: false,
      isESLoader: false,
      expErr: "",
      expertArr: [],
      optionsExp: [],
      defaultExpert: null,
      editExpert: null,
      selectExpert: null,
      selectExp: null,
      isSkillsO: false,
      selectSkills: null,
      sSkillsLevel: null,
      isSLoader: false,
      skillErr: "",
      officeBaseVal: null,
      homeBaseVal: null,
      fixedPrice: null,
      hourPrice: null,
      partTime: null,
      fullTime: null,
      freeA: null,
      isEHistory: false,
      isEducation: false,

      isAddMawthooq: false,
      mawthooqType: 0,
      mawthooq: "",
      mawthooq_image_url: "",
      mawthooq_id: "",
      issue_date: "",
      expiry_date: "",
      formMawthooqError: {
        mawthooq: "",
        mawthooq_id: "",
        issue_date: "",
        expiry_date: "",
      },
      formMawthooqValid: {
        mawthooq: false,
        mawthooq_id: false,
        issue_date: false,
        expiry_date: false,
      },

      isAddPayment: false,
      isEditPayment: false,
      editBankId: "",
      banksList: [],
      connectedBanksList: [],
      bankAttachmentURL: "",
      selectedBank: "",
      ibanPrefix: "SA",
      name: "",
      ibanNumber: "",
      bankAttachment: "",
      isPrimary: 0,
      formBankError: {
        selectedBank: "",
        name: "",
        ibanNumber: "",
        // bankAttachment: '',
      },
      formBankValid: {
        selectedBank: false,
        name: false,
        ibanNumber: false,
        // bankAttachment: false,
      },
      isAddTags: false,
      tagsOption: [],
      agentAllTags: [],
      selectedTags: [],
      isAddCategory: false,
      categories: [],
      categoriesAllData: [],
      selectedCategory: "",
      addCategoryList: '',

      isAddWebsite: false,
      website: "",

      isAddSocialMedia: false,
      isEditSocialMedia: false,
      editSocialMediaId: "",
      socialMediaTypeList: [],
      socialMediaplatformsTypeList: [],
      connectedSocialMediaList: [],
      socialMedias: [
        {
          socialMediaplatformsTypeList: [],
          socialMediaType: "",
          socialMediaplatformsType: "",
          socialMediaUserName: "",
          socialMediaFollowers: 0,
          socialMediaprifix: "",
          selectedSocialMediaType: "",
          selectedSocialMediaplatformsType: "",
        },
      ],
      socialMediaError: "",
      isAddVatReg: false,
      vatType: 2,
      vatNumber: "",
      vatAttachment: "",
      vat_url: "",
      vat_registration_id: "",
      formVatError: {
        vatNumber: "",
        vatAttachment: "",
      },
      formVatValid: {
        vatNumber: false,
        vatAttachment: false,
      },

      isAddCR: false,
      cr_number: "",
      commercial_certi: "",
      cr_url: "",
      commercial_registration_id: "",
      formCrError: {
        cr_number: "",
        commercial_certi: "",
      },
      formCrValid: {
        cr_number: false,
        commercial_certi: false,
      },

      isAgencyEdit: false,
      agencyId: 0,
      agencyName: "",
      name_public: 0,
      about: "",
      about_public: 0,
      agencyEmail: "",
      email_public: 0,
      agencyWebsite: "",
      website_public: 0,
      address: "",
      address_public: 0,
      agencyPhone: "",
      phone_public: 0,
      agencyPhoneCode: "",
      formErrorAgency: {
        agencyName: "",
        about: "",
        agencyEmail: "",
        agencyWebsite: "",
        address: "",
        agencyPhone: "",
      },
      formValidAgency: {
        agencyName: false,
        about: false,
        agencyEmail: false,
        agencyWebsite: false,
        address: false,
        agencyPhone: false,
      },
    };
  }

  getQueryStringUrl = () => {
    const {
      location: { search },
    } = this.props;
    var url = new URLSearchParams(search);
    this.setState({
      isType: url.get("type"),
    });
    return url.get("type");
  };

  componentDidMount() {
    this._unmounted = true;
    const type = this.getQueryStringUrl();
    if (type === "agents") {
      this.getMawthooqDetails();
      this.getViewAgentProfile();
      this.getAllCategories();
      this.getAllAgentCategories();
      this.getAllTags();
      this.getAllAgentTags();
      this.getAllBanksList();
      this.getAllSocialMediaList();
    }
    if (type === "clients") this.getViewClientProfile();
    this.getLanguages();

    const optionsExp = [
      { label: "Less than 1 year", value: 0 },
      { label: "1-3 years", value: 1 },
      { label: "4-6 years", value: 2 },
      { label: "7-9 years", value: 3 },
      { label: "10-12 years", value: 4 },
      { label: "13-15 years", value: 5 },
      { label: "16-18 years", value: 6 },
      { label: "19-21 years", value: 7 },
      { label: "22-24 years", value: 8 },
      { label: "25-27 years", value: 9 },
      { label: "28-30 years", value: 10 },
      { label: "31+ years", value: 11 },
    ];

    if (this._unmounted) {
      this.setState({
        optionsExp: optionsExp,
      });
    }
  }

  componentWillUnmount() {
    this._unmounted = false;
  }


  componentDidUpdate(prevProps) {
    const { userDetails } = this.props;
    if (prevProps.userDetails !== userDetails) {

      if (this.props.type === "agents") {
        this.setState({
          connectedBanksList: userDetails?.banks?.data,
          userDetails: userDetails,
          agencyId: userDetails?.profile_agencies?.id,
          agencyName: userDetails?.profile_agencies?.name,
          agencyPhone: userDetails?.profile_agencies?.phone,
          agencyEmail: userDetails?.profile_agencies?.email,
          agencyWebsite: userDetails?.profile_agencies?.website,
          about: userDetails?.profile_agencies?.about,
          address: userDetails?.profile_agencies?.address,
          name_public: userDetails?.profile_agencies?.name_public || 0,
          phone_public: userDetails?.profile_agencies?.phone_public || 0,
          email_public: userDetails?.profile_agencies?.email_public || 0,
          about_public: userDetails?.profile_agencies?.about_public || 0,
          website_public: userDetails?.profile_agencies?.website_public || 0,
          address_public: userDetails?.profile_agencies?.address_public || 0,
          selectedCategory: {
            value: userDetails?.expertise?.id,
            label: userDetails?.expertise?.name,
          },
          website: userDetails?.websites,
          mobileValid: userDetails?.contact_no ? true : false,
        });
      } else {
        this.setState({
          userDetails: userDetails,
          brand: userDetails?.brand_name || "",
          company: userDetails?.company_name || "",
          cr_number: userDetails?.cr_number,
          cr_url: userDetails?.cr_file,
          commercial_registration_id:
            userDetails?.commercial_registration_id || "",
          vatNumber: userDetails?.vat_number,
          vatType: userDetails?.vat_number ? 1 : 2,
          vat_url: userDetails?.vat_file,
          vat_registration_id: userDetails?.vat_registration_id || "",
        });
      }
    }


  }

  getAllSocialMediaList = async (e) => {
    try {
      this.setState({
        isLoader: true,
      });
      const headers = {
        Authorization: localStorage.getItem("token"),
      };
      const { id } = this.props.match.params;
      let formData = new FormData();
      formData.append("profile_id", id);
      const response = await apiHalper("getSocialMedias", "GET", null, headers);
      const selectedResponse = await apiHalper(
        "getConnectedSocialPlatform",
        "POST",
        formData,
        headers
      );
      const res = response.data;
      const resSelected = selectedResponse.data;

      if (!res.status) {
        this.setState({
          socialMediaTypeList: [],
          isLoader: false,
        });
      }
      const socialMediaList = jwtDecode(res.data);
      const selectedSocialMediaList = jwtDecode(resSelected.data);
      const sortList = socialMediaList.data.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });
      selectedSocialMediaList.map((social) => {
        const filterSelected = socialMediaList.data.filter(
          (item) => item.id === social.social_platform_id
        );
        social.typeName = filterSelected[0]?.name;
        return social;
      });

      this.setState({
        connectedSocialMediaList: selectedSocialMediaList,
        socialMediaTypeList: sortList.map((info) => ({
          value: info.id,
          label: info.name,
          platforms: info.platforms,
        })),
        isLoader: false,
      });
    } catch (err) {
      toster_error(err.message);
    }
  };

  getAllTags = async (e) => {
    try {
      this.setState({
        isLoader: true,
      });
      const response1 = await apiHalper(`getAllServiceCategoryTags?limit=1000`, "GET", null);
      const res = response1.data;
      if (!res.status) {
        this.setState({
          tagsOption: [],
          isLoader: false,
        });
      }
      this.setState({
        tagsOption: response1?.data?.data?.tags.map((info) => ({
          value: info.id,
          label: info.name,
        })),
        isLoader: false,
      });
    } catch (err) {
      toster_error(err?.message);
    }
  };
  getAllAgentTags = async (e) => {
    const { id } = this.props.match.params;
    try {
      this.setState({
        isLoader: true,
      });
      const response1 = await apiHalper(`getProfileTags/${id}`, "GET", null);
      const res = response1.data;
      if (!res.status) {
        this.setState({
          tagsOption: [],
          isLoader: false,
        });
      }
      this.setState({
        agentAllTags: response1?.data?.data,
        isLoader: false,
      });

    } catch (err) {
      toster_error(err?.message);
    }
  };
  getAllCategories = async (e) => {
    try {
      const { userDetails } = this.state;
      this.setState({
        isLoader: true,
      });
      const response = await apiHalper("getAllServiceCategories?limit=1000", "GET", null);
      const res = response.data;
      if (!res.status) {
        this.setState({
          categories: [],
          isLoader: false,
        });
      }
      const sortList = res?.data?.categories.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });
      this.setState({
        categories: res?.data?.categories.map((info) => ({
          value: info.id,
          label: info.name,
        })),
        categoriesAllData: res?.data?.categories,
        selectedCategory: userDetails.service_category_id
          ? sortList.find((info) => info.id === userDetails.service_category_id)
          : "",
        isLoader: false,
      });

    } catch (err) {
      toster_error(err.message);
    }
  };
  getAllAgentCategories = async (e) => {
    try {
      const { id } = this.props.match.params;
      this.setState({
        isLoader: true,
      });
      const response = await apiHalper(`getProfileServiceCategories/${id}`, "GET", null);
      const res = response.data;
      if (!res.status) {
        this.setState({
          categories: [],
          isLoader: false,
        });
      }
      this.setState({
        addCategoryList: response?.data?.data,
        isLoader: false,
      });

    } catch (err) {
      toster_error(err.message);
    }
  };


  getAllBanksList = async (e) => {
    try {
      this.setState({
        isLoader: true,
      });
      const headers = {
        Authorization: localStorage.getItem("token"),
      };
      const response = await apiHalper("getBankLists", "GET", null, headers);
      const res = response.data;
      if (!res.status) {
        this.setState({
          banksList: [],
          isLoader: false,
        });
      }
      const selectedBanksList = jwtDecode(res.data);
      const sortList = selectedBanksList.banks.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });
      this.setState({
        banksList: sortList.map((info) => ({
          value: info.id,
          label: info.name,
        })),
        isLoader: false,
      });
    } catch (err) {
      toster_error(err.message);
    }
  };

  handleTagsChange = (selectedTags) => {
    this.setState({ selectedTags });
  };
  handleCategoryChange = (selectedCategory) => {
    this.setState({ selectedCategory });
  };
  handleBankChange = (selectedBank) => {
    this.setState({
      selectedBank,
      formBankError: {
        ...this.state.formBankError,
        selectedBank: "",
      },
      formBankValid: {
        ...this.state.formBankValid,
        selectedBank: true,
      },
    });
  };
  getViewClientProfile = async () => {
    try {
      this.setState({
        isLoader: true,
      });

      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      const { id } = this.props.match.params;

      let formData = new FormData();
      formData.append("profile_id", id);

      const response = await apiHalper(
        "viewClientProfile",
        "POST",
        formData,
        headers
      );
      const res = response.data;
      if (res.status) {
        const data = jwtDecode(res.data);
        const { addDetails } = this.props;
        addDetails(data);
        let countryCode1 = "";
        let mobile_no;
        if (data?.contact_no.includes(".")) {
          mobile_no = `+${data?.contact_no.replace(".", "")}`;
          countryCode1 = data.contact_no.split(".")[0];
        } else {
          mobile_no = `+${data?.contact_no}`;
        }
        this.setState({
          isLoader: false,
          userDetails: data,
          firstname: data.first_name,
          lastname: data.last_name,
          username: data.username,

          password: "",
          selectedLanglist: data.language,
          mobileError: "",
          mobileValid: true,
          formError: {
            firstname: "",
            lastname: "",
            username: "",

            email: "",
            password: "",
            brand: "",
            company: "",
          },
          formValid: {
            firstname: true,
            lastname: true,

            username: true,
            email: true,
            password: true,
            brand: true,
            company: true,
          },
          countryCode: countryCode1,
          mobile: mobile_no,
          email: data.email,
          headline: data.headlines,
          summary: data.summaries,
        });

        switch (data.workbase) {
          case "0":
            this.setState({
              officeBaseVal: 1,
              homeBaseVal: 0,
            });
            break;
          case "1":
            this.setState({
              officeBaseVal: 0,
              homeBaseVal: 1,
            });
            break;
          case "2":
            this.setState({
              officeBaseVal: 1,
              homeBaseVal: 1,
            });
            break;
          case "3":
            this.setState({
              officeBaseVal: 0,
              homeBaseVal: 0,
            });
            break;
          default:
            break;
        }
      } else {
        this.setState({
          isLoader: false,
        });
      }
    } catch (err) {
      toster_error(err.message);
    }
  };
  getViewAgentProfile = async () => {
    try {
      this.setState({
        isLoader: true,
      });

      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      const { id } = this.props.match.params;

      let formData = new FormData();
      formData.append("profile_id", id);
      const response = await apiHalper(
        "viewAgentProfile",
        "POST",
        formData,
        headers
      );
      const res = response.data;

      if (res.status) {
        const data = jwtDecode(res.data);
        const { addDetails } = this.props;
        addDetails(data);

        let mobile_no;
        let countryCode1 = "";
        if (data?.contact_no?.includes(".")) {
          mobile_no = `+${data?.contact_no?.replace(".", "")}`;
          countryCode1 = data?.contact_no?.split(".")[0];
        } else {
          mobile_no = data?.contact_no === null ? "" : `+${data?.contact_no}`;
        }
        this.setState({
          isLoader: false,
          userDetails: data,
          firstname: data.first_name,
          lastname: data.last_name,
          gender:
            data.gender == 2
              ? { value: 2, label: "Male" }
              : data.gender == 1
                ? { value: 1, label: "Female" }
                : "",
          bussiness_email:
            data?.bussiness_email === "null" ? "" : data?.bussiness_email,
          bussiness_email_status: data.show_email,
          username: data.username,
          minprice: data.min_price,
          maxprice: data.max_price, //here
          password: "",
          selectedLanglist: data.language,
          formError: {
            firstname: "",
            lastname: "",
            gender: "",
            bussiness_email: "",
            username: "",
            email: "",
            password: "",
          },
          formValid: {
            firstname: true,
            lastname: true,
            gender: true,
            bussiness_email: true,
            username: true,
            email: true,
            password: true,
          },
          countryCode: countryCode1,
          mobile: mobile_no,
          email: data.email,
          headline: data.headlines,
          summary: data.summaries,
          fixedPrice: data.profile_pay_types[0].status,
          hourPrice: data.profile_pay_types[1].status,
          partTime: data.profile_pay_types[2].status,
          fullTime: data.profile_pay_types[3].status,
          freeA: data.profile_pay_types[4].status,
          agentSkills: data.skills,
          defaultExpert: data.expertise,
          formErrorAgency: {
            agencyName: "",
            agencyEmail: "",
            agencyPhone: "",
            agencyWebsite: "",
            about: "",
            address: "",
          },
          formValidAgency: {
            agencyName: true,
            agencyEmail: true,
            agencyPhone: true,
            agencyWebsite: true,
            about: true,
            address: true,
          },
        });
        switch (data.workbase) {
          case "0":
            this.setState({
              officeBaseVal: 1,
              homeBaseVal: 0,
            });
            break;
          case "1":
            this.setState({
              officeBaseVal: 0,
              homeBaseVal: 1,
            });
            break;
          case "2":
            this.setState({
              officeBaseVal: 1,
              homeBaseVal: 1,
            });
            break;
          case "3":
            this.setState({
              officeBaseVal: 0,
              homeBaseVal: 0,
            });
            break;
          default:
            break;
        }
      } else {
        this.setState({
          isLoader: false,
        });
      }
    } catch (err) {
      toster_error(err.message);
    }
  };

  getExpertiseApi = async () => {
    try {
      if (this._unmounted) {
        this.setState({
          isELoader: true,
        });
      }

      const headers = {
        Authorization: localStorage.getItem("token"),
        //"Access-Control-Allow-Origin": '*'
      };

      const response = await apiHalper("getMainServices", "GET", null, headers);
      const res = response.data;
      if (res.status) {
        const data = jwtDecode(res.data);
        const option = Array.isArray(data)
          ? data.map(({ id, name }) => ({ value: id, label: name }))
          : [];
        if (this._unmounted) {
          this.setState({
            expertArr: option,
            isELoader: false,
          });
        }
      } else {
        if (this._unmounted) {
          this.setState({
            expertArr: [],
            isELoader: false,
          });
        }
      }
    } catch (err) {
      toster_error(err);
      if (this._unmounted) {
        this.setState({
          expertArr: [],
          isELoader: false,
        });
      }
    }
  };
  getMawthooqDetails = async () => {
    try {
      const headers = {
        Authorization: localStorage.getItem("token"),
        //"Access-Control-Allow-Origin": '*'
      };

      const { id } = this.props.match.params;

      let formData = new FormData();
      formData.append("profile_id", id);

      const response = await apiHalper(
        "viewProfileVerificationMawthooq",
        "POST",
        formData,
        headers
      );
      const res = response.data;
      if (res.status) {
        const data = jwtDecode(res.data);
        this.setState({
          mawthooqType: +data.is_number,
          mawthooq_image_url: data.is_number === "0" ? data.data : "",
          mawthooq_id: data.is_number === "1" ? data.data : "",
          issue_date: new Date(data.issue_date),
          expiry_date: new Date(data.expiry_date),
        });
      } else {
        if (this._unmounted) {
          this.setState({
            expertArr: [],
            isELoader: false,
          });
        }
      }
    } catch (err) {
      toster_error(err);
      if (this._unmounted) {
        this.setState({
          expertArr: [],
          isELoader: false,
        });
      }
    }
  };

  handlePhone = (isvalid, val) => {
    const NewValue = val.replace(/\s+/g, "");
    if (!isvalid) {
      this.setState({
        mobile: NewValue,
        mobileError: "Please Enter Valid Mobile Number",
        mobileValid: false,
      });
    } else {
      this.setState({
        mobile: NewValue,
        mobileError: "",
        mobileValid: true,
      });
    }
  };
  handleAgencyPhone = (isvalid, val) => {
    const { formErrorAgency, formValidAgency } = this.state;
    if (!isvalid) {
      this.setState({
        agencyPhone: val,
        formErrorAgency: {
          ...formErrorAgency,
          agencyPhone: "Please Enter Valid Mobile Number",
        },
        formValidAgency: {
          ...formValidAgency,
          agencyPhone: false,
        },
      });
    } else {
      this.setState({
        agencyPhone: val,
        formErrorAgency: {
          ...formErrorAgency,
          agencyPhone: "",
        },
        formValidAgency: {
          ...formValidAgency,
          agencyPhone: true,
        },
      });
    }
  };

  getCountry = (e, country) => {
    this.setState({
      countryCode: country.dialCode,
    });
  };
  getAgencyCountry = (e, country) => {
    this.setState({
      agencyPhoneCode: country.dialCode,
    });
  };

  inputHandler = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => this.validationChecked(name, value)
    );
  };
  handleGenderChange = (gender) => {
    this.setState({ gender: gender });
  };
  inputHandlerAgency = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => this.validationCheckedAgency(name, value)
    );
  };
  inputCRHandler = (e) => {
    const { name, value } = e.target;
    const { formCrError, formCrValid } = this.state;
    this.setState({
      [name]: value,
      formCrError: {
        ...formCrError,
        [name]: "",
      },
      formCrValid: {
        ...formCrValid,
        [name]: true,
      },
    });
  };
  inputVatHandler = (e) => {
    const { name, value } = e.target;
    const { formVatError, formVatValid } = this.state;
    this.setState({
      [name]: value,
      formVatError: {
        ...formVatError,
        [name]: "",
      },
      formVatValid: {
        ...formVatValid,
        [name]: true,
      },
    });
  };
  inputBankHandler = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => this.validationBankChecked(name, value)
    );
  };
  validationBankChecked = (name, value) => {
    let error = this.state.formBankError;
    let valid = this.state.formBankValid;

    switch (name) {
      case "name":
        valid.name = value.length > 0;
        error.name = valid.name ? "" : "Name is required!";
        break;
      case "ibanNumber":
        if (value.length > 0) {
          if (value.length === 22) {
            valid.ibanNumber = true;
            error.ibanNumber = "";
          } else {
            valid.ibanNumber = false;
            error.ibanNumber = "Enter a valid IBAN number!";
          }
        } else {
          valid.ibanNumber = false;
          error.ibanNumber = "IBAN number is required!";
        }
        break;
      default:
        break;
    }

    this.setState({
      formBankError: error,
      formBankValid: valid,
    });
  };
  validationCrChecked = (name, value) => {
    let error = this.state.formBankError;
    let valid = this.state.formBankValid;

    switch (name) {
      case "cr_number":
        valid.cr = value.length > 0;
        error.name = valid.name ? "" : "Name is required!";
        break;
      case "ibanNumber":
        if (value.length > 0) {
          if (value.length === 22 && !isNaN(value)) {
            valid.ibanNumber = true;
            error.ibanNumber = "";
          } else {
            valid.ibanNumber = false;
            error.ibanNumber = "Enter a valid IBAN number!";
          }
        } else {
          valid.ibanNumber = false;
          error.ibanNumber = "IBAN number is required!";
        }
        break;
      default:
        break;
    }

    this.setState({
      formBankError: error,
      formBankValid: valid,
    });
  };
  validationChecked = (name, value) => {
    let error = this.state.formError;
    let valid = this.state.formValid;

    switch (name) {
      case "bussiness_email":
        if (value.length > 0) {
          if (
            value.match(
              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
            )
          ) {
            valid.bussiness_email = true;
            error.bussiness_email = "";
          } else {
            valid.bussiness_email = false;
            error.bussiness_email = "Enter a valid email!";
          }
        } else {
          valid.bussiness_email = true;
          error.bussiness_email = "";
        }
        break;
      case "firstname":
        valid.firstname = value.length > 0;
        error.firstname = valid.firstname ? "" : "Firstname is required!";
        break;
      case "lastname":
        valid.lastname = value.length > 0;
        error.lastname = valid.lastname ? "" : "Lastname is required!";
        break;
      case "username":
        valid.username = value.length > 0;
        error.username = valid.username ? "" : "Username is required!";
        break;
      case "degree":
        valid.degree = value.length > 0;
        error.degree = valid.degree ? "" : "Degree is required!";
        break;
      case "brand":
        valid.brand = value.length > 0;
        error.brand = valid.brand ? "" : "Brand is required!";
        break;
      case "company":
        valid.company = value.length > 0;
        error.company = valid.company ? "" : "Company is required!";
        break;
      case "email":
        if (value.length > 0) {
          if (
            value.match(
              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
            )
          ) {
            valid.email = true;
            error.email = "";
          } else {
            valid.email = false;
            error.email = "Enter a valid email!";
          }
        } else {
          valid.email = false;
          error.email = "Email is required!";
        }
        break;
      case "password":
        if (value.length > 0) {
          if (value.length >= 1) {
            if (value.length >= 8) {
              valid.password = true;
              error.password = "";
            } else {
              valid.password = false;
              error.password = "Password must be at least 8 characters long";
            }
          }
          //  else {
          //     valid.password = false;
          //     error.password = 'Password is Required!';
          // }
        } else {
          valid.password = true;
          error.password = "";
        }
        break;
      default:
        break;
    }

    this.setState({
      formError: error,
      formValid: valid,
    });
  };
  validationCheckedAgency = (name, value) => {
    let error = this.state.formErrorAgency;
    let valid = this.state.formValidAgency;
    switch (name) {
      case "agencyName":
        valid.agencyName = value.length > 0;
        error.agencyName = valid.agencyName ? "" : "Agency Name is required!";
        break;
      case "about":
        valid.about = value.length > 0;
        error.about = valid.about ? "" : "About is required!";
        break;
      case "agencyWebsite":
        valid.agencyWebsite = value.length > 0;
        error.agencyWebsite = valid.agencyWebsite
          ? ""
          : "Agency Website is required!";
        break;
      case "address":
        valid.address = value.length > 0;
        error.address = valid.address ? "" : "Address is required!";
        break;
      case "agencyEmail":
        if (value.length > 0) {
          if (
            value.match(
              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
            )
          ) {
            valid.agencyEmail = true;
            error.agencyEmail = "";
          } else {
            valid.agencyEmail = false;
            error.agencyEmail = "Enter a valid Agency Email!";
          }
        } else {
          valid.agencyEmail = false;
          error.agencyEmail = "Agency Email is required!";
        }
        break;
      default:
        break;
    }

    this.setState({
      formErrorAgency: error,
      formValidAgency: valid,
    });
  };

  inputMawthooqHandler = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  inputEduHandler = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => this.validationEduChecked(name, value)
    );
  };

  validationEduChecked = (name, value) => {
    let error = this.state.eduFormError;
    let valid = this.state.eduFormValid;
    switch (name) {
      case "degree":
        valid.degree = value.length > 0;
        error.degree = valid.degree ? "" : "Degree is required!";
        break;
      case "university":
        valid.university = value.length > 0;
        error.university = valid.university ? "" : "University is required!";
        break;
      default:
        break;
    }

    this.setState({
      eduFormError: error,
      eduFormValid: valid,
    });
  };

  updateUserD = (e) => {
    e.preventDefault();
  };

  submitUserProfile = async (e) => {
    try {

      if (this._unmounted) {
        this.setState({
          isUserProModal: false,
          isUserLoader: true,
          userError: "",
        });
      }

      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      const { id } = this.props.match.params;

      const {
        firstname,
        lastname,
        username,
        email,
        mobile,
        password,
        countryCode,
        minprice,
        maxprice,
        gender,
        bussiness_email,
        bussiness_email_status,
        profile_invisible,
      } = this.state;
      const mobilePreFix = mobile.split(" ");
      let mobileNum = "";

      if (mobile.includes("+")) {
        mobileNum = mobile.replace(`+${countryCode}`, "");
      } else {
        mobileNum = mobile;
      }

      let formData = new FormData();
      formData.append("profile_id", id);
      formData.append("username", username);
      formData.append("first_name", firstname);
      formData.append("last_name", lastname);
      formData.append("email", email);
      formData.append("mobile_prefix", countryCode);
      formData.append("contactNo", mobileNum);
      formData.append("password", password);
      formData.append("min_price", minprice);
      formData.append("max_price", maxprice);
      formData.append("gender", gender?.value);
      formData.append("bussiness_email", bussiness_email);
      formData.append("show_email", bussiness_email_status);

      const response = await apiHalper(
        "updateAgentProfile",
        "POST",
        formData,
        headers
      );
      const res = response.data;
      if (res.status) {
        this.getViewAgentProfile();
        if (this._unmounted) {
          this.setState({
            isUserProModal: false,
            isUserLoader: false,
            userError: "",
            isEdit: false,
          });
          toster_sucess("Profile Edit Successfully...");
        }
      } else {
        if (this._unmounted) {
          this.setState({
            isUserLoader: false,
            userError: res.message,
          });
          toster_error(res.message);
        }
      }
    } catch (err) {
      toster_error(err.message);
      if (this._unmounted) {
        this.setState({
          isUserLoader: false,
          userError: err.message,
        });
      }
    }
  };
  submitUserProfileClient = async (e) => {
    try {

      if (this._unmounted) {
        this.setState({
          isUserProModal: false,
          isUserLoader: true,
          userError: "",
        });
      }

      const headers = {
        Authorization: localStorage.getItem("token"),
        //"Access-Control-Allow-Origin": '*'
      };

      const { id } = this.props.match.params;
      const {
        firstname,
        lastname,
        username,
        email,
        mobile,
        password,
        countryCode,
        brand,
        company,
      } = this.state;
      const mobilePreFix = mobile.split(" ");
      let mobileNum = "";

      if (mobile.includes("+")) {
        mobileNum = mobile.replace(`+${countryCode}`, "");
      } else if (mobile.includes(countryCode)) {
        mobileNum = mobile.replace(`${countryCode} `, "");
      } else {
        mobileNum = mobile;
      }

      let formData = new FormData();
      formData.append("profile_id", id);
      formData.append("username", username);
      formData.append("first_name", firstname);
      formData.append("last_name", lastname);
      formData.append("email", email);
      formData.append("mobile_prefix", countryCode);
      formData.append("contactNo", mobileNum);
      formData.append("password", password);
      formData.append("brand_name", brand);
      formData.append("company_name", company);

      const response = await apiHalper(
        "updateClientProfile",
        "POST",
        formData,
        headers
      );
      const res = response.data;
      if (res.status) {
        this.getViewClientProfile();
        if (this._unmounted) {
          this.setState({
            isUserProModalClient: false,
            isUserLoader: false,
            userError: "",
            isEdit: false,
          });
          toster_sucess("Profile Edit Successfully...");
        }
      } else {
        if (this._unmounted) {
          this.setState({
            isUserLoader: false,
            userError: res.message,
          });
          toster_error(res.message);
        }
      }
    } catch (err) {
      toster_error(err.message);
      if (this._unmounted) {
        this.setState({
          isUserLoader: false,
          userError: err.message,
        });
      }
    }
  };
  submitAgencyDetails = async (e) => {
    e.preventDefault();
    try {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      const { id } = this.props.match.params;

      const {
        agencyId,
        agencyName,
        name_public,
        agencyEmail,
        email_public,
        agencyPhone,
        phone_public,
        agencyWebsite,
        website_public,
        about,
        about_public,
        address,
        address_public,
        agencyPhoneCode,
      } = this.state;
      const phoneNumber = `+${agencyPhoneCode}${agencyPhone}`;

      // const phoneval =

      let formData = new FormData();
      formData.append("profile_id", id);
      formData.append("id", agencyId || 0);
      formData.append("name", agencyName);
      formData.append("name_public", name_public);
      formData.append("email", agencyEmail);
      formData.append("email_public", email_public);
      formData.append("phone", phoneNumber);
      formData.append("phone_public", phone_public);
      formData.append("website", agencyWebsite);
      formData.append("website_public", website_public);
      formData.append("about", about);
      formData.append("about_public", about_public);
      formData.append("address", address);
      formData.append("address_public", address_public);
      formData.append("note", "test");
      formData.append("note_public", 1);

      const response = await apiHalper(
        "addProfileAgencies",
        "POST",
        formData,
        headers
      );
      const res = response.data;
      if (res.status) {
        if (this._unmounted) {
          this.setState({
            isAgencyEdit: false,
          });
          toster_sucess(res.message);
        }
      } else {
        if (this._unmounted) {
          this.setState({
            isUserLoader: false,
            userError: res.message,
          });
          toster_error(res.message);
        }
      }
    } catch (err) {
      toster_error(err.message);
    }
  };

  editHandle = () => {
    this.setState({
      isEdit: !this.state.isEdit,
      isAgencyEdit: false,
      isAddCR: false,
      isAddMawthooq: false,
      isAddPayment: false,
      isAddTags: false,
      isAddCategory: false,
      isAddWebsite: false,
      isAddSocialMedia: false,
      isAddVatReg: false,
      isAddLang: false,
      isSkillsO: false,
    });
  };
  deleteConfirmation = (row) => {
    this.setState({
      updateType: "status",
      editService: row,
      title: "Delete Record",
      text: "Are you sure you want to delete this record?",
    });
    this.openDeleteModal();
  };
  openDeleteModal = () => {
    this.setState({
      isConFModal: true,
    });
  };
  closeCofiClose = () => {
    this.setState({
      isConFModal: false,
      title: "",
      text: "",
    });
  };
  deleteProfile = async (e) => {
    try {
      e.preventDefault();
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      const { id } = this.props.match.params;

      let formData = new FormData();
      formData.append("profile_id", id);

      const response = await apiHalper(
        "deleteAgentProfile",
        "POST",
        formData,
        headers
      );
      const res = response.data;

      if (!res.status) {
        this.setState({
          apiErr: res.message,
        });
        toster_error(res.message);
        return true;
      }
      toster_sucess(res.message);
      setTimeout(() => {
        window.location.href = "/users";
        this.setState({
          isConFModal: false,
          editData: null,
        });
      }, 500);
    } catch (err) {
      toster_error(err.message);
    }
  };
  editHandleAgency = () => {
    this.setState({
      isAgencyEdit: !this.state.isAgencyEdit,
      isEdit: false,
      isAddCR: false,
      isAddMawthooq: false,
      isAddPayment: false,
      isAddTags: false,
      isAddCategory: false,
      isAddWebsite: false,
      isAddSocialMedia: false,
      isAddVatReg: false,
      isAddLang: false,
      isSkillsO: false,
    });
  };

  openLanguage = () => {
    if (!this.state.isAddLang) {
      this.setState({
        selectLang: [],
        allLang: this.state.allLangBack,
        langLevel: [],
      });
    }
    this.setState({
      isAddLang: !this.state.isAddLang,
      isEdit: false,
      isAgencyEdit: false,
      isAddCR: false,
      isAddMawthooq: false,
      isAddPayment: false,
      isAddTags: false,
      isAddCategory: false,
      isAddWebsite: false,
      isAddSocialMedia: false,
      isAddVatReg: false,
      isSkillsO: false,
    });
  };
  openLanguage = () => {
    if (!this.state.isAddLang) {
      this.setState({
        selectLang: [],
        allLang: this.state.allLangBack,
        langLevel: [],
      });
    }
    this.setState({
      isAddLang: !this.state.isAddLang,
      isEdit: false,
      isAgencyEdit: false,
      isAddCR: false,
      isAddMawthooq: false,
      isAddPayment: false,
      isAddTags: false,
      isAddCategory: false,
      isAddWebsite: false,
      isAddSocialMedia: false,
      isAddVatReg: false,
      isSkillsO: false,
    });
  };


  deleteMawthooq = async () => {
    try {
      if (!window.confirm("Are you sure you want to delete this record?")) {
        return false;
      }

      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      const { id } = this.props.match.params;

      const body = { profile_id: id };
      const response = await apiHalper(
        "deleteMawthooqVerification",
        "DELETE",
        body,
        headers
      );
      const res = response.data;

      if (!res.status) {
        this.setState({
          apiErr: res.message,
        });
        toster_error(res.message);
        return true;
      }
      this.setState({
        mawthooqType: 0,
        mawthooq_image_url: "",
        mawthooq_id: "",
        issue_date: null,
        expiry_date: null,
      });
      toster_sucess(res.message);
    } catch (err) {
      toster_error(err.message);
    }
  };

  openMawthooq = () => {
    this.setState({
      isAddMawthooq: !this.state.isAddMawthooq,
      isEdit: false,
      isAgencyEdit: false,
      isAddCR: false,
      isAddPayment: false,
      isAddTags: false,
      isAddCategory: false,
      isAddWebsite: false,
      isAddSocialMedia: false,
      isAddVatReg: false,
      isAddLang: false,
      isSkillsO: false,
    });
  };
  openPayment = () => {
    this.setState({
      isAddPayment: !this.state.isAddPayment,
      isEdit: false,
      isAgencyEdit: false,
      isAddCR: false,
      isAddMawthooq: false,
      isAddTags: false,
      isAddCategory: false,
      isAddWebsite: false,
      isAddSocialMedia: false,
      isAddVatReg: false,
      isAddLang: false,
      isSkillsO: false,
    });
  };
  openTags = () => {
    this.setState({
      selectedTags: "",
      isAddTags: !this.state.isAddTags,
      isAddPayment: false,
      isEdit: false,
      isAgencyEdit: false,
      isAddCR: false,
      isAddMawthooq: false,
      isAddCategory: false,
      isAddWebsite: false,
      isAddSocialMedia: false,
      isAddVatReg: false,
      isAddLang: false,
      isSkillsO: false,
    });
  };
  openCategory = () => {
    this.setState({
      selectedCategory: "",
      isAddCategory: !this.state.isAddCategory,
      isAddPayment: false,
      isEdit: false,
      isAgencyEdit: false,
      isAddCR: false,
      isAddMawthooq: false,
      isAddTags: false,
      isAddWebsite: false,
      isAddSocialMedia: false,
      isAddVatReg: false,
      isAddLang: false,
      isSkillsO: false,
    });
  };
  openWebsite = () => {
    this.setState({
      isAddWebsite: !this.state.isAddWebsite,
      isAddPayment: false,
      isEdit: false,
      isAgencyEdit: false,
      isAddCR: false,
      isAddMawthooq: false,
      isAddTags: false,
      isAddCategory: false,
      isAddSocialMedia: false,
      isAddVatReg: false,
      isAddLang: false,
      isSkillsO: false,
    });
  };
  openSocialMedia = () => {
    this.setState({
      isAddSocialMedia: !this.state.isAddSocialMedia,
      isAddPayment: false,
      isEdit: false,
      isAgencyEdit: false,
      isAddCR: false,
      isAddMawthooq: false,
      isAddTags: false,
      isAddCategory: false,
      isAddWebsite: false,
      isAddVatReg: false,
      isAddLang: false,
    });
  };
  openVatReg = () => {
    this.setState({
      isAddVatReg: !this.state.isAddVatReg,
      isAddPayment: false,
      isEdit: false,
      isAgencyEdit: false,
      isAddCR: false,
      isAddMawthooq: false,
      isAddTags: false,
      isAddCategory: false,
      isAddWebsite: false,
      isAddSocialMedia: false,
      isAddLang: false,
    });
  };
  openCRDetails = () => {
    this.setState({
      isAddCR: !this.state.isAddCR,
      isAddPayment: false,
      isEdit: false,
      isAgencyEdit: false,
      isAddMawthooq: false,
      isAddTags: false,
      isAddCategory: false,
      isAddWebsite: false,
      isAddSocialMedia: false,
      isAddVatReg: false,
      isAddLang: false,
    });
  };

  mawthooqSubmit = async (e) => {
    e.preventDefault();
    try {
      const type = this.getQueryStringUrl();
      const { id } = this.props.match.params;
      const {
        mawthooq,
        mawthooq_id,
        issue_date,
        expiry_date,
        mawthooqType,
        mawthooq_image_url,
      } = this.state;
      if (mawthooq || mawthooq_id || mawthooq_image_url) {
        let formData = new FormData();
        formData.append("profile_id", id);
        formData.append("profile_type_id", 1);
        formData.append("issue_date", moment(issue_date).format("YYYY-MM-DD"));
        formData.append(
          "expiry_date",
          moment(expiry_date).format("YYYY-MM-DD")
        );
        formData.append("mawthooqType", mawthooqType);
        if (mawthooqType === 0 && mawthooq) {
          if (mawthooq) {
            formData.append("mawthooq", mawthooq);
          } else {
            formData.append("isUpload", mawthooq);
          }
        } else if (mawthooq_id) {
          formData.append("mawthooq_id", mawthooq_id);
        }
        const response = await apiHalper(
          "addMawthooqVerificationData",
          "POST",
          formData,
        );
        const res = response.data;
        if (res.status) {
          toster_sucess("Mathooq added Successfully...");
          this.getMawthooqDetails();
          this.getViewAgentProfile();
          this.setState({
            isAddMawthooq: false,
          });
        } else {
          toster_error(res.msg);
        }
      } else {
        this.setState({
          mawthooqError:
            mawthooqType === 0
              ? "Please upload mawthooq certificate."
              : "Please enter mawthooq number.",
        });
      }
    } catch (error) {
      toster_error(error);
    }
  };

  tagsSubmit = async (e) => {
    e.preventDefault();
    const type = this.getQueryStringUrl();
    const { id } = this.props.match.params;
    const { selectedTags } = this.state;
    if (selectedTags) {

      const tagIdsdata = selectedTags.map(tag => tag.value)
      const response = await apiHalper(`assignTagsToProfile/${id}`, "POST", { tagIds: tagIdsdata });
      const res = response.data;
      if (res.status) {
        this.getAllAgentTags();
        toster_sucess("Tags added Successfully...");
        this.setState({
          isAddTags: false,
        });
      } else {
        toster_error(res.msg);
      }
    } else {
      toster_error("please select Tag option");
    }
  };

  categorySubmit = async (e) => {
    e.preventDefault();
    const type = this.getQueryStringUrl();
    const { id } = this.props.match.params;
    const { selectedCategory } = this.state;
    if (selectedCategory) {

      const selectdeData = this.state.categoriesAllData.filter((data) => data.id === selectedCategory?.value)
      const categorydata = selectedCategory?.map(category => category.value)
      try {
        const response = await apiHalper(`assignServiceCategoriesToProfile/${id}`, "POST", { categoryIds: categorydata });
        const res = response.data;
        if (response.status === 200) {
          this.getAllAgentCategories();
          toster_sucess("Category added Successfully...");
          this.setState({
            isAddCategory: false,
          });
        } else {
          toster_error(res.msg);
        }
      } catch (error) {
        toster_error(error);
      }
    } else {
      toster_error("please select Category option");

    }
  };
  getLanguages = async () => {
    try {

      const response = await apiHalper("getLanguage", "GET", null);
      const res = response.data;
      if (res.status) {
        const data = jwtDecode(res.data);
        const option = Array.isArray(data)
          ? data.map(({ id, name }) => ({ value: id, label: name }))
          : [];
        this.setState({
          allLang: option,
          allLangBack: option,
        });
      } else {
        this.setState({
          allLang: [],
          allLangBack: [],
        });
      }
    } catch (err) {
      toster_error(err);
    }
  };

  getSkills = async (page, search, isPrevSearch) => {
    try {
      if (this._unmounted) {
        this.setState({
          isSkillAPILoader: true,
        });
      }

      const { id } = this.props.match.params;

      let formData = new FormData();
      formData.append("page_no", page);
      formData.append("search", search);
      formData.append("profile_id", id);

      const response = await apiHalper("getSkills", "POST", formData);
      const res = response.data;
      if (res.status) {
        const data = jwtDecode(res.data);
        const option = Array.isArray(data.skill_lists)
          ? data.skill_lists.map(({ id, name }) => ({ value: id, label: name }))
          : [];
        if (this._unmounted) {
          if (isPrevSearch) {
            this.setState({
              allSkill: option,
              allSkillBack: option,
              skillsCount: page < 1 ? data.skill_count : this.state.skillsCount,
              isSkillSLoader: option.length > 100,
              isSkillAPILoader: false,
            });
          } else {
            this.setState({
              allSkill: [...this.state.allSkill, ...option],
              allSkillBack: [...this.state.allSkillBack, ...option],
              skillsCount: page < 1 ? data.skill_count : this.state.skillsCount,
              isSkillSLoader: true,
              isSkillAPILoader: false,
            });
          }
        }
      } else {
        if (this._unmounted) {
          this.setState({
            allSkill: [],
            skillsCount: 0,
            allSkillBack: [],
            isSkillSLoader: false,
            isSkillAPILoader: false,
          });
        }
      }
    } catch (err) {
      toster_error(err);
      if (this._unmounted) {
        this.setState({
          allSkill: [],
          skillsCount: 0,
          allSkillBack: [],
          isSkillSLoader: false,
          isSkillAPILoader: false,
        });
      }
    }
  };

  handleChange = (selectedOption) => {
    const { allLangBack } = this.state;

    const newArr = allLangBack.filter(
      (info) => info.value !== selectedOption.value
    );

    this.setState({
      selectLang: selectedOption,
      allLang: newArr,
    });
  };
  handleChangeLanguage = (selected, index) => {
    const newFormData = [...this.state.selectedLanguages];
    newFormData[index].selectedLang = selected;
    this.setState({ selectedLanguages: newFormData });
  };

  handleChangeLangLevel = (selected, index) => {
    const newFormData = [...this.state.selectedLanguages];
    newFormData[index].selectedLevel = selected;
    this.setState({ selectedLanguages: newFormData });
  };

  handleDeleteInputLang = (index) => {
    const newFormData = [...this.state.selectedLanguages];
    newFormData.splice(index, 1);
    this.setState({ selectedLanguages: newFormData });
  };

  handleAddMoreLanguage = () => {
    const newFormData = [...this.state.selectedLanguages, {}];
    this.setState({ selectedLanguages: newFormData });
  };

  handleChange2 = (selectedOption) => {
    this.setState({
      langLevel: selectedOption,
    });
  };

  handleChangeEdu = (selectedOption) => {
    this.setState({
      eduLevel: selectedOption,
    });
  };

  handleExper = (selectedOption) => {
    this.setState({
      selectExpert: selectedOption,
    });
  };

  handleExp = (selectedOption) => {
    this.setState({
      selectExp: selectedOption,
    });
  };

  LangSubmit = async (e) => {
    try {
      e.preventDefault();

      if (this.state.isEditLang) {
        this.setState({
          isLangLoader: true,
          langErr: "",
        });

        const { id } = this.props.match.params;

        const { selectedLanglist } = this.state;

        const selectedForEdit = this.state.selectedLanguages[0];
        const data = selectedLanglist.map((item) => {
          if (this.state.isEditLangID === item.profile_language_id) {
            return {
              lang_id: selectedForEdit.selectedLang.value,
              level: selectedForEdit.selectedLevel.value,
            };
          }
          return { lang_id: item.id, level: item.level };
        });

        let formData = new FormData();
        formData.append("profile_id", id);
        formData.append("language_list", JSON.stringify(data));

        const response = await apiHalper(
          "addProfileLanguage",
          "POST",
          formData,

        );

        if (response.data.status) {
          this.getViewAgentProfile();
          this.setState({
            isLangLoader: false,
            selectedLanguages: [{}],
            isEditLang: !this.state.isEditLang,
          });
          toster_sucess("Social media added Successfully...");
        } else {
          toster_error(response.data.message);
        }
      } else {
        this.setState({
          isLangLoader: true,
          langErr: "",
        });

        const { id } = this.props.match.params;
        const { selectedLanguages, selectedLanglist } = this.state;
        const prevData = selectedLanglist.map((item) => {
          return { lang_id: item.id, level: item.level };
        });
        const data = selectedLanguages.map((item) => ({
          lang_id: item.selectedLang.value,
          level: item.selectedLevel.value,
        }));

        const finalData = [...prevData, ...data];

        let formData = new FormData();
        formData.append("profile_id", id);
        formData.append("language_list", JSON.stringify(finalData));

        const response = await apiHalper(
          "addProfileLanguage",
          "POST",
          formData,

        );

        if (response.data.status) {
          this.getAllSocialMediaList();
          this.getViewAgentProfile();
          this.setState({
            isLangLoader: false,
            selectedLanguages: [{}],
            isAddLang: !this.state.isAddLang,
          });
          toster_sucess("Social media added Successfully...");
        } else {
          toster_error(response.data.message);
        }
      }
    } catch (err) {
      toster_error(err?.message);
      if (this._unmounted) {
        this.setState({
          isLangLoader: false,
          isAddLang: false,
        });
      }
    }
  };

  ToggleExpert = () => {
    if (!this.state.isExpertise) {
      this.setState({
        selectExpert: null,
        selectExp: null,
      });
    }
    this.setState({
      isExpertise: !this.state.isExpertise,
    });
  };
  expertSubmit = async (e) => {
    try {
      e.preventDefault();

      this.setState({
        isESLoader: true,
        expErr: "",
      });

    } catch (err) {
      toster_error(err?.message);
      if (this._unmounted) {
        this.setState({
          isESLoader: false,
          isExpertise: true,
        });
      }
    }
  };

  renderLangu = (data, i) => {
    switch (data.level) {
      case 0:
        return (
          <li key={i}>
            {data.name}{" "}
            <span>
              Basic{" "}
              <span className="hover_block">
                <button
                  type="button"
                  className="btn_edi"
                  onClick={() => this.langEditMod(data)}
                ></button>
                <button
                  type="button"
                  className="btn_del"
                  onClick={() => this.delLangMod(data)}
                ></button>
              </span>
            </span>
          </li>
        );
      case 1:
        return (
          <li key={i}>
            {data.name}{" "}
            <span>
              Conversational{" "}
              <span className="hover_block">
                <button
                  type="button"
                  className="btn_edi"
                  onClick={() => this.langEditMod(data)}
                ></button>
                <button
                  type="button"
                  className="btn_del"
                  onClick={() => this.delLangMod(data)}
                ></button>
              </span>
            </span>
          </li>
        );
      case 2:
        return (
          <li key={i}>
            {data.name}{" "}
            <span>
              Fluent{" "}
              <span className="hover_block">
                <button
                  type="button"
                  className="btn_edi"
                  onClick={() => this.langEditMod(data)}
                ></button>
                <button
                  type="button"
                  className="btn_del"
                  onClick={() => this.delLangMod(data)}
                ></button>
              </span>
            </span>
          </li>
        );
      case 3:
        return (
          <li key={i}>
            {data.name}{" "}
            <span>
              Native{" "}
              <span className="hover_block">
                <button
                  type="button"
                  className="btn_edi"
                  onClick={() => this.langEditMod(data)}
                ></button>
                <button
                  type="button"
                  className="btn_del"
                  onClick={() => this.delLangMod(data)}
                ></button>
              </span>
            </span>
          </li>
        );
      default:
        return null;
    }
  };
  deletePaymentDetail = async (data, index) => {
    try {

      const { id } = this.props.match.params;

      const { connectedBanksList } = this.state;

      let formData = new FormData();
      formData.append("id", data.id);
      formData.append("profile_id", id);

      const response = await apiHalper("deleteBank", "POST", formData);

      if (response.data.status) {
        const newData = connectedBanksList.filter((_, i) => i !== index);
        this.setState({
          connectedBanksList: newData,
        });
        toster_sucess("Paymnet details deleted Successfully...");
      } else {
        toster_error(response.data.message);
      }
    } catch (err) {
      toster_error(err.message);
    }
  };
  editPaymentDetail = async (data, i) => {
    try {

      const { id } = this.props.match.params;
      const {
        editBankId,
        name,
        selectedBank,
        ibanNumber,
        isPrimary,
        bankAttachment,
      } = this.state;

      const { connectedBanksList } = this.state;

      let formData = new FormData();
      formData.append("id", editBankId);
      formData.append("profile_id", id);
      formData.append("beneficiary_name", name);
      formData.append("iban", ibanNumber);
      formData.append("is_primary", isPrimary);
      formData.append("bank_id", selectedBank.value);
      formData.append("bank_cert", bankAttachment || "");

      const response = await apiHalper("updateBank", "POST", formData);

      if (response.data.status) {
        toster_sucess("Paymnet details deleted Successfully...");
      } else {
        toster_error(response.data.message);
      }
    } catch (err) {
      toster_error(err.message);
    }
  };
  openEditBank = (data) => {
    this.setState({
      editBankId: data.id,
      isEditPayment: !this.state.isEditPayment,
      selectedBank: { value: data.bank_id, label: data.name },
      bankAttachmentURL: data.bank_certificate,
      ibanNumber: data.iban.replace("SA", ""),
      name: data.beneficiary_name,
      isPrimary: data.beneficiary_name,
    });
  };
  renderBanks = (data, i) => {
    const imgfilePath = localStorage.getItem("filePath");
    const decodePath = jwtDecode(imgfilePath);
    return (
      <div className="payment-card">
        {data.bank_certificate && (
          <a
            target="_blank"
            href={`${decodePath.bank_certificate}${data.bank_certificate}`}
          >
            <button type="button" className="btn-show-icon"></button>
          </a>
        )}
        <div>
          <h6>{data.name}</h6>
          <p>{data.beneficiary_name}</p>
          <small>{data.iban}</small>
        </div>
        <div className="bank-action">
          <Button
            type="button"
            variant="danger"
            className="D-icon  delete-social-media"
            onClick={() => this.deletePaymentDetail(data, i)}
          >
            <img src={DeleteIcon} alt={""} />
          </Button>
          <Button
            type="button"
            variant="primary"
            className="D-icon  edit-social-media"
            onClick={() => this.openEditBank(data)}
          >
            <img src={EditIcon} alt={""} />
          </Button>
        </div>
      </div>
    );
  };
  renderMawthooq = (data, i) => {
    const imgfilePath = localStorage.getItem("filePath");
    const decodePath = jwtDecode(imgfilePath);
    return (
      <div className="payment-card">
        <span>
          <img
            src={`${decodePath.bank_certificate}/${data.bank_certificate}`}
          />
        </span>
        <div>
          <h6>{data.name}</h6>
          <p>{data.beneficiary_name}</p>
          <small>{data.iban}</small>
        </div>
        <Button>Edit</Button>
      </div>
    );
  };
  renderTags = (data, i) => {
    return (
      <Badge bg="primary" key={i}>
        {data.label}
      </Badge>
    );
  };

  formatNumberForSocial = (number) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "decimal",
    });
    if (number >= 1_000_000) {
      const millionCount = number / 1_000_000;
      return `${formatter.format(millionCount)}M`;
    } else if (number >= 1_000) {
      const thousandCount = number / 1_000;
      return `${formatter.format(thousandCount)}K`;
    } else {
      return formatter.format(number);
    }
  };

  formatNumberForSocialEdit = (number) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "decimal",
    });
    if (number >= 1_000_000) {
      const millionCount = number / 1_000_000;
      return { count: formatter.format(millionCount), prefix: "M" };
    } else if (number >= 1_000) {
      const thousandCount = number / 1_000;
      return { count: formatter.format(thousandCount), prefix: "K" };
    } else {
      return formatter.format(number);
    }

  };

  formatNumber = (number) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "decimal",
    });
    if (number >= 1_000_000_000) {
      const billionCount = number / 1_000_000_000;
      return `${formatter.format(billionCount)}B`;
    } else if (number >= 1_000_000) {
      const millionCount = number / 1_000_000;
      return `${formatter.format(millionCount)}M`;
    } else if (number >= 1_000) {
      const thousandCount = number / 1_000;
      return `${formatter.format(thousandCount)}K`;
    } else {
      return formatter.format(number);
    }
  };
  formatNumberForEdit = (number) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "decimal",
    });
    if (number >= 1_000_000_000) {
      const billionCount = number / 1_000_000_000;
      return { count: formatter.format(billionCount), prefix: "B" };
    } else if (number >= 1_000_000) {
      const millionCount = number / 1_000_000;
      return { count: formatter.format(millionCount), prefix: "M" };
    } else if (number >= 1_000) {
      const thousandCount = number / 1_000;
      return { count: formatter.format(thousandCount), prefix: "K" };
    } else {
      return formatter.format(number);
    }
  };

  langEditMod = (data) => {
    const { allLangBack } = this.state;
    const newArr = allLangBack.filter((info) => info.value !== data.id);
    let levelSelected;
    switch (data.level) {
      case 0:
        levelSelected = { label: "Basic", value: data.level };
        break;
      case 1:
        levelSelected = { label: "Conversational", value: data.level };
        break;
      case 2:
        levelSelected = { label: "Fluent", value: data.level };
        break;
      case 3:
        levelSelected = { label: "Native", value: data.level };
        break;
      default:
        break;
    }

    this.setState({
      selectedLanguages: [
        {
          selectedLang: { label: data.name, value: data.id },
          selectedLevel: levelSelected,
        },
      ],
      isEditLangID: data.profile_language_id,
      selectLang: { label: data.name, value: data.id },
      isEditLang: true,
    });
  };

  delLangMod = async (data) => {
    try {
      const { id } = this.props.match.params;


      let formData = new FormData();
      formData.append("id", data.profile_language_id);
      formData.append("profile_id", id);

      const response = await apiHalper(
        "deleteLanguage",
        "POST",
        formData,

      );
      const res = response.data;

      if (res.status) {
        this.getViewAgentProfile();
      }
    } catch (err) {
      toster_error(err);
    }
  };
  delAgentCatgeory = async (data) => {
    try {
      const { id } = this.props.match.params;
      const response = await apiHalper(
        `removeProfileCategories/${id}`,
        "PATCH",
        { categoryIds: [data?.category_id] },
      );
      const res = response.data;
      if (res.status) {
        toster_sucess("Category Delete Successfully...");
        this.getAllAgentCategories();
      }
    } catch (err) {
      toster_error(err);
    }
  };
  delAgentTag = async (data) => {
    try {
      const { id } = this.props.match.params;
      const response = await apiHalper(
        `removeProfileTags/${id}`,
        "PATCH",
        { tagIds: [data?.tag_id] },
      );
      const res = response.data;
      if (res.status) {
        toster_sucess("Tag Delete Successfully...");
        this.getAllAgentTags();
      }
    } catch (err) {
      toster_error(err);
    }
  };

  renderSkills = (data, i) => {
    switch (data.rating) {
      case 0:
        return (
          <li key={i}>
            {data.name}{" "}
            <span>
              Basic{" "}
              <span className="hover_block">
                <button
                  type="button"
                  className="btn_edi"
                  onClick={() => this.skillsEditMod(data)}
                ></button>
                {i > 0 ? (
                  <button
                    type="button"
                    className="btn_del"
                    onClick={() => this.skillsDelete(data)}
                  ></button>
                ) : null}
              </span>
            </span>
          </li>
        );
      case 1:
        return (
          <li key={i}>
            {data.name}{" "}
            <span>
              Conversational{" "}
              <span className="hover_block">
                <button
                  type="button"
                  className="btn_edi"
                  onClick={() => this.skillsEditMod(data)}
                ></button>
                {i > 0 ? (
                  <button
                    type="button"
                    className="btn_del"
                    onClick={() => this.skillsDelete(data)}
                  ></button>
                ) : null}
              </span>
            </span>
          </li>
        );
      case 2:
        return (
          <li key={i}>
            {data.name}{" "}
            <span>
              Fluent{" "}
              <span className="hover_block">
                <button
                  type="button"
                  className="btn_edi"
                  onClick={() => this.skillsEditMod(data)}
                ></button>
                {i > 0 ? (
                  <button
                    type="button"
                    className="btn_del"
                    onClick={() => this.skillsDelete(data)}
                  ></button>
                ) : null}
              </span>
            </span>
          </li>
        );
      case 3:
        return (
          <li key={i}>
            {data.name}{" "}
            <span>
              Native{" "}
              <span className="hover_block">
                <button
                  type="button"
                  className="btn_edi"
                  onClick={() => this.skillsEditMod(data)}
                ></button>
                {i > 0 ? (
                  <button
                    type="button"
                    className="btn_del"
                    onClick={() => this.skillsDelete(data)}
                  ></button>
                ) : null}
              </span>
            </span>
          </li>
        );
      default:
        return null;
    }
  };

  openSocialMediaEdit = (data) => {
    const { socialMediaTypeList } = this.state;

    const selectedSocialMediaType = socialMediaTypeList.find(
      (item) => item.value === data.social_platform_type_id
    );

    if (selectedSocialMediaType) {
      const { platforms } = selectedSocialMediaType;
      const selectedPlatform = platforms.find(
        (platform) => platform.id === data.social_platform_id
      );

      if (selectedPlatform) {
        const {
          id: socialMediaplatformsType,
          name,
          filename,
          web_url,
        } = selectedPlatform;

        this.setState({
          isEditSocialMedia: true,
          editSocialMediaId: data.id,
          socialMedias: [
            {
              socialMediaplatformsTypeList: platforms.map((info) => ({
                value: info.id,
                label: info.name,
                filename: info.filename,
                web_url: info.web_url,
              })),
              socialMediaType: selectedSocialMediaType.value,
              socialMediaplatformsType: socialMediaplatformsType,
              socialMediaUserName: data.username,
              socialMediaFollowers: this.formatNumberForSocialEdit(
                data.followers
              ).count,
              socialMediaprifix: {
                label: this.formatNumberForSocialEdit(data.followers).prefix,
                value: this.formatNumberForSocialEdit(data.followers).prefix,
              },
              selectedSocialMediaType: selectedSocialMediaType,
              selectedSocialMediaplatformsType: {
                value: socialMediaplatformsType,
                label: name,
                filename,
                web_url,
              },
            },
          ],
        });
      }
    }
  };

  renderSocialMedias = (data, i) => {
    return (
      <div className="social-card" key={i}>
        <img className="w-10" src={data.filename} />
        <div>
          <h6>{data.typeName}</h6>
          <p>{data.name}</p>
          <a href={`${data.web_url}${data.username}`} target="_blank">
            <small>{data.username}</small>
          </a>
          <small>{this.formatNumberForSocial(data.followers)}</small>
          <p>{data.prifix}</p>
          <div className="d-flex justify-content-center gap-2">
            <Button
              type="button"
              variant="danger"
              className="D-icon delete-social-media"
              onClick={() => this.deleteConnectedSocialMedia(data, i)}
            >
              <img src={DeleteIcon} alt={""} />
            </Button>
            <Button
              type="button"
              variant="primary"
              className="D-icon edit-social-media"
              onClick={() => this.openSocialMediaEdit(data)}
            >
              <img src={EditIcon} alt={""} />
            </Button>
          </div>
        </div>
      </div>
    );
  };

  skillsEditMod = (data) => {
    this.setState({
      isSkillsO: true,
      selectSkills: { label: data.name, value: data.id },
      editSkill: data,
    });

    switch (data.rating) {
      case 0:
        this.setState({ sSkillsLevel: { label: "Basic", value: 0 } });
        break;
      case 1:
        this.setState({ sSkillsLevel: { label: "Conversational", value: 1 } });
        break;
      case 2:
        this.setState({ sSkillsLevel: { label: "Fluent", value: 2 } });
        break;
      case 3:
        this.setState({ sSkillsLevel: { label: "Native", value: 3 } });
        break;
      default:
        break;
    }

    if (this.state.allSkillBack.length === 0) this.getSkills(1, "", false);
  };

  experTEditMod = (data) => {
    if (this.state.expertArr.length === 0) this.getExpertiseApi();

    let selectExpe = null;

    switch (data.length) {
      case 0:
        selectExpe = { label: "Less than 1 year", value: 0 };
        break;
      case 1:
        selectExpe = { label: "1-3 years", value: 1 };
        break;
      case 2:
        selectExpe = { label: "4-6 years", value: 2 };
        break;
      case 3:
        selectExpe = { label: "7-9 years", value: 3 };
        break;
      case 4:
        selectExpe = { label: "10-12 years", value: 4 };
        break;
      case 5:
        selectExpe = { label: "13-15 years", value: 5 };
        break;
      case 6:
        selectExpe = { label: "16-18 years", value: 6 };
        break;
      case 7:
        selectExpe = { label: "19-21 years", value: 7 };
        break;
      case 8:
        selectExpe = { label: "22-24 years", value: 8 };
        break;
      case 9:
        selectExpe = { label: "25-27 years", value: 9 };
        break;
      case 10:
        selectExpe = { label: "28-30 years", value: 10 };
        break;
      case 11:
        selectExpe = { label: "31+ years", value: 11 };
        break;
      default:
        break;
    }

    this.setState({
      isExpertise: true,
      selectExpert: { value: data.id, label: data.name },
      selectExp: selectExpe,
      editExpert: data,
    });
  };

  expRender = (data, i) => {
    const d = new Date(data.start_date);
    const l = new Date(data.end_date);
    if (data.company_name !== null) {
      return (
        <li key={i}>
          {data.company_name}
          <span>
            {d.getFullYear()} -{" "}
            {data.is_current > 0 ? "Present" : l.getFullYear()}
            <span className="hover_block">
              <button type="button" className="btn_edi"></button>
              <button type="button" className="btn_del"></button>
            </span>
          </span>
        </li>
      );
    }
  };

  edcucaRender = (data, i) => {
    const d = new Date(data.start_date);
    const l = new Date(data.end_date);
    return (
      <li key={i}>
        {data.degree}
        <span>
          {d.getFullYear()} - {l.getFullYear()}
          <span className="hover_block">
            <button type="button" className="btn_edi"></button>
            <button
              type="button"
              className="btn_del"
              onClick={() => this.delEduction(data)}
            ></button>
          </span>
        </span>
      </li>
    );
  };

  headlineInput = (e) => {
    const { value } = e.target;
    this.setState({
      headline: value,
    });
  };
  websiteInput = (e) => {
    const { value } = e.target;
    this.setState({
      website: value,
    });
  };
  companyInput = (e) => {
    const { value } = e.target;
    this.setState({
      company: value,
    });
  };
  brandInput = (e) => {
    const { value } = e.target;
    this.setState({
      brand: value,
    });
  };
  radioHandlerVat = (vatType) => {
    this.setState({ vatType });
  };
  summaryInput = (e) => {
    const { value } = e.target;
    this.setState({
      summary: value,
    });
  };

  toggleEditButton = (name) => {
    switch (name) {
      case "headline":
        this.setState({
          isHeadline: !this.state.isHeadline,
        });
        break;
      case "summary":
        this.setState({
          isSummary: !this.state.isSummary,
        });
        break;
      case "degree":
        this.setState({
          degree: !this.state.degree,
        });
        break;
      case "brand":
        this.setState({
          isBrand: !this.state.isBrand,
        });
        break;
      case "company":
        this.setState({
          isCompany: !this.state.isCompany,
        });
        break;
      default:
        break;
    }
  };

  HeadlineSubmit = async (e) => {
    try {
      e.preventDefault();
      const headers = {
        Authorization: localStorage.getItem("token"),
      };
      const { id } = this.props.match.params;

      let formData = new FormData();
      formData.append("profile_id", id);
      formData.append("content", this.state.headline);

      const response = await apiHalper(
        "addHeadlines",
        "POST",
        formData,
        headers
      );
      if (response.data.status) {
        toster_sucess(response.data.message);
        this.setState({
          isHeadline: false,
        });
      } else {
        toster_error(response.data.message);
      }
    } catch (err) {
      toster_error(err.message);
    }
  };


  companySubmit = async (e) => {
    try {
      e.preventDefault();

      const { id } = this.props.match.params;

      let formData = new FormData();
      formData.append("profile_id", id);
      formData.append("brand", this.state.brand);
      formData.append("company", this.state.company);

      const response = await apiHalper(
        "addClientInfo",
        "POST",
        formData,

      );

      if (response.data.status) {
        this.setState({
          isCompany: !this.state.isCompany,
        });
        toster_sucess("Client info added Successfully...");
      } else {
        toster_error(response.data.message);
      }
    } catch (err) {
      toster_error(err.message);
    }
  };

  websiteSubmit = async (e) => {
    try {
      e.preventDefault();

      const { id } = this.props.match.params;

      let formData = new FormData();
      formData.append("profile_id", id);
      formData.append("website", this.state.website);

      const response = await apiHalper(
        "editProfessionalInfo",
        "POST",
        formData,

      );

      if (response.data.status) {
        this.setState({
          isAddWebsite: !this.state.isAddWebsite,
        });
        toster_sucess("Website Added Successfully...");
      } else {
        toster_error(response.data.message);
      }
    } catch (err) {
      toster_error(err.message);
    }
  };
  socialMediaSubmit = async (e) => {
    try {
      if (this.state.isEditSocialMedia) {
        e.preventDefault();

        const data = this.state.socialMedias[0];
        let formData = new FormData();
        formData.append("social_platform_id", this.state.editSocialMediaId);
        formData.append("username", data.socialMediaUserName);
        formData.append("followers", data.socialMediaFollowers);
        formData.append("prefix", data.socialMediaprifix.value);

        const response = await apiHalper(
          "updateSocialPlatforms",
          "POST",
          formData,

        );
        if (response.data.status) {
          this.getAllSocialMediaList();
          this.setState({
            socialMedias: [{}],
            isEditSocialMedia: !this.state.isEditSocialMedia,
          });
          toster_sucess("Social media added Successfully...");
        } else {
          toster_error(response.data.message);
        }
      } else {
        e.preventDefault();

        const { id } = this.props.match.params;

        const data = this.state.socialMedias.map((item) => ({
          social_platform_type_id: item.socialMediaType,
          social_platform_id: item.socialMediaplatformsType,
          username: item.socialMediaUserName,
          followers: item.socialMediaFollowers,
          prefix: item.socialMediaprifix.value,
        }));

        let formData = new FormData();
        formData.append("profile_id", id);
        formData.append("accounts", JSON.stringify(data));

        const response = await apiHalper(
          "addSocialProfile",
          "POST",
          formData,

        );

        if (response.data.status) {
          this.getAllSocialMediaList();
          this.setState({
            socialMedias: [{}],
            isAddSocialMedia: !this.state.isAddSocialMedia,
          });
          toster_sucess("Social media added Successfully...");
        } else {
          toster_error(response.data.message);
        }
      }
    } catch (err) {
      toster_error(err.message);
    }
  };
  crSubmit = async (e) => {
    try {
      e.preventDefault();
      const { formCrValid, formCrError, cr_number, commercial_certi, cr_url } =
        this.state;

      const { id } = this.props.match.params;

      let formData = new FormData();
      formData.append("profile_id", id);
      formData.append("cr_number", cr_number);
      if (commercial_certi) {
        formData.append("commercial", commercial_certi);
      }

      const response = await apiHalper("addCRData", "POST", formData);

      if (response.data.status) {
        this.getViewClientProfile();
        this.setState({
          isAddCR: !this.state.isAddCR,
        });
        toster_sucess("CR details added Successfully...");
      } else {
        toster_error(response.data.message);
      }
    } catch (err) {
      toster_error(err.message);
    }
  };
  vatSubmit = async (e) => {
    try {
      e.preventDefault();
      const { formVatValid, formVatError, vatNumber, vatAttachment, vat_url } =
        this.state;

      const { id } = this.props.match.params;

      let formData = new FormData();
      formData.append("profile_id", id);
      formData.append("vat_number", vatNumber);
      if (vatAttachment) {
        formData.append("vat", vatAttachment);
      }

      const response = await apiHalper("addVatData", "POST", formData);

      if (response.data.status) {
        this.getViewClientProfile();
        this.setState({
          isAddVatReg: !this.state.isAddVatReg,
        });
        toster_sucess("Vat Details added Successfully...");
      } else {
        toster_error(response.data.message);
      }
    } catch (err) {
      toster_error(err.message);
    }
  };
  bankSubmit = async (e) => {
    try {
      if (this.state.isEditPayment) {
        e.preventDefault();

        const { id } = this.props.match.params;
        const {
          ibanPrefix,
          ibanNumber,
          name,
          selectedBank,
          isPrimary,
          bankAttachment,
          bankAttachmentURL,
          connectedBanksList,
          editBankId,
        } = this.state;

        let formData = new FormData();
        formData.append("id", editBankId);
        formData.append("profile_id", id);
        formData.append("iban", `${ibanPrefix}${ibanNumber}`);
        formData.append("beneficiary_name", name);
        formData.append("bank_id", selectedBank.value);
        formData.append("is_primary", isPrimary ? 1 : 0);
        // formData.append('status', 1)
        formData.append("bank_cert", bankAttachment);

        const response = await apiHalper(
          "updateBank",
          "POST",
          formData,

        );

        if (response.data.status) {
          this.setState({
            connectedBanksList: connectedBanksList.map((item) => {
              if (item.id === editBankId) {
                item.iban = ibanNumber;
                item.beneficiary_name = name;
                item.name = selectedBank.label;
                item.is_prime = isPrimary;
                item.bank_certificate = bankAttachmentURL;
              }
              return item;
            }),
            isEditPayment: false,
          });
          toster_sucess("Paymnet details added Successfully...");
        } else {
          toster_error(response.data.message);
        }
      } else {
        e.preventDefault();

        const { id } = this.props.match.params;
        const {
          ibanPrefix,
          ibanNumber,
          name,
          selectedBank,
          isPrimary,
          bankAttachment,
          formBankValid,
          formBankError,
          connectedBanksList,
        } = this.state;
        if (
          formBankValid.name &&
          formBankValid.ibanNumber &&
          formBankValid.selectedBank
        ) {
          let formData = new FormData();
          formData.append("profile_id", id);
          formData.append("iban", `${ibanPrefix}${ibanNumber}`);
          formData.append("beneficiary_name", name);
          formData.append("bank_id", selectedBank.value);
          formData.append("is_primary", isPrimary ? 1 : 0);
          // formData.append('status', 1)
          formData.append("bank_cert", bankAttachment);

          const response = await apiHalper(
            "addBank",
            "POST",
            formData,

          );

          if (response.data.status) {
            const addedData = {
              iban: ibanNumber,
              beneficiary_name: name,
              name: selectedBank.label,
              is_prime: isPrimary,
              bank_certificate: bankAttachment,
            };
            this.setState({
              connectedBanksList: [...connectedBanksList, addedData],
              isAddPayment: !this.state.isAddPayment,
            });
            toster_sucess("Paymnet details added Successfully...");
          } else {
            toster_error(response.data.message);
          }
        } else {
          this.setState({
            formBankError: {
              ...formBankError,
              selectedBank: !formBankValid.selectedBank
                ? "Please select your bank"
                : "",
              name: !formBankValid.name ? "Name is required!" : "",
              ibanNumber: !formBankValid.ibanNumber
                ? "IBAN number is required!"
                : "",
            },
            formBankValid: {
              ...formBankValid,
              selectedBank: !formBankValid.selectedBank ? false : true,
              name: !formBankValid.name ? false : true,
              ibanNumber: !formBankValid.ibanNumber ? false : true,
            },
          });
        }
      }
    } catch (err) {
      toster_error(err.message);
    }
  };
  deleteVatDetails = async (e) => {
    try {
      e.preventDefault();

      const { vat_registration_id } = this.state;

      let formData = new FormData();
      formData.append("vat_registration_id", vat_registration_id);

      const response = await apiHalper(
        "deleteVatRegistration",
        "POST",
        formData,

      );

      if (response.data.status) {
        this.setState({
          vatNumber: "",
          vatAttachment: "",
          vat_url: "",
          vat_registration_id: "",
        });
        toster_sucess("Paymnet details added Successfully...");
      } else {
        toster_error(response.data.message);
      }
    } catch (err) {
      toster_error(err.message);
    }
  };
  editVatDetails = async (e) => {
    try {
      e.preventDefault();

      const { vat_registration_id } = this.state;

      let formData = new FormData();
      formData.append("vat_registration_id", vat_registration_id);

      const response = await apiHalper(
        "deleteVatRegistration",
        "POST",
        formData,

      );

      if (response.data.status) {
        this.setState({
          vatNumber: "",
          vatAttachment: "",
          vat_url: "",
          vat_registration_id: "",
        });
        toster_sucess("Paymnet details added Successfully...");
      } else {
        toster_error(response.data.message);
      }
    } catch (err) {
      toster_error(err.message);
    }
  };
  deleteCRDetails = async (e) => {
    try {
      e.preventDefault();
      const { commercial_registration_id } = this.state;

      let formData = new FormData();
      formData.append("commercial_registration_id", commercial_registration_id);

      const response = await apiHalper(
        "deleteCommercialRegistration",
        "POST",
        formData,
      );

      if (response.data.status) {
        this.setState({
          cr_number: "",
          commercial_certi: "",
          cr_url: "",
          commercial_registration_id: "",
        });
        toster_sucess("CR deleted Successfully...");
      } else {
        toster_error(response.data.message);
      }
    } catch (err) {
      toster_error(err.message);
    }
  };
  editCRDetails = async (e) => {
    try {
      e.preventDefault();
      const { commercial_registration_id } = this.state;

      let formData = new FormData();
      formData.append("commercial_registration_id", commercial_registration_id);

      const response = await apiHalper(
        "deleteCommercialRegistration",
        "POST",
        formData,
      );

      if (response.data.status) {
        this.setState({
          cr_number: "",
          commercial_certi: "",
          cr_url: "",
          commercial_registration_id: "",
        });
        toster_sucess("VAT deleted Successfully...");
      } else {
        toster_error(response.data.message);
      }
    } catch (err) {
      toster_error(err.message);
    }
  };
  deleteConnectedSocialMedia = async (data, index) => {
    try {
      const { connectedSocialMediaList } = this.state;
      const { commercial_registration_id } = this.state;

      let formData = new FormData();
      formData.append("social_platform_id", data.id);

      const response = await apiHalper(
        "deleteSocialPlatforms",
        "POST",
        formData,
      );

      if (response.data.status) {
        const newData = connectedSocialMediaList.filter((_, i) => i !== index);

        this.setState({
          connectedSocialMediaList: newData,
        });
        toster_sucess("Paymnet details added Successfully...");
      } else {
        toster_error(response.data.message);
      }
    } catch (err) {
      toster_error(err.message);
    }
  };

  handlePrifix = (index, selectedOption) => {
    const newFormData = [...this.state.socialMedias];
    newFormData[index].socialMediaprifix = selectedOption;
    this.setState({ socialMedias: newFormData });
  };

  handleSocialMediaType = (index, selectedOption) => {
    const newFormData = [...this.state.socialMedias];
    newFormData[index].selectedSocialMediaType = selectedOption;
    newFormData[index].socialMediaType = selectedOption.value;
    newFormData[index].socialMediaplatformsTypeList =
      selectedOption.platforms.map((info) => ({
        value: info.id,
        label: info.name,
        filename: info.filename,
        web_url: info.web_url,
      }));
    this.setState({ socialMedias: newFormData });
  };

  handleSocialMediaPlatformType = (index, selectedOption) => {
    const newFormData = [...this.state.socialMedias];
    newFormData[index].selectedSocialMediaplatformsType = selectedOption;
    newFormData[index].socialMediaplatformsType = selectedOption.value;
    this.setState({ socialMedias: newFormData });
  };
  handleDeleteMoreSocialMedia = (index) => {
    const newFormData = [...this.state.socialMedias];
    newFormData.splice(index, 1);
    this.setState({ socialMedias: newFormData });
  };

  handleInputUsernameChange = (index, event) => {
    const newFormData = [...this.state.socialMedias];
    newFormData[index].socialMediaUserName = event.target.value;
    this.setState({ socialMedias: newFormData });
  };
  handleInputFollowersChange = (index, event) => {
    const newFormData = [...this.state.socialMedias];
    newFormData[index].socialMediaFollowers = event.target.value;
    this.setState({ socialMedias: newFormData });
  };


  handleAddMoreSocialMedia = () => {
    const currentSetFilled = this.state.socialMedias.every(
      (field) =>
        field.socialMediaUserName.trim() !== "" &&
        field.socialMediaFollowers !== 0 &&
        field.socialMediaprifix !== ""
    );

    if (currentSetFilled) {
      const newFormData = [
        ...this.state.socialMedias,
        {
          socialMediaplatformsTypeList: [],
          socialMediaType: "",
          socialMediaplatformsType: "",
          socialMediaUserName: "",
          socialMediaFollowers: 0,
          socialMediaprifix: "",
          selectedSocialMediaType: "",
          selectedSocialMediaplatformsType: "",
        },
      ];
      this.setState({ socialMedias: newFormData, socialMediaError: "" });
    } else {
      this.setState({
        socialMediaError:
          "All fields in the current set are required. Please fill before adding a new one.",
      });
    }
  };

  summarySubmit = (e) => {
    e.preventDefault();
  };

  skillsEditBlock = () => {
    let skillView = this.state.isSkillsO;
    if (this.state.isSkillsO) {
      skillView = false;
    } else {
      skillView = true;
    }

    if (!skillView) {
      this.setState({
        selectSkills: null,
        sSkillsLevel: null,
      });
    }
    const { pageSkill, searchSkill, isPrevSearchSkill } = this.state;
    if (skillView) {
      if (this.state.allSkillBack.length === 0) {
        this.getSkills(pageSkill, searchSkill, isPrevSearchSkill);
      }
    }
    this.setState({
      isSkillsO: skillView,
      isEdit: !this.state.isEdit,
      isAgencyEdit: false,
      isAddCR: false,
      isAddMawthooq: false,
      isAddPayment: false,
      isAddTags: false,
      isAddCategory: false,
      isAddWebsite: false,
      isAddSocialMedia: false,
      isAddVatReg: false,
      isAddLang: false,
    });
  };

  radioHandle = (e, val) => {
    const { name } = e.target;
    switch (name) {
      case "profile_visibilty_status_private":
        this.setState({ profile_invisible: val });
        break;
      case "profile_visibilty_status_public":
        this.setState({ profile_invisible: val });
        break;
      case "bussiness_email_status_private":
        this.setState({ bussiness_email_status: val });
        break;
      case "bussiness_email_status_public":
        this.setState({ bussiness_email_status: val });
        break;
      case "name_public":
        this.setState({ name_public: val });
        break;
      case "phone_public":
        this.setState({ phone_public: val });
        break;
      case "email_public":
        this.setState({ email_public: val });
        break;
      case "about_public":
        this.setState({ about_public: val });
        break;
      case "website_public":
        this.setState({ website_public: val });
        break;
      case "address_public":
        this.setState({ address_public: val });
        break;
      default:
        break;
    }
  };
  handleSkill = (selectedOption) => {
    this.setState({
      selectSkills: selectedOption,
    });
  };
  handleSLevel = (selectedOption) => {
    this.setState({
      sSkillsLevel: selectedOption,
    });
  };

  skillsDelete = async (data) => {
    try {
      const { agentSkills } = this.state;

      const newDeteleArr = agentSkills.filter((info) => info.id !== data.id);
      const newSkilId = newDeteleArr.map((info) => info.id);
      const newSkillLevel = newDeteleArr.map((info) => info.rating);

      const { id } = this.props.match.params;

      let formData = new FormData();
      formData.append("profile_id", id);
      formData.append("skill_id", JSON.stringify(newSkilId));
      formData.append("rating", JSON.stringify(newSkillLevel));

      const response = await apiHalper(
        "addProfileSkills",
        "POST",
        formData,
      );

      if (response.data.status) {
        const { allSkill } = this.state;
        const decode = jwtDecode(response.data.data);
        let remainSkills = [];
        if (decode.length > 0) {
          for (let index = 0; index < decode.length; index++) {
            remainSkills = allSkill.filter(
              (info) => info.value !== decode[index].id
            );
          }
        }
        if (this._unmounted) {
          this.setState({
            agentSkills: decode,
            allSkill: remainSkills,
          });
        }

        toster_sucess("Skills Delete Successfully...");
      } else {

        toster_error(response.data.message);
      }
    } catch (err) {
      toster_error(err.message);
    }
  };

  skillsSubmit = async (e) => {
    try {
      e.preventDefault();

      const { agentSkills, editSkill, selectSkills, sSkillsLevel } = this.state;

      if (this._unmounted) {
        this.setState({
          isSLoader: true,
          skillErr: "",
        });
      }

      let newSkilId = [];
      let newSkillLevel = [];
      let isChange = false;

      if (agentSkills.length > 0) {
        if (editSkill !== null) {
          const exitsID = agentSkills.filter(
            (info) => info.id === editSkill.id
          );

          if (
            exitsID[0].id !== selectSkills.value &&
            exitsID[0].rating !== sSkillsLevel.value
          ) {
            isChange = true;
            const newArr = agentSkills.map((info) => info.id);
            const indexLang = newArr.indexOf(exitsID[0].id);
            newSkilId = Object.assign([], newArr, {
              [indexLang]: selectSkills.value,
            });

            const levelArr = agentSkills.map((info) => info.rating);
            const indexLevel = levelArr.indexOf(exitsID[0].rating);
            newSkillLevel = Object.assign([], levelArr, {
              [indexLevel]: sSkillsLevel.value,
            });
          } else if (
            exitsID[0].id !== selectSkills.value ||
            exitsID[0].rating !== sSkillsLevel.value
          ) {
            isChange = true;
            if (exitsID[0].id !== selectSkills.value) {
              const newArr1 = agentSkills.map((info) => info.id);
              const indexLang1 = newArr1.indexOf(exitsID[0].id);
              newSkilId = Object.assign([], newArr1, {
                [indexLang1]: selectSkills.value,
              });

              newSkillLevel = agentSkills.map((info) => info.rating);
            }

            if (exitsID[0].rating !== sSkillsLevel.value) {
              newSkilId = agentSkills.map((info) => info.id);
              const levelArr1 = agentSkills.map((info) => info.rating);
              const indexLevel1 = levelArr1.indexOf(exitsID[0].rating);
              newSkillLevel = Object.assign([], levelArr1, {
                [indexLevel1]: sSkillsLevel.value,
              });
            }
          } else {
            isChange = false;
          }
        } else {
          isChange = true;

          newSkilId = agentSkills.map((info) => info.id);
          newSkilId.push(selectSkills.value);

          newSkillLevel = agentSkills.map((info) => info.rating);
          newSkillLevel.push(sSkillsLevel.value);
        }
      } else {
        isChange = true;
        newSkilId.push(selectSkills.value);
        newSkillLevel.push(sSkillsLevel.value);
      }

      if (isChange) {
        const { id } = this.props.match.params;

        let formData = new FormData();
        formData.append("profile_id", id);
        formData.append("skill_id", JSON.stringify(newSkilId));
        formData.append("rating", JSON.stringify(newSkillLevel));

        const response = await apiHalper(
          "addProfileSkills",
          "POST",
          formData,
        );

        if (response.data.status) {
          const { allSkill } = this.state;
          const data = jwtDecode(response.data.data);
          let remainSkills = [];
          if (data.length > 0) {
            for (let h = 0; h < data.length; h++) {
              remainSkills = allSkill.filter(
                (info) => info.value !== data[h].id
              );
            }
          }
          if (this._unmounted) {
            this.setState({
              agentSkills: data,
              allSkill: remainSkills,
              isSLoader: false,
              skillErr: "",
              isSkillsO: false,
              sSkillsLevel: null,
              selectSkills: null,
            });
          }

          toster_sucess("Skills Added Successfully...");
        } else {
          if (this._unmounted) {
            this.setState({
              isSLoader: false,
              skillErr: "",
              isSkillsO: true,
            });
          }

          toster_error(response.data.message);
        }
      } else {
        if (this._unmounted) {
          this.setState({
            isSLoader: false,
            isSkillsO: false,
          });
        }
      }
    } catch (err) {
      toster_error(err.message);
      if (this._unmounted) {
        this.setState({
          isSLoader: false,
          isSkillsO: false,
        });
      }
    }
  };

  officeBaseClik = (val) => {
    this.setState({
      officeBaseVal: val,
    });
  };

  homeBaseClik = (val) => {
    this.setState({
      homeBaseVal: val,
    });
  };

  fixedPricedCl = (val) => {
    this.setState({
      fixedPrice: val,
    });
  };
  hourPricedCl = (val) => {
    this.setState({
      hourPrice: val,
    });
  };
  partTimeCl = (val) => {
    this.setState({
      partTime: val,
    });
  };
  fullTimeCl = (val) => {
    this.setState({
      fullTime: val,
    });
  };
  freeCl = (val) => {
    this.setState({
      freeA: val,
    });
  };

  fetchMoreData = () => {
    const inc = this.state.pageSkill + 1;
    if (this.state.isSkillSLoader) {
      this.setState({
        pageSkill: inc,
      });
      this.getSkills(inc, this.state.searchSkill, this.state.isPrevSearchSkill);
    } else {
      this.setState({
        isSkillSLoader: false,
      });
    }
  };

  modalCloseConfirm = () => {
    this.setState({
      isUserProModal: false,
    });
  };
  modalCloseConfirmClient = () => {
    this.setState({
      isUserProModalClient: false,
    });
  };

  modalOpenConfirm = () => {
    this.setState({
      isUserProModal: true,
    });
  };
  modalOpenConfirmClient = () => {
    this.setState({
      isUserProModalClient: true,
    });
  };

  openEduc = () => {
    if (!this.state.isEducation) {
      this.setState({
        eduLevel: null,
      });
    }
    this.setState({
      isEducation: !this.state.isEducation,
    });
  };

  setStartDate = (date) => {
    this.setState({
      startDate: date,
    });
  };

  setEndDate = (date) => {
    this.setState({
      endDate: date,
    });
  };
  setIssueDate = (date) => {
    this.setState({
      issue_date: date,
    });
  };

  setExpiryDate = (date) => {
    this.setState({
      expiry_date: date,
    });
  };
  handleChangeMawthooq = (e) => {
    this.setState({
      mawthooq: e.target.files[0],
    });
  };
  handleChangeCR = (e) => {
    const { formCrError, formCrValid } = this.state;
    this.setState({
      commercial_certi: e.target.files[0],
      formCrError: {
        ...formCrError,
        commercial_certi: "",
      },
      formCrValid: {
        ...formCrValid,
        commercial_certi: true,
      },
    });
  };
  handleChangeVat = (e) => {
    const { formVatError, formVatValid } = this.state;
    this.setState({
      vatAttachment: e.target.files[0],
      formVatError: {
        ...formVatError,
        vatAttachment: "",
      },
      formVatValid: {
        ...formVatValid,
        vatAttachment: true,
      },
    });
  };
  handleChangeBankCerti = (e) => {
    this.setState({
      bankAttachment: e.target.files[0],
      formBankError: {
        ...this.state.formBankError,
        bankAttachment: "",
      },
      formBankValid: {
        ...this.state.formBankValid,
        bankAttachment: true,
      },
    });
  };
  radioHandler = (mawthooqType) => {
    this.setState({ mawthooqType });
  };
  setBankAsPrimary = (e) => {
    let checked = e.target.checked;
    let value = 0;
    if (checked) {
      value = 1;
    }
    this.setState({
      isPrimary: value,
    });
  };
  delEduction = async (data) => {
    try {
      const { id } = this.props.match.params;
      let formData = new FormData();
      formData.append("profile_id", id);
      formData.append("education_id", data.id);

      const response = await apiHalper(
        "deleteEducations",
        "POST",
        formData,
      );
      const res = response.data;
      if (res.status) {
        this.setState({
          isUserLoader: false,
        });
      }
    } catch (err) {
      toster_error(err);
    }
  };
  EducationSubmit = async (e) => {
    try {
      e.preventDefault();
      if (this._unmounted) {
        this.setState({
          isUserProModal: false,
          isUserLoader: true,
          userError: "",
        });
      }



      const { id } = this.props.match.params;

      const { startDate, endDate, university, degree, selectedOption } =
        this.state;

      let formData = new FormData();
      formData.append("profile_id", id);
      formData.append("degree", degree);
      formData.append("school_name", university);
      formData.append("start_date", moment(startDate).format("YYYY-MM-DD"));
      formData.append("end_date", moment(endDate).format("YYYY-MM-DD"));
      formData.append("level", selectedOption !== null ? selectedOption : "");

      const response = await apiHalper(
        "addEducations",
        "POST",
        formData,
      );
      const res = response.data;
      if (res.status) {
        if (this._unmounted) {
          this.setState({
            isUserLoader: false,
            userError: "",
            isEdit: false,
          });
          toster_sucess("Education Detail Added Successfully...");
        }
      } else {
        if (this._unmounted) {
          this.setState({
            isUserLoader: false,
            userError: res.message,
          });
          toster_error(res.message);
        }
      }
    } catch (err) {
      toster_error(err.message);
    }
  };

  render() {
    const {
      startDate,
      endDate,
      degree,
      university,
      eduLevel,
      eduFormError,
      eduFormValid,
      firstname,
      lastname,
      username,
      email,
      minprice,
      maxprice,
      isEdit,
      mobile,
      mobileError,
      mobileValid,
      formError,
      formValid,
      isAddLang,
      isEditLang,
      isExpertise,
      allLang,
      isAddMawthooq,
      mawthooqError,
      mawthooqType,
      mawthooq,
      issue_date,
      expiry_date,
      mawthooq_id,
      isAddPayment,
      isEditPayment,
      bankAttachmentURL,
      isPrimary,
      formBankError,
      isAddTags,
      selectedTags,
      tagsOption,
      agentAllTags,
      isAddCategory,
      categories,
      categoriesAllData,
      selectedCategory,
      addCategoryList,
      isAddVatReg,
      vatType,
      vatNumber,
      formVatError,
      cr_number,
      formCrError,
      isAddCR,
      isAddSocialMedia,
      isEditSocialMedia,
      socialMediaError,
      socialMedias,
      isAgencyEdit,
      agencyName,
      name_public,
      agencyPhone,
      phone_public,
      agencyEmail,
      email_public,
      about,
      about_public,
      agencyWebsite,
      website_public,
      address,
      address_public,
      formErrorAgency,
      formValidAgency,
      commercial_certi,
      cr_url,
      vatAttachment,
      vat_url,
      selectedLanguages,
      gender,
      genderOptions,
      bussiness_email,
      bussiness_email_status,
      profile_invisible,
    } = this.state;
    const { userDetails, type } = this.props;

    const disabled =
      type === "agents"
        ? !formValid.firstname ||
        !formValid.lastname ||
        !formValid.username ||
        !formValid.bussiness_email ||
        !formValid.email ||
        !mobileValid
        : !formValid.firstname ||
        !formValid.lastname ||
        !formValid.username ||
        !formValid.email ||
        !mobileValid ||
        !formValid.brand ||
        !formValid.company;
    const disabledAgency =
      !formValidAgency.agencyName ||
      !formValidAgency.agencyEmail ||
      !formValidAgency.agencyPhone ||
      !formValidAgency.agencyWebsite ||
      !formValidAgency.about ||
      !formValidAgency.address;
    const disabledEdu =
      !eduFormValid.degree ||
      !eduFormValid.university ||
      !eduLevel ||
      !startDate ||
      !endDate;

    const imgfilePath = localStorage.getItem("filePath");
    const decodePath = jwtDecode(imgfilePath);
    let resume = null;
    if (userDetails !== null) {
      if (type === "agent") {
        if (userDetails !== null) {
          const arr = userDetails ? userDetails.resumes.split("/") : [];
          const index = arr.length - 1;
          resume = arr[index];
        }
      }
    }
    const options = [
      { label: "English", value: 0 },
      { label: "Hindi", value: 1 },
      { label: "Gujarati", value: 2 },
      { label: "Marathi", value: 3 },
    ];

    const optionsLevel = [
      { label: "Basic", value: 0 },
      { label: "Conversational", value: 1 },
      { label: "Fluent", value: 2 },
      { label: "Native", value: 3 },
    ];

    const optionsEdu = [
      { label: "Associate", value: 0 },
      { label: "Bachelor", value: 1 },
      { label: "Master", value: 2 },
      { label: "Doctorate", value: 3 },
    ];

    const optionsExp = [
      { label: "Less than 1 year", value: 0 },
      { label: "1-3 years", value: 1 },
      { label: "4-6 years", value: 2 },
      { label: "7-9 years", value: 3 },
      { label: "10-12 years", value: 4 },
      { label: "13-15 years", value: 5 },
      { label: "16-18 years", value: 6 },
      { label: "19-21 years", value: 7 },
      { label: "22-24 years", value: 8 },
      { label: "25-27 years", value: 9 },
      { label: "28-30 years", value: 10 },
      { label: "31+ years", value: 11 },
    ];
    const confirmModalData = {
      isOpen: this.state.isUserProModal,
      modalClose: this.modalCloseConfirm,
      text: "Update user details?",
      confirmHandler: this.submitUserProfile,
    };
    const confirmModalDataClient = {
      isOpen: this.state.isUserProModalClient,
      modalClose: this.modalCloseConfirmClient,
      text: "Update user details?",
      confirmHandler: this.submitUserProfileClient,
    };
    const DeleteModalData = {
      title: this.state.title,
      text: this.state.text,
      handleClose: this.closeCofiClose,
      isModal: this.state.isConFModal,
      submitFunc: this.deleteProfile,
      isLoader: this.state.isDLoader,
    };
    return (
      <div className="tab_container">
        {type === "agents" ? (
          <div className="form_wrap">
            <form method="POST" action="/" onSubmit={this.updateUserD}>
              <div className="form_group divd_wrap">
                <div className="wid_full">
                  <label className="label_form">First Name</label>
                  <input
                    type="text"
                    className={
                      formError.firstname !== ""
                        ? "form_control error_border"
                        : "form_control"
                    }
                    value={firstname}
                    name="firstname"
                    placeholder="First Name"
                    onChange={this.inputHandler}
                    autoComplete="off"
                    disabled={!isEdit}
                  />
                  {formError.firstname !== "" ? (
                    <p className="error_text">{formError.firstname}</p>
                  ) : null}
                </div>
                <div className="wid_full">
                  <label className="label_form">Last Name</label>
                  <input
                    type="text"
                    className={
                      formError.lastname !== ""
                        ? "form_control error_border"
                        : "form_control"
                    }
                    value={lastname}
                    name="lastname"
                    placeholder="Last Name"
                    onChange={this.inputHandler}
                    autoComplete="off"
                    disabled={!isEdit}
                  />
                  {formError.lastname !== "" ? (
                    <p className="error_text">{formError.lastname}</p>
                  ) : null}
                </div>
              </div>
              <div className="form_group divd_wrap">
                <div className="wid_full">
                  <label className="label_form">User Name</label>
                  <input
                    type="text"
                    className={
                      formError.username !== ""
                        ? "form_control error_border"
                        : "form_control"
                    }
                    value={username}
                    name="username"
                    placeholder="User Name"
                    onChange={this.inputHandler}
                    autoComplete="off"
                    disabled={!isEdit}
                  />
                  {formError.username !== "" ? (
                    <p className="error_text">{formError.username}</p>
                  ) : null}
                </div>
                <div className="wid_full">
                  <label className="label_form">Email</label>
                  <input
                    type="email"
                    className={
                      formError.email !== ""
                        ? "form_control error_border"
                        : "form_control"
                    }
                    value={email}
                    name="email"
                    placeholder="Email Address"
                    onChange={this.inputHandler}
                    autoComplete="off"
                    disabled={!isEdit}
                  />
                  {formError.email !== "" ? (
                    <p className="error_text">{formError.email}</p>
                  ) : null}
                </div>
              </div>
              <div className="form_group divd_wrap">
                <div className="wid_full">
                  <label className="label_form">Password</label>
                  <input
                    type="password"
                    className={
                      formError.password !== ""
                        ? "form_control error_border"
                        : "form_control"
                    }
                    value={this.state.password}
                    name="password"
                    placeholder="New Password"
                    onChange={this.inputHandler}
                    autoComplete="off"
                    disabled={!isEdit}
                  />
                </div>
                <div className="wid_full">
                  <label className="label_form">Mobile</label>
                  <IntlTelInput
                    onPhoneNumberChange={this.handlePhone}
                    defaultCountry="sa"
                    onSelectFlag={this.getCountry}
                    value={mobile || ""}
                    disabled={!isEdit}
                    inputClassName={
                      mobileError !== ""
                        ? "form_control error_border"
                        : "form_control"
                    }
                    formatOnInit={true}
                  />
                  {mobileError !== "" ? (
                    <p className="error_text">{mobileError}</p>
                  ) : null}
                </div>
              </div>
              <div className="form_group divd_wrap">
                <div className="wid_full">
                  <label className="label_form">Min-Price</label>
                  <input
                    type="text"
                    className="minprice form_control"
                    value={minprice}
                    name="minprice"
                    placeholder="Min Price"
                    onChange={this.inputHandler}
                    autoComplete="off"
                    disabled={!isEdit}
                  />
                </div>
                <div className="wid_full">
                  <label className="label_form">Max-Price</label>
                  <input
                    type="text"
                    className="maxprice form_control"
                    value={maxprice}
                    name="maxprice"
                    placeholder="Max Price"
                    onChange={this.inputHandler}
                    autoComplete="off"
                    disabled={!isEdit}
                  />
                </div>
              </div>
              <div className="form_group divd_wrap">
                <div className="wid_full">
                  <div className="d-flex justify-content-between align-items-center gap-2 mb-2">
                    <label className="label_form w-auto white-no-wrap mb-0">
                      Bussiness Email
                    </label>
                    <div className="d-flex w-100 ms-auto justify-content-end gap-2 align-items-center">
                      <div className={"wrap_notialert d-flex"}>
                        <input
                          type="radio"
                          id="bussiness_email_status_public"
                          name="bussiness_email_status_public"
                          checked={bussiness_email_status === 1}
                          disabled={!isEdit}
                          onChange={(e) => {
                            this.radioHandle(e, 1);
                          }}
                        />
                        <label htmlFor="bussiness_email_status_public">
                          Public
                        </label>
                        <input
                          type="radio"
                          id="bussiness_email_status_private"
                          name="bussiness_email_status_private"
                          checked={bussiness_email_status === 2}
                          disabled={!isEdit}
                          onChange={(e) => {
                            this.radioHandle(e, 2);
                          }}
                        />
                        <label htmlFor="bussiness_email_status_private">
                          Private
                        </label>
                      </div>
                    </div>
                  </div>
                  <input
                    type="text"
                    className={
                      formError.email !== ""
                        ? "form_control error_border"
                        : "form_control"
                    }
                    value={bussiness_email}
                    name="bussiness_email"
                    placeholder="Bussiness Email"
                    onChange={this.inputHandler}
                    autoComplete="off"
                    disabled={!isEdit}
                  />
                  {formError.bussiness_email !== "" ? (
                    <p className="error_text">{formError.bussiness_email}</p>
                  ) : null}
                </div>
                <div className="wid_full">
                  <label className="label_form">Gender</label>
                  <Select
                    value={gender}
                    onChange={this.handleGenderChange}
                    options={genderOptions}
                    placeholder="Gender"
                    isDisabled={!isEdit}
                  />
                </div>
              </div>
              <div className="form_btngroup">
                {!isEdit ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      maxWidth: "100%",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <button
                        type="button"
                        className="btn_edit"
                        onClick={this.editHandle}
                      >
                        Edit
                      </button>
                      <button
                        style={{
                          marginLeft: "10px",
                          marginBottom: "0",
                          padding: "8px 15px",
                          cursor: "pointer",
                        }}
                        className="btn-delete"
                        onClick={() => this.deleteConfirmation(userDetails.id)}
                      >
                        Delete Account
                      </button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <button
                      type="button"
                      className="btn_save"
                      disabled={disabled || this.state.isUserLoader}
                      onClick={this.modalOpenConfirm}
                    >
                      {this.state.isUserLoader ? (
                        <img
                          src={LoaderImg}
                          className="small-loader"
                          alt="loader"
                        />
                      ) : (
                        "Save"
                      )}
                    </button>
                    <button
                      type="button"
                      className="btn_cancel"
                      onClick={this.editHandle}
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>
            </form>
          </div>
        ) : (
          <div className="form_wrap">
            <form method="POST" action="/" onSubmit={this.updateUserD}>
              <div className="form_group divd_wrap">
                <div className="wid_full">
                  <label className="label_form">First Name</label>
                  <input
                    type="text"
                    className={
                      formError.firstname !== ""
                        ? "form_control error_border"
                        : "form_control"
                    }
                    value={firstname}
                    name="firstname"
                    placeholder="First Name"
                    onChange={this.inputHandler}
                    autoComplete="off"
                    disabled={!isEdit}
                  />
                  {formError.firstname !== "" ? (
                    <p className="error_text">{formError.firstname}</p>
                  ) : null}
                </div>
                <div className="wid_full">
                  <label className="label_form">Last Name</label>
                  <input
                    type="text"
                    className={
                      formError.lastname !== ""
                        ? "form_control error_border"
                        : "form_control"
                    }
                    value={lastname}
                    name="lastname"
                    placeholder="Last Name"
                    onChange={this.inputHandler}
                    autoComplete="off"
                    disabled={!isEdit}
                  />
                  {formError.lastname !== "" ? (
                    <p className="error_text">{formError.lastname}</p>
                  ) : null}
                </div>
              </div>
              <div className="form_group divd_wrap">
                <div className="wid_full">
                  <label className="label_form">User Name</label>
                  <input
                    type="text"
                    className={
                      formError.username !== ""
                        ? "form_control error_border"
                        : "form_control"
                    }
                    value={username}
                    name="username"
                    placeholder="User Name"
                    onChange={this.inputHandler}
                    autoComplete="off"
                    disabled={!isEdit}
                  />
                  {formError.username !== "" ? (
                    <p className="error_text">{formError.username}</p>
                  ) : null}
                </div>
                <div className="wid_full">
                  <label className="label_form">Email</label>

                  <input
                    type="email"
                    className={
                      formError.email !== ""
                        ? "form_control error_border"
                        : "form_control"
                    }
                    value={email}
                    name="email"
                    placeholder="Email Address"
                    onChange={this.inputHandler}
                    autoComplete="off"
                    disabled={!isEdit}
                  />
                  {formError.email !== "" ? (
                    <p className="error_text">{formError.email}</p>
                  ) : null}
                </div>
              </div>
              <div className="form_group divd_wrap">
                <div className="wid_full">
                  <label className="label_form">Password</label>
                  <input
                    type="password"
                    className={
                      formError.password !== ""
                        ? "form_control error_border"
                        : "form_control"
                    }
                    value={this.state.password}
                    name="password"
                    placeholder="New Password"
                    onChange={this.inputHandler}
                    autoComplete="off"
                    disabled={!isEdit}
                  />
                </div>
                <div className="wid_full">
                  <label className="label_form">Mobile</label>
                  <IntlTelInput
                    onPhoneNumberChange={this.handlePhone}
                    defaultCountry="sa"
                    onSelectFlag={this.getCountry}
                    value={mobile || ""}
                    disabled={!isEdit}
                    inputClassName={
                      mobileError !== ""
                        ? "form_control error_border"
                        : "form_control"
                    }
                    formatOnInit={true}
                  />
                  {mobileError !== "" ? (
                    <p className="error_text">{mobileError}</p>
                  ) : null}
                </div>
              </div>
              <div className="form_group divd_wrap">
                <div className="wid_full">
                  <label className="label_form">Brand Name</label>
                  <input
                    type="text"
                    className={
                      formError.brand !== ""
                        ? "form_control error_border"
                        : "form_control"
                    }
                    value={this.state.brand}
                    name="brand"
                    placeholder="Brand name"
                    onChange={this.inputHandler}
                    autoComplete="off"
                    disabled={!isEdit}
                  />
                  {formError.brand !== "" ? (
                    <p className="error_text">{formError.brand}</p>
                  ) : null}
                </div>
                <div className="wid_full">
                  <label className="label_form">Company Name</label>
                  <input
                    type="text"
                    className={
                      formError.company !== ""
                        ? "form_control error_border"
                        : "form_control"
                    }
                    value={this.state.company}
                    name="company"
                    placeholder="Company name"
                    onChange={this.inputHandler}
                    autoComplete="off"
                    disabled={!isEdit}
                  />
                  {formError.company !== "" ? (
                    <p className="error_text">{formError.company}</p>
                  ) : null}
                </div>
              </div>


              <div className="form_btngroup">
                {!isEdit ? (
                  <div style={{ maxWidth: "none" }}>
                    <button
                      type="button"
                      className="btn_edit"
                      onClick={this.editHandle}
                    >
                      Edit
                    </button>

                    <button
                      style={{
                        marginLeft: "10px",
                        padding: "8px 15px",
                        cursor: "pointer",
                      }}
                      className="btn-delete"
                      onClick={() => this.deleteConfirmation(userDetails.id)}
                    >
                      Delete Account{" "}
                    </button>
                  </div>
                ) : (
                  <div>
                    <button
                      type="button"
                      className="btn_save"
                      disabled={disabled || this.state.isUserLoader}
                      onClick={this.modalOpenConfirmClient}
                    >
                      {this.state.isUserLoader ? (
                        <img
                          src={LoaderImg}
                          className="small-loader"
                          alt="loader"
                        />
                      ) : (
                        "Save"
                      )}
                    </button>
                    <button
                      type="button"
                      className="btn_cancel"
                      onClick={this.editHandle}
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>
            </form>
          </div>
        )}
        {type === "agents" ? (
          <div
            className="form_wrap mt-4 overflow-y-auto"
            style={{ overflowY: "auto " }}
          >
            <h4 className="title_lgng">Agency Info </h4>
            <form>
              <div className="form_group divd_wrap">
                <div className="wid_full">
                  <div className="d-xl-flex d-block  justify-content-between align-items-center gap-2 mb-2">
                    <label className="label_form w-auto white-no-wrap mb-0">
                      Agency Name
                    </label>
                    <div className=" text-nowrap d-flex w-100 ms-auto justify-content-xl-end gap-2 align-items-center">
                      <p>Make public</p>
                      <div className={"wrap_notialert d-flex"}>
                        <input
                          type="radio"
                          id="name_public"
                          name="name_public"
                          checked={name_public === 0}
                          disabled={!isAgencyEdit}
                          onChange={(e) => this.radioHandle(e, 0)}
                        />
                        <label htmlFor="name_public">No</label>
                        <input
                          type="radio"
                          id="not_name_public"
                          name="name_public"
                          checked={name_public === 1}
                          disabled={!isAgencyEdit}
                          onChange={(e) => this.radioHandle(e, 1)}
                        />
                        <label htmlFor="not_name_public">Yes</label>
                      </div>
                    </div>
                  </div>
                  <input
                    type="text"
                    className={
                      formErrorAgency.agencyName !== ""
                        ? "form_control error_border"
                        : "form_control"
                    }
                    value={agencyName}
                    name="agencyName"
                    placeholder="Agency Name"
                    onChange={this.inputHandlerAgency}
                    autoComplete="off"
                    disabled={!isAgencyEdit}
                  />
                  {formErrorAgency.agencyName !== "" ? (
                    <p className="error_text">{formErrorAgency.agencyName}</p>
                  ) : null}
                </div>
                <div className="wid_full">
                  <div className="d-xl-flex d-block justify-content-between align-items-center gap-2 mb-2">
                    <label className="label_form w-auto white-no-wrap mb-0">
                      About
                    </label>
                    <div className=" text-nowrap d-flex w-100 ms-auto justify-content-xl-end gap-2 align-items-center">
                      <p>Make public</p>
                      <div className={"wrap_notialert d-flex"}>
                        <input
                          type="radio"
                          id="about_public"
                          name="about_public"
                          checked={about_public === 0}
                          disabled={!isAgencyEdit}
                          onChange={(e) => this.radioHandle(e, 0)}
                        />
                        <label htmlFor="about_public">No</label>
                        <input
                          type="radio"
                          id="not_about_public"
                          name="about_public"
                          checked={about_public === 1}
                          disabled={!isAgencyEdit}
                          onChange={(e) => this.radioHandle(e, 1)}
                        />
                        <label htmlFor="not_about_public">Yes</label>
                      </div>
                    </div>
                  </div>
                  <input
                    type="text"
                    className={
                      formErrorAgency.about !== ""
                        ? "form_control error_border"
                        : "form_control"
                    }
                    value={about}
                    name="about"
                    placeholder="About"
                    onChange={this.inputHandlerAgency}
                    autoComplete="off"
                    disabled={!isAgencyEdit}
                  />
                  {formErrorAgency.about !== "" ? (
                    <p className="error_text">{formErrorAgency.about}</p>
                  ) : null}
                </div>
              </div>
              <div className="form_group divd_wrap">
                <div className="wid_full">
                  <div className="d-xl-flex d-block  justify-content-between align-items-center gap-2 mb-2">
                    <label className="label_form w-auto white-no-wrap mb-0">
                      Phone
                    </label>
                    <div className=" text-nowrap d-flex w-100 ms-auto justify-content-xl-end gap-2 align-items-center">
                      <p>Make public</p>
                      <div className={"wrap_notialert d-flex"}>
                        <input
                          type="radio"
                          id="phone_public"
                          name="phone_public"
                          checked={phone_public === 0}
                          disabled={!isAgencyEdit}
                          onChange={(e) => this.radioHandle(e, 0)}
                        />
                        <label htmlFor="phone_public">No</label>
                        <input
                          type="radio"
                          id="not_phone_public"
                          name="phone_public"
                          checked={phone_public === 1}
                          disabled={!isAgencyEdit}
                          onChange={(e) => this.radioHandle(e, 1)}
                        />
                        <label htmlFor="not_phone_public">Yes</label>
                      </div>
                    </div>
                  </div>
                  <IntlTelInput
                    onPhoneNumberChange={this.handleAgencyPhone}
                    defaultCountry="sa"
                    onSelectFlag={this.getAgencyCountry}
                    value={agencyPhone || ""}
                    disabled={!isAgencyEdit}
                    inputClassName={
                      formErrorAgency.agencyPhone !== ""
                        ? "form_control error_border"
                        : "form_control"
                    }
                    formatOnInit={true}
                  />
                  {formErrorAgency.agencyPhone !== "" ? (
                    <p className="error_text">{formErrorAgency.agencyPhone}</p>
                  ) : null}
                </div>
                <div className="wid_full">
                  <div className="d-xl-flex d-block  justify-content-between align-items-center gap-2 mb-2">
                    <label className="label_form w-auto white-no-wrap mb-0">
                      Email
                    </label>
                    <div className="text-nowrap d-flex w-100 ms-auto justify-content-xl-end gap-2 align-items-center">
                      <p>Make public</p>
                      <div className={"wrap_notialert d-flex"}>
                        <input
                          type="radio"
                          id="email_public"
                          name="email_public"
                          checked={email_public === 0}
                          disabled={!isAgencyEdit}
                          onChange={(e) => this.radioHandle(e, 0)}
                        />
                        <label htmlFor="email_public">No</label>
                        <input
                          type="radio"
                          id="not_email_public"
                          name="email_public"
                          checked={email_public === 1}
                          disabled={!isAgencyEdit}
                          onChange={(e) => this.radioHandle(e, 1)}
                        />
                        <label htmlFor="not_email_public">Yes</label>
                      </div>
                    </div>
                  </div>
                  <input
                    type="email"
                    className={
                      formErrorAgency.agencyEmail !== ""
                        ? "form_control error_border"
                        : "form_control"
                    }
                    value={agencyEmail}
                    name="agencyEmail"
                    placeholder="Agency Email Address"
                    onChange={this.inputHandlerAgency}
                    autoComplete="off"
                    disabled={!isAgencyEdit}
                  />
                  {formErrorAgency.agencyEmail !== "" ? (
                    <p className="error_text">{formErrorAgency.agencyEmail}</p>
                  ) : null}
                </div>
              </div>
              <div className="form_group divd_wrap">
                <div className="wid_full">
                  <div className="d-xl-flex d-block  justify-content-between align-items-center gap-2 mb-2">
                    <label className="label_form w-auto white-no-wrap mb-0">
                      Website
                    </label>
                    <div className="text-nowrap d-flex w-100 ms-auto justify-content-xl-end gap-2 align-items-center">
                      <p>Make public</p>
                      <div className={"wrap_notialert d-flex"}>
                        <input
                          type="radio"
                          id="website_public"
                          name="website_public"
                          checked={website_public === 0}
                          disabled={!isAgencyEdit}
                          onChange={(e) => this.radioHandle(e, 0)}
                        />
                        <label htmlFor="website_public">No</label>
                        <input
                          type="radio"
                          id="not_website_public"
                          name="website_public"
                          checked={website_public === 1}
                          disabled={!isAgencyEdit}
                          onChange={(e) => this.radioHandle(e, 1)}
                        />
                        <label htmlFor="not_website_public">Yes</label>
                      </div>
                    </div>
                  </div>
                  <input
                    type="text"
                    className={
                      formErrorAgency.agencyWebsite !== ""
                        ? "form_control error_border"
                        : "form_control"
                    }
                    value={agencyWebsite}
                    name="agencyWebsite"
                    placeholder="New Agency Website"
                    onChange={this.inputHandlerAgency}
                    autoComplete="off"
                    disabled={!isAgencyEdit}
                  />
                  {formErrorAgency.agencyWebsite !== "" ? (
                    <p className="error_text">
                      {formErrorAgency.agencyWebsite}
                    </p>
                  ) : null}
                </div>
                <div className="wid_full">
                  <div className="d-xl-flex d-block  justify-content-between align-items-center gap-2 mb-2">
                    <label className="label_form w-auto white-no-wrap mb-0">
                      Address
                    </label>
                    <div className="text-nowrap d-flex w-100 ms-auto justify-content-xl-end gap-2 align-items-center">
                      <p>Make public</p>
                      <div className={"wrap_notialert d-flex"}>
                        <input
                          type="radio"
                          id="address_public"
                          name="address_public"
                          checked={address_public === 0}
                          disabled={!isAgencyEdit}
                          onChange={(e) => this.radioHandle(e, 0)}
                        />
                        <label htmlFor="address_public">No</label>
                        <input
                          type="radio"
                          id="not_address_public"
                          name="address_public"
                          checked={address_public === 1}
                          disabled={!isAgencyEdit}
                          onChange={(e) => this.radioHandle(e, 1)}
                        />
                        <label htmlFor="not_address_public">Yes</label>
                      </div>
                    </div>
                  </div>
                  <input
                    type="text"
                    className={
                      formErrorAgency.address !== ""
                        ? "form_control error_border"
                        : "form_control"
                    }
                    value={address}
                    name="address"
                    placeholder="User Address"
                    onChange={this.inputHandlerAgency}
                    autoComplete="off"
                    disabled={!isAgencyEdit}
                  />
                  {formErrorAgency.address !== "" ? (
                    <p className="error_text">{formErrorAgency.address}</p>
                  ) : null}
                </div>
              </div>
              <div className="form_btngroup">
                {!isAgencyEdit ? (
                  <div>
                    <button
                      type="button"
                      className="btn_edit"
                      onClick={this.editHandleAgency}
                    >
                      Edit
                    </button>
                  </div>
                ) : (
                  <div>
                    <button
                      type="button"
                      className="btn_save"
                      disabled={disabledAgency}
                      onClick={this.submitAgencyDetails}
                    >
                      {" "}
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn_cancel"
                      onClick={this.editHandleAgency}
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>
            </form>
          </div>
        ) : (
          ""
        )}
        <div className="main_prof_div">

          {type === "agents" ? (
            <>
              <div className="li_vie">
                <div className="lang_block">
                  <h4 className="title_lgng">
                    Category{" "}
                    <button
                      type="button"
                      className={
                        isAddCategory ? "btn_addimg active" : "btn_addimg"
                      }
                      onClick={this.openCategory}
                    ></button>
                  </h4>
                  <CategoryTagJSX
                    LoaderImg={LoaderImg}
                    isLangLoader={this.state.isLangLoader}
                    isAddCategory={isAddCategory}
                    addCategoryList={this.state.addCategoryList}
                    delAgentCatgeory={this.delAgentCatgeory}
                    categorySubmit={this.categorySubmit}
                    selectedCategory={selectedCategory}
                    handleCategoryChange={(e) => { this.setState({ selectedCategory: e }) }}
                    categories={categories}
                    openCategory={this.openCategory}
                    placeholder='Category'
                  />

                </div>
              </div>
              <div className="li_vie">
                <div className="lang_block">
                  <h4 className="title_lgng">
                    Tags{" "}
                    <button
                      type="button"
                      className={isAddTags ? "btn_addimg active" : "btn_addimg"}
                      onClick={this.openTags}
                    ></button>
                  </h4>
                  <CategoryTagJSX
                    LoaderImg={LoaderImg}
                    isLangLoader={this.state.isLangLoader}
                    isAddCategory={isAddTags}
                    addCategoryList={this.state.agentAllTags}
                    delAgentCatgeory={this.delAgentTag}
                    categorySubmit={this.tagsSubmit}
                    selectedCategory={selectedTags}
                    handleCategoryChange={(e) => { this.setState({ selectedTags: e }) }}
                    categories={tagsOption}
                    openCategory={this.openTags}
                    placeholder='Tags'
                  />

                </div>
              </div>
              <div className="li_vie">
                <div className="lang_block">
                  <h4 className="title_lgng">
                    Language{" "}
                    <button
                      type="button"
                      className={isAddLang ? "btn_addimg active" : "btn_addimg"}
                      onClick={this.openLanguage}
                    ></button>
                  </h4>
                  {!isAddLang && !isEditLang ? (
                    <ul className="list_vie">
                      {this.state.selectedLanglist.length > 0 ? (
                        this.state.selectedLanglist.map((data, i) =>
                          this.renderLangu(data, i)
                        )
                      ) : (
                        <li>No Record Found</li>
                      )}
                    </ul>
                  ) : (
                    <form
                      method="POST"
                      action="#javascript:void(0)"
                      onSubmit={this.LangSubmit}
                    >
                      {selectedLanguages.map((item, index) => (
                        <div className="form_group flex gap-4" key={index}>
                          <div className="w-100">
                            <Select
                              value={item.selectedLang}
                              placeholder={"Select Language"}
                              onChange={(selected) =>
                                this.handleChangeLanguage(selected, index)
                              }
                              options={allLang}
                            />
                          </div>
                          <div className="w-100">
                            <Select
                              value={item.selectedLevel}
                              placeholder={"Language Level"}
                              onChange={(selected) =>
                                this.handleChangeLangLevel(selected, index)
                              }
                              options={optionsLevel}
                            />
                          </div>
                          {!isEditLang && (
                            <Button
                              type="button"
                              variant="danger"
                              className="D-icon"
                              onClick={() => this.handleDeleteInputLang(index)}
                            >
                              <img src={DeleteIcon} alt={""} />
                            </Button>
                          )}
                        </div>
                      ))}
                      {!isEditLang && (
                        <div className="mb-2">
                          <Button
                            type="button"
                            onClick={this.handleAddMoreLanguage}
                          >
                            Add
                          </Button>
                        </div>
                      )}
                      <div className="form_group">
                        <button
                          type="submit"
                          className="btn_save"
                          disabled={this.state.isLangLoader}
                        >
                          {this.state.isLangLoader ? (
                            <img
                              src={LoaderImg}
                              className="small-loader"
                              alt="loader"
                            />
                          ) : (
                            "Save"
                          )}
                        </button>
                        <button
                          type="button"
                          className="btn_cancel"
                          onClick={() => {
                            this.setState({
                              isAddLang: false,
                              isEditLang: false,
                              selectedLanguages: [
                                {
                                  selectedLang: "",
                                  selectedLevel: "",
                                },
                              ],
                            });
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  )}
                </div>
              </div>

              <div className="li_vie">
                <div className="lang_block">
                  <h4 className="title_lgng">
                    Mawthooq{" "}
                    {!this.state.mawthooq &&
                      !this.state.mawthooq_id &&
                      !this.state.mawthooq_image_url && (
                        <button
                          type="button"
                          className={
                            isAddMawthooq ? "btn_addimg active" : "btn_addimg"
                          }
                          onClick={this.openMawthooq}
                        ></button>
                      )}
                  </h4>
                  {!isAddMawthooq ? (
                    <ul className="list_vie">
                      {this.state.mawthooq ||
                        this.state.mawthooq_id ||
                        this.state.mawthooq_image_url ? (
                        <div className="payment-card">
                          {mawthooqType === 0 && (
                            <a
                              target="_blank"
                              href={`${decodePath.mawthooq}${this.state.mawthooq_image_url}`}
                            >
                              <button
                                type="button"
                                className="btn-show-icon"
                              ></button>
                            </a>
                          )}
                          <div>
                            {mawthooqType === 1 && <h6>{mawthooq_id}</h6>}
                            <p>{moment(issue_date).format("YYYY-MM-DD")}</p>
                            <p>{moment(expiry_date).format("YYYY-MM-DD")}</p>
                          </div>
                          <div
                            className="bank-action"
                            style={{ display: "flex", gap: "5px" }}
                          >
                            <Button
                              type="button"
                              variant="primary"
                              className="D-icon edit-social-media"
                              onClick={() => this.deleteMawthooq()}
                            >
                              <img src={DeleteIcon} alt={""} />
                            </Button>
                            <Button
                              type="button"
                              variant="primary"
                              className="D-icon edit-social-media"
                              onClick={() => this.openMawthooq()}
                            >
                              <img src={EditIcon} alt={""} />
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <li>No Record Found</li>
                      )}
                    </ul>
                  ) : (
                    <form
                      method="POST"
                      action="#javascript:void(0)"
                      onSubmit={this.mawthooqSubmit}
                    >
                      <div className="form_group3">
                        <div className="colum_2_wrap">
                          <input
                            type="radio"
                            name="release"
                            id="file"
                            checked={mawthooqType === 0}
                            onClick={(e) => this.radioHandler(0)}
                          />
                          <label htmlFor="file">Upload Mawthooq Docs</label>
                        </div>
                        <div className="colum_2_wrap">
                          <input
                            type="radio"
                            name="release"
                            id="number"
                            checked={mawthooqType === 1}
                            onClick={(e) => this.radioHandler(1)}
                          />
                          <label htmlFor="number">Enter Mawthooq Number</label>
                        </div>
                      </div>
                      {mawthooqType === 0 ? (
                        <div className="div_2">
                          {mawthooqType === 0 &&
                            this.state.mawthooq_image_url && (
                              <a
                                target="_blank"
                                href={`${decodePath.mawthooq}${this.state.mawthooq_image_url}`}
                              >
                                <button
                                  type="button"
                                  className="btn-show-icon"
                                ></button>
                              </a>
                            )}
                          <label className="label_form">Mawthooq File</label>
                          <input
                            type="file"
                            onChange={this.handleChangeMawthooq}
                          />
                          {mawthooqError !== "" ? (
                            <p className="error_text">{mawthooqError}</p>
                          ) : null}
                        </div>
                      ) : (
                        <div className="div_2">
                          <label className="label_form">Mawthooq Number</label>
                          <input
                            type="text"
                            className={"form_control"}
                            value={mawthooq_id}
                            name="mawthooq_id"
                            placeholder="Mawthooq Number"
                            onChange={this.inputHandler}
                            autoComplete="off"
                          />
                          {mawthooqError !== "" ? (
                            <p className="error_text">{mawthooqError}</p>
                          ) : null}
                        </div>
                      )}
                      <div className="div_2 form-group flex column-gap-15 black-dp">
                        <div>
                          <label className="label_form">Issue Date</label>
                          <DatePicker
                            placeholderText="Select Start Date"
                            selected={issue_date}
                            onChange={(date) => this.setIssueDate(date)}
                            dateFormat="yyyy-MM-dd"
                          />
                        </div>
                        <div>
                          <label className="label_form">Expiry Date</label>
                          <DatePicker
                            placeholderText="Select End Date"
                            selected={expiry_date}
                            onChange={(date) => this.setExpiryDate(date)}
                            minDate={issue_date}
                            dateFormat="yyyy-MM-dd"
                          />
                        </div>
                      </div>

                      <div className="form_group">
                        <button
                          type="submit"
                          className="btn_save"
                          disabled={this.state.isLangLoader}
                        >
                          {this.state.isLangLoader ? (
                            <img
                              src={LoaderImg}
                              className="small-loader"
                              alt="loader"
                            />
                          ) : (
                            "Save"
                          )}
                        </button>
                        <button
                          type="button"
                          className="btn_cancel"
                          onClick={this.openMawthooq}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  )}
                </div>
              </div>
              <div className="li_vie">
                <div className="lang_block">
                  <h4 className="title_lgng">
                    Payment{" "}
                    <button
                      type="button"
                      className={
                        isAddPayment || isEditPayment
                          ? "btn_addimg active"
                          : "btn_addimg"
                      }
                      onClick={this.openPayment}
                    ></button>
                  </h4>
                  {!isAddPayment && !isEditPayment ? (
                    <ul className="list_vie">
                      {this.state.connectedBanksList.length > 0 ? (
                        this.state.connectedBanksList.map((data, i) =>
                          this.renderBanks(data, i)
                        )
                      ) : (
                        <li>No Record Found</li>
                      )}
                    </ul>
                  ) : (
                    <form
                      method="POST"
                      action="#javascript:void(0)"
                      onSubmit={this.bankSubmit}
                    >
                      <div className="div_2 mb-2">
                        <label className="label_form">Bank Name</label>
                        <Select
                          value={this.state.selectedBank}
                          placeholder={"Select Your Bank"}
                          isDisabled={this.state.isELoader}
                          isLoading={this.state.isELoader}
                          onChange={this.handleBankChange}
                          options={this.state.banksList}
                          autoComplete="off"
                        />
                        {formBankError.selectedBank !== "" ? (
                          <p className="error_text">
                            {formBankError.selectedBank}
                          </p>
                        ) : null}
                      </div>
                      <div className="div_2 mb-2">
                        <label className="label_form">Beneficiary Name</label>
                        <input
                          type="text"
                          className={"form_control"}
                          value={this.state.name}
                          name="name"
                          placeholder="Beneficiary Name"
                          onChange={this.inputBankHandler}
                          autoComplete="off"
                        />
                        {formBankError.name !== "" ? (
                          <p className="error_text">{formBankError.name}</p>
                        ) : null}
                      </div>
                      <div className="div_2 mb-2">
                        <label className="label_form">IBAN</label>
                        <input
                          type="text"
                          className={"form_control"}
                          value={this.state.ibanNumber}
                          name="ibanNumber"
                          placeholder="IBAN Number"
                          maxLength={22}
                          onChange={this.inputBankHandler}
                          autoComplete="off"
                        />
                        {formBankError.ibanNumber !== "" ? (
                          <p className="error_text">
                            {formBankError.ibanNumber}
                          </p>
                        ) : null}
                      </div>
                      <div className="div_2 mb-2">
                        <label className="label_form">
                          Bank Certificate Attachment
                        </label>
                        {bankAttachmentURL && (
                          <a
                            target="_blank"
                            href={`${decodePath.bank_certificate}${bankAttachmentURL}`}
                          >
                            <button
                              type="button"
                              className="btn-show-icon"
                            ></button>
                          </a>
                        )}
                        <input
                          type="file"
                          onChange={this.handleChangeBankCerti}
                        />
                      </div>
                      <div className="checkbox-group">
                        <input
                          type="checkbox"
                          value={isPrimary}
                          name="isPrimary"
                          id="isPrimaryBank"
                          defaultChecked={isPrimary}
                          onChange={(e) => this.setBankAsPrimary(e)}
                          className="styled-checkbox"
                        />
                        <label htmlFor="isPrimaryBank">Set as primary</label>
                      </div>
                      <div className="form_group">
                        <button
                          type="submit"
                          className="btn_save"
                          disabled={this.state.isLangLoader}
                        >
                          {this.state.isLangLoader ? (
                            <img
                              src={LoaderImg}
                              className="small-loader"
                              alt="loader"
                            />
                          ) : (
                            "Save"
                          )}
                        </button>
                        <button
                          type="button"
                          className="btn_cancel"
                          onClick={() => {
                            this.setState({
                              isAddPayment: false,
                              isEditPayment: false,
                              isPrimary: false,
                              ibanNumber: "",
                              name: "",
                              selectedBank: "",
                              bankAttachment: "",
                            });
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  )}
                </div>
              </div>


              <div className="li_vie">
                <div className="lang_block">
                  <h4 className="title_lgng">
                    Skills{" "}
                    <button
                      type="button"
                      className={
                        this.state.isSkillsO
                          ? "btn_addimg active"
                          : "btn_addimg"
                      }
                      onClick={this.skillsEditBlock}
                    ></button>
                  </h4>
                  {!this.state.isSkillsO ? (
                    <ul className="list_vie">
                      {userDetails ? (
                        Array.isArray(this.state.agentSkills) &&
                          this.state.agentSkills.length > 0 ? (
                          this.state.agentSkills.map((data, i) =>
                            this.renderSkills(data, i)
                          )
                        ) : (
                          <li>No Record Found</li>
                        )
                      ) : (
                        <li>No Record Found</li>
                      )}
                    </ul>
                  ) : (
                    <form
                      method="POST"
                      action="#javascript:void(0)"
                      onSubmit={this.skillsSubmit}
                    >
                      <div className="form_group">
                        <div className="div_2">
                          <Select
                            className="skill-selec"
                            onMenuScrollToBottom={this.fetchMoreData}
                            value={this.state.selectSkills}
                            placeholder={"Select Skills"}
                            onChange={this.handleSkill}
                            options={this.state.allSkill}
                            isDisabled={this.state.isSkillAPILoader}
                            isLoading={this.state.isSkillAPILoader}
                          />
                        </div>
                        <div className="div_2">
                          <Select
                            value={this.state.sSkillsLevel}
                            placeholder={"Skills Level"}
                            onChange={this.handleSLevel}
                            options={optionsLevel}
                          />
                        </div>
                      </div>
                      <div className="form_group">
                        <button type="submit" className="btn_save">
                          Save
                        </button>
                        <button
                          type="button"
                          className="btn_cancel"
                          onClick={this.skillsEditBlock}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  )}
                </div>
              </div>
              <div className="li_vie">
                <div className="lang_block">
                  <form action="#" method="POST" onSubmit={this.websiteSubmit}>
                    <h4 className="title_lgng">
                      Website{" "}
                      <button
                        type="button"
                        className={
                          this.state.isAddWebsite
                            ? "btn_addimg active"
                            : "btn_addimg"
                        }
                        onClick={() => this.openWebsite()}
                      ></button>
                    </h4>
                    <div className="hed_input">
                      <input
                        type="text"
                        className="form_control"
                        value={this.state.website}
                        name="website"
                        onChange={this.websiteInput}
                        disabled={!this.state.isAddWebsite}
                      />
                    </div>
                    {this.state.isAddWebsite ? (
                      <div className="form_group mar_t15">
                        <button type="submit" className="btn_save">
                          Save
                        </button>
                        <button
                          type="button"
                          className="btn_cancel"
                          onClick={() => this.openWebsite()}
                        >
                          Cancel
                        </button>
                      </div>
                    ) : null}
                  </form>
                </div>
              </div>
              <div className="li_vie">
                <div className="lang_block">
                  <h4 className="title_lgng">
                    Social Media{" "}
                    <button
                      type="button"
                      className={
                        isAddSocialMedia ? "btn_addimg active" : "btn_addimg"
                      }
                      onClick={this.openSocialMedia}
                    ></button>
                  </h4>
                  {!isAddSocialMedia && !isEditSocialMedia ? (
                    <ul className="list_vie social-card-main d-flex justify-content-center">
                      {userDetails ? (
                        Array.isArray(this.state.connectedSocialMediaList) &&
                          this.state.connectedSocialMediaList.length > 0 ? (
                          this.state.connectedSocialMediaList.map((data, i) =>
                            this.renderSocialMedias(data, i)
                          )
                        ) : (
                          <li>No Record Found</li>
                        )
                      ) : (
                        <li>No Record Found</li>
                      )}
                    </ul>
                  ) : (
                    <form
                      method="POST"
                      action="#javascript:void(0)"
                      onSubmit={this.socialMediaSubmit}
                    >
                      {socialMedias.map((item, index) => (
                        <div className="mb-3 flex gap-3">
                          <div className="w-20">
                            <label className="label_form_social">
                              social media type
                            </label>
                            <Select
                              className="select-custom skill-selec"
                              onMenuScrollToBottom={this.fetchMoreData}
                              value={item.selectedSocialMediaType}
                              placeholder={"Social media type"}
                              onChange={(selected) =>
                                this.handleSocialMediaType(index, selected)
                              }
                              options={this.state.socialMediaTypeList}
                            />
                          </div>
                          <div className="w-20">
                            <label className="label_form_social">
                              Platforms type
                            </label>
                            <Select
                              className="select-custom"
                              value={item.selectedSocialMediaplatformsType}
                              placeholder={"Social media platform"}
                              onChange={(selected) =>
                                this.handleSocialMediaPlatformType(
                                  index,
                                  selected
                                )
                              }
                              options={item.socialMediaplatformsTypeList}
                            />
                          </div>
                          <div className="w-20">
                            <label className="label_form_social">
                              Username
                            </label>
                            <input
                              type="text"
                              className={"form_control px-2"}
                              value={item.socialMediaUserName}
                              name="username"
                              placeholder="User name"
                              onChange={(e) =>
                                this.handleInputUsernameChange(index, e)
                              }
                              autoComplete="off"
                            />
                          </div>
                          <div className="w-9">
                            <label className="label_form_social">
                              Followers
                            </label>
                            <input
                              type="text"
                              className={"form_control px-2"}
                              value={item.socialMediaFollowers}
                              name="followers"
                              placeholder="Number of followers"
                              onChange={(e) =>
                                this.handleInputFollowersChange(index, e)
                              }
                              autoComplete="off"
                            />
                          </div>
                          <div className="w-9">
                            <label className="label_form_social">Prefix</label>
                            <Select
                              className="select-custom skill-selec"
                              value={item.socialMediaprifix}
                              name="prifix"
                              onChange={(selected) =>
                                this.handlePrifix(index, selected)
                              }
                              options={[
                                { value: "K", label: "K" },
                                { value: "M", label: "M" },
                              ]}
                            />
                          </div>
                          {!isEditSocialMedia && (
                            <Button
                              type="button"
                              variant="danger"
                              className="D-icon D-small-icon"
                              onClick={() =>
                                this.handleDeleteMoreSocialMedia(index)
                              }
                            >
                              <img src={DeleteIcon} alt={""} />
                            </Button>
                          )}
                        </div>
                      ))}
                      {socialMediaError !== "" ? (
                        <p className="error_text">{socialMediaError}</p>
                      ) : null}
                      {!isEditSocialMedia && (
                        <div className="mb-2">
                          <Button
                            type="button"
                            onClick={this.handleAddMoreSocialMedia}
                          >
                            Add
                          </Button>
                        </div>
                      )}
                      <div className="form_group">
                        <button type="submit" className="btn_save">
                          Save
                        </button>
                        <button
                          type="button"
                          className="btn_cancel"
                          onClick={() => {
                            this.setState({ isAddSocialMedia: false });
                            this.setState({ isEditSocialMedia: false });
                            this.setState({ socialMedias: [{}] });
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  )}
                </div>
              </div>
              <div className="li_vie">
                <div className="lang_block">
                  <form action="#" method="POST" onSubmit={this.HeadlineSubmit}>
                    <h4 className="title_lgng">
                      Professional Headline{" "}
                      <button
                        type="button"
                        className={
                          this.state.isHeadline
                            ? "btn_addimg active"
                            : "btn_addimg"
                        }
                        onClick={() => this.toggleEditButton("headline")}
                      ></button>
                    </h4>
                    <div className="hed_input">
                      <input
                        type="text"
                        className="form_control"
                        value={this.state.headline}
                        onChange={this.headlineInput}
                        disabled={!this.state.isHeadline}
                      />
                    </div>
                    {this.state.isHeadline ? (
                      <div className="form_group mar_t15">
                        <button type="submit" className="btn_save">
                          Save
                        </button>
                        <button
                          type="button"
                          className="btn_cancel"
                          onClick={() => this.toggleEditButton("headline")}
                        >
                          Cancel
                        </button>
                      </div>
                    ) : null}
                  </form>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="li_vie">
                <div className="lang_block">
                  <h4 className="title_lgng">
                    CR Number{" "}
                    {!cr_number && (
                      <button
                        type="button"
                        className={isAddCR ? "btn_addimg active" : "btn_addimg"}
                        onClick={this.openCRDetails}
                      ></button>
                    )}
                  </h4>
                  {!isAddCR ? (
                    <ul className="list_vie">
                      {userDetails ? (
                        cr_number ? (
                          <div className="payment-card">
                            <a
                              target="_blank"
                              href={`${decodePath.commercial_registration}${cr_url}`}
                            >
                              <button
                                type="button"
                                className="btn-show-icon"
                              ></button>
                            </a>
                            <div>
                              <h6>CR number: {cr_number}</h6>
                            </div>
                            <div className="bank-action">
                              <Button
                                type="button"
                                variant="danger"
                                className="D-icon delete-social-media"
                                onClick={this.deleteCRDetails}
                              >
                                <img src={DeleteIcon} alt={""} />
                              </Button>
                              <Button
                                type="button"
                                variant="primary"
                                className="D-icon edit-social-media"
                                onClick={this.openCRDetails}
                              >
                                <img src={EditIcon} alt={""} />
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <li>No Record Found</li>
                        )
                      ) : (
                        <li>No Record Found</li>
                      )}
                    </ul>
                  ) : (
                    <form
                      method="POST"
                      action="#javascript:void(0)"
                      onSubmit={this.crSubmit}
                    >
                      <div className="form_group">
                        <div className="div_2">
                          <label>Commercial Registration Number</label>
                          <input
                            type="text"
                            name="cr_number"
                            className="form_control2"
                            value={cr_number}
                            onChange={this.inputCRHandler}
                            placeholder="Enter commercial registration number"
                            autoComplete="off"
                          />
                          {formCrError.cr_number !== "" ? (
                            <p className="error_text">
                              {formCrError.cr_number}
                            </p>
                          ) : null}
                        </div>
                        <div className="div_2 d-flex align-items-center justify-content-between gap-2">
                          <label className="mb-0">
                            Commercial Registration Document
                          </label>
                          {cr_url && (
                            <a
                              target="_blank"
                              className="d-block"
                              href={`${decodePath.commercial_registration}${cr_url}`}
                            >
                              <button
                                type="button"
                                className="btn-show-icon"
                              ></button>
                            </a>
                          )}
                          <input type="file" onChange={this.handleChangeCR} />
                          {formCrError.commercial_certi !== "" ? (
                            <p className="error_text">
                              {formCrError.commercial_certi}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="form_group">
                        <button type="submit" className="btn_save">
                          Save
                        </button>
                        <button
                          type="button"
                          className="btn_cancel"
                          onClick={this.openCRDetails}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  )}
                </div>
              </div>
              <div className="li_vie">
                <div className="lang_block">
                  <h4 className="title_lgng">
                    VAT Registration{" "}
                    {!vatNumber && (
                      <button
                        type="button"
                        className={
                          isAddVatReg ? "btn_addimg active" : "btn_addimg"
                        }
                        onClick={this.openVatReg}
                      ></button>
                    )}
                  </h4>
                  {!isAddVatReg ? (
                    <ul className="list_vie">
                      {userDetails ? (
                        vatNumber ? (
                          <div className="payment-card">
                            <a
                              target="_blank"
                              href={`${decodePath.vat}${vat_url}`}
                            >
                              <button
                                type="button"
                                className="btn-show-icon"
                              ></button>
                            </a>
                            <div>
                              <h6>VAT number: {vatNumber}</h6>
                            </div>
                            <div className="bank-action">
                              <Button
                                type="button"
                                variant="danger"
                                className="D-icon delete-social-media"
                                onClick={this.deleteVatDetails}
                              >
                                <img src={DeleteIcon} alt={""} />
                              </Button>
                              <Button
                                type="button"
                                variant="primary"
                                className="D-icon edit-social-media"
                                onClick={this.openVatReg}
                              >
                                <img src={EditIcon} alt={""} />
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <li>No Record Found</li>
                        )
                      ) : (
                        <li>No Record Found</li>
                      )}
                    </ul>
                  ) : (
                    <form
                      method="POST"
                      action="#javascript:void(0)"
                      onSubmit={this.vatSubmit}
                    >
                      <div className="form_group3">
                        <div className="colum_2_wrap">
                          <label>VAT Registration Query</label>
                          <input
                            type="radio"
                            name="release"
                            checked={vatType === 1}
                            onClick={(e) => this.radioHandlerVat(1)}
                          />{" "}
                          Yes
                          <input
                            type="radio"
                            name="release"
                            checked={vatType === 2}
                            onClick={(e) => this.radioHandlerVat(2)}
                          />{" "}
                          No
                        </div>
                      </div>
                      {vatType === 1 && (
                        <div className="form_group3">
                          <div className="colum_2_wrap">
                            <label>VAT Number</label>
                            <input
                              type="text"
                              name="vatNumber"
                              className="form_control2"
                              value={vatNumber}
                              onChange={this.inputVatHandler}
                              placeholder="Enter vat number"
                              autoComplete="off"
                            />
                            {formVatError.vatNumber !== "" ? (
                              <p className="error_text">
                                {formVatError.vatNumber}
                              </p>
                            ) : null}
                          </div>
                          <div className="colum_2_wrap">
                            <label>VAT Certificate Attachment</label>
                            <div className="d-flex align-items-center justify-content-between">
                              {vat_url && (
                                <a
                                  target="_blank"
                                  href={`${decodePath.vat}${vat_url}`}
                                >
                                  <button
                                    type="button"
                                    className="btn-show-icon"
                                  ></button>
                                </a>
                              )}
                              <input
                                type="file"
                                onChange={this.handleChangeVat}
                              />
                              {formVatError.vatAttachment !== "" ? (
                                <p className="error_text">
                                  {formVatError.vatAttachment}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="form_group">
                        <button type="submit" className="btn_save">
                          Save
                        </button>
                        <button
                          type="button"
                          className="btn_cancel"
                          onClick={this.openVatReg}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </>
          )}


          {type === "agents" ? (
            <>
              <div className="li_vie">
                <div className="lang_block">
                  <h4 className="title_lgng">
                    Employment History{" "}
                    <button type="button" className="btn_addimg"></button>
                  </h4>
                  {!this.state.isEHistory ? (
                    <ul className="list_vie">
                      {userDetails ? (
                        Array.isArray(userDetails.experiences) &&
                          userDetails.experiences.length > 0 ? (
                          userDetails.experiences.map((data, i) =>
                            this.expRender(data, i)
                          )
                        ) : (
                          <li>No Record Found</li>
                        )
                      ) : (
                        <li>No Record Found</li>
                      )}
                    </ul>
                  ) : (
                    <form
                      method="POST"
                      action="#javascript:void(0)"
                      onSubmit={this.HistorySubmit}
                    >
                      <div className="form_group">
                        <input
                          type="text"
                          value={this.state.selectExp}
                          placeholder={"Select Experience"}
                          onChange={this.handleExp}
                          options={optionsExp}
                        />
                      </div>
                      <div className="form_group">
                        <div className="div_2">
                          <Select
                            value={this.state.selectLang}
                            placeholder={"Select Language"}
                            onChange={this.handleChange}
                            options={options}
                          />
                        </div>
                        <div className="div_2">
                          <Select
                            value={this.state.langLevel}
                            placeholder={"Language Level"}
                            onChange={this.handleChange2}
                            options={optionsLevel}
                          />
                        </div>
                      </div>
                      <div className="form_group">
                        <button type="submit" className="btn_save">
                          Save
                        </button>
                        <button
                          type="button"
                          className="btn_cancel"
                          onClick={this.openLanguage}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  )}
                </div>
              </div>
              <div className="li_vie">
                <div className="lang_block">
                  <h4 className="title_lgng">
                    Education{" "}
                    <button
                      type="button"
                      className={
                        this.state.isEducation
                          ? "btn_addimg active"
                          : "btn_addimg"
                      }
                      onClick={this.openEduc}
                    ></button>
                  </h4>
                  {!this.state.isEducation ? (
                    <ul className="list_vie">
                      {userDetails ? (
                        Array.isArray(userDetails.educations) &&
                          userDetails.educations.length > 0 ? (
                          userDetails.educations.map((data, i) =>
                            this.edcucaRender(data, i)
                          )
                        ) : (
                          <li>No Record Found</li>
                        )
                      ) : (
                        <li>No Record Found</li>
                      )}
                    </ul>
                  ) : (
                    <form
                      method="POST"
                      action="/"
                      onSubmit={this.EducationSubmit}
                    >
                      <div className="form_group">
                        <div className="div_2">
                          <input
                            type="text"
                            className={
                              eduFormError.degree !== ""
                                ? "form_control error_border"
                                : "form_control"
                            }
                            name="degree"
                            value={degree}
                            onChange={this.inputEduHandler}
                            placeholder="Enter Degree"
                            disabled={isEdit}
                            autoComplete="off"
                          />
                          {eduFormError.degree !== "" ? (
                            <p className="error_text">{eduFormError.degree}</p>
                          ) : null}
                        </div>
                        <div className="div_2">
                          <input
                            type="text"
                            className={
                              eduFormError.university !== ""
                                ? "form_control error_border"
                                : "form_control"
                            }
                            name="university"
                            value={university}
                            onChange={this.inputEduHandler}
                            placeholder="Enter university"
                            disabled={isEdit}
                            autoComplete="off"
                          />
                          {eduFormError.university !== "" ? (
                            <p className="error_text">
                              {eduFormError.university}
                            </p>
                          ) : null}
                        </div>
                        <div className="div_2">
                          <Select
                            value={this.state.eduLevel}
                            placeholder={"Select Education"}
                            onChange={this.handleChangeEdu}
                            options={optionsEdu}
                          />
                        </div>
                        <div className="div_2 flex column-gap-15 black-dp">
                          <DatePicker
                            placeholderText="Select Start Date"
                            selected={startDate}
                            minDate={new Date("2018-01-01")}
                            maxDate={new Date()}
                            onChange={(date) => this.setStartDate(date)}
                            dateFormat="yyyy-MM-dd"
                          />
                          <DatePicker
                            placeholderText="Select End Date"
                            selected={endDate}
                            onChange={(date) => this.setEndDate(date)}
                            minDate={startDate}
                            maxDate={new Date()}
                            endDate={endDate}
                            dateFormat="yyyy-MM-dd"
                          />
                        </div>
                      </div>
                      <div className="form_group">
                        <button
                          type="submit"
                          className="btn_save"
                          disabled={disabledEdu || this.state.isLangLoader}
                        >
                          {this.state.isLangLoader ? (
                            <img
                              src={LoaderImg}
                              className="small-loader"
                              alt="loader"
                            />
                          ) : (
                            "Save"
                          )}
                        </button>
                        <button
                          type="button"
                          className="btn_cancel"
                          onClick={this.openEduc}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  )}
                </div>
              </div>
              <div className="li_vie">
                <div className="lang_block">
                  <h4 className="title_lgng">Resume Only visible to you</h4>
                  <ul className="list_vie">
                    {userDetails ? (
                      userDetails.resumes !== null ? (
                        <li>
                          {userDetails.resumes}{" "}
                          <img src={Resume_icon} alt="resume" />
                          {resume}
                        </li>
                      ) : (
                        <li>No Record Found</li>
                      )
                    ) : (
                      <li>No Record Found</li>
                    )}
                  </ul>
                </div>
              </div>
              <div className="li_vie">
                <div className="lang_block">
                  <h4 className="title_lgng">Type of work</h4>

                  {userDetails !== null ? (
                    <ul className="list_vie no-hover">
                      <li>
                        Office-base
                        <span>
                          <button
                            type="button"
                            className={
                              this.state.officeBaseVal === 0
                                ? "btn_radiuo no active"
                                : "btn_radiuo no"
                            }
                            onClick={() => this.officeBaseClik(0)}
                          >
                            No
                          </button>
                          <button
                            type="button"
                            className={
                              this.state.officeBaseVal === 1
                                ? "btn_radiuo yes active"
                                : "btn_radiuo yes"
                            }
                            onClick={() => this.officeBaseClik(1)}
                          >
                            Yes
                          </button>
                        </span>
                      </li>
                      <li>
                        Home-base
                        <span>
                          <button
                            type="button"
                            className={
                              this.state.homeBaseVal === 0
                                ? "btn_radiuo no active"
                                : "btn_radiuo no"
                            }
                            onClick={() => this.homeBaseClik(0)}
                          >
                            No
                          </button>
                          <button
                            type="button"
                            className={
                              this.state.homeBaseVal === 1
                                ? "btn_radiuo yes active"
                                : "btn_radiuo yes"
                            }
                            onClick={() => this.homeBaseClik(1)}
                          >
                            Yes
                          </button>
                        </span>
                      </li>
                    </ul>
                  ) : (
                    <li>No Record Found</li>
                  )}
                </div>
              </div>
              <div className="li_vie">
                <div className="lang_block">
                  <h4 className="title_lgng">Availability</h4>
                  <ul className="list_vie no-hover">
                    <li>
                      Fixed-price
                      <span>
                        <button
                          type="button"
                          className={
                            this.state.fixedPrice === 0
                              ? "btn_radiuo no active"
                              : "btn_radiuo no"
                          }
                          onClick={() => this.fixedPricedCl(0)}
                        >
                          No
                        </button>
                        <button
                          type="button"
                          className={
                            this.state.fixedPrice === 1
                              ? "btn_radiuo yes active"
                              : "btn_radiuo yes"
                          }
                          onClick={() => this.fixedPricedCl(1)}
                        >
                          Yes
                        </button>
                      </span>
                    </li>
                    <li>
                      Hourly ($/hour)
                      <span>
                        <button
                          type="button"
                          className={
                            this.state.hourPrice === 0
                              ? "btn_radiuo no active"
                              : "btn_radiuo no"
                          }
                          onClick={() => this.hourPricedCl(0)}
                        >
                          No
                        </button>
                        <button
                          type="button"
                          className={
                            this.state.hourPrice === 1
                              ? "btn_radiuo yes active"
                              : "btn_radiuo yes"
                          }
                          onClick={() => this.hourPricedCl(1)}
                        >
                          Yes
                        </button>
                      </span>
                    </li>
                    <li>
                      Part-time (20hrs/wk)
                      <span>
                        <button
                          type="button"
                          className={
                            this.state.partTime === 0
                              ? "btn_radiuo no active"
                              : "btn_radiuo no"
                          }
                          onClick={() => this.partTimeCl(0)}
                        >
                          No
                        </button>
                        <button
                          type="button"
                          className={
                            this.state.partTime === 1
                              ? "btn_radiuo yes active"
                              : "btn_radiuo yes"
                          }
                          onClick={() => this.partTimeCl(1)}
                        >
                          Yes
                        </button>
                      </span>
                    </li>
                    <li>
                      Full-time (40hrs/wk)
                      <span>
                        <button
                          type="button"
                          className={
                            this.state.fullTime === 0
                              ? "btn_radiuo no active"
                              : "btn_radiuo no"
                          }
                          onClick={() => this.fullTimeCl(0)}
                        >
                          No
                        </button>
                        <button
                          type="button"
                          className={
                            this.state.fullTime === 1
                              ? "btn_radiuo yes active"
                              : "btn_radiuo yes"
                          }
                          onClick={() => this.fullTimeCl(1)}
                        >
                          Yes
                        </button>
                      </span>
                    </li>
                    <li>
                      Free
                      <span>
                        <button
                          type="button"
                          className={
                            this.state.freeA === 0
                              ? "btn_radiuo no active"
                              : "btn_radiuo no"
                          }
                          onClick={() => this.freeCl(0)}
                        >
                          No
                        </button>
                        <button
                          type="button"
                          className={
                            this.state.freeA === 1
                              ? "btn_radiuo yes active"
                              : "btn_radiuo yes"
                          }
                          onClick={() => this.freeCl(1)}
                        >
                          Yes
                        </button>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </>
          ) : null}
        </div>
        <DeleteModal data={DeleteModalData} />

        <ConfirmModal modalData={confirmModalData} />
        <ConfirmModal modalData={confirmModalDataClient} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userDetails: state.users.userDetails,
});
const mapDispatchToProps = (dispatch) => ({
  addDetails: (data) =>
    dispatch({ type: ACTION_TYPES.USER_DETAILS_API, payload: data }),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfileTab)
);
