import Select from "react-select";

const CategoryTagJSX = ({ placeholder,LoaderImg, isLangLoader, isAddCategory, addCategoryList, delAgentCatgeory, categorySubmit, selectedCategory, handleCategoryChange, categories, openCategory }) => {
   
    return (
      <>
        {!isAddCategory ? (
          <ul className="list_vie">
            {addCategoryList?.length ? (
              <>
                {addCategoryList?.map((data) => {
                  return (
                    <li style={{ borderBottom: "none" }}>
                      {data.name}{" "}
                      <span>
                        <span className="hover_block">
                          <button
                            type="button"
                            className="btn_del"
                            onClick={() => delAgentCatgeory(data)}
                          ></button>
                        </span>
                      </span>
                    </li>
                  );
                })}
              </>
            ) : (
              <li>No Record Found</li>
            )}
          </ul>
        ) : (
          <form
            method="POST"
            action="#javascript:void(0)"
            onSubmit={categorySubmit}
          >
            <div className="div_2 mb-2">
              <label className="label_form">{placeholder}</label>
              <Select
                isMulti={true}
                value={selectedCategory}
                onChange={(e) => {
                  handleCategoryChange(e);
                }}
                options={categories}
                placeholder={placeholder}
                closeMenuOnSelect={false}
              />
            </div>

            <div className="form_group">
              <button
              disabled={isLangLoader || selectedCategory ? false  : true}
                type="submit"
                className="btn_save"
                // disabled={isLangLoader}
              >
                {isLangLoader ? (
                  <img src={LoaderImg} className="small-loader" alt="loader" />
                ) : (
                  "Save"
                )}
              </button>
              <button
                type="button"
                className="btn_cancel"
                onClick={() => {
                  openCategory();
                }}
              >
                Cancel
              </button>
            </div>
          </form>
        )}
      </>
    );
}

export default CategoryTagJSX;