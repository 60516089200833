import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as ACTIVE_TYPES from "../../redux/actions/types";
import Dropdown from "react-bootstrap/Dropdown";
import Sidebar from "../Sidebar";
import { jwtDecode } from "../../helpers/jwt_helper";
import UserImage from "../../assets/images/user_img.svg";
import NotificationsComp from "./notifications";

import "./index.css";

class Header extends Component {
  logoutClick = (e) => {
    e.preventDefault();
    localStorage.removeItem("token");
    const { history } = this.props;
    history.push("/");
    // window.location.href ="http://adminweb.24task.com/";
  };

  render() {
    const { sidebarClick } = this.props;
    const token = localStorage.getItem("token");
    const user = jwtDecode(token);
    // console.log(user);
    return (
      <Fragment>
        <nav className="nav_bg">
          <div className="container-fluid">
            <div className="nav_wrap">
              <div className="logo_block">
                <Link to="/allJobs">Influence Bird</Link>
                <button
                  type="button"
                  className="btn-toggle"
                  onClick={() => sidebarClick()}
                >
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
              </div>
              <div className="profile_wrap">
                <NotificationsComp />
                <Dropdown className="custom_drop">
                  <Dropdown.Toggle id="profile_dropdown">
                    <img
                      src={
                        user.profile_pic !== null ? user.profile_pic : UserImage
                      }
                      alt={user.username}
                    />{" "}
                    {user.username}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="/profile">Profile</Dropdown.Item>
                    <Dropdown.Item
                      href="#/javascript:void(0)"
                      onClick={this.logoutClick}
                    >
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </nav>
        <Sidebar />
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  sidebarClick: () => dispatch({ type: ACTIVE_TYPES.TOGGLE_SIDEBAR_ACTIVE }),
});

export default withRouter(connect(null, mapDispatchToProps)(Header));
