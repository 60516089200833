import { useEffect, useState } from "react";
import { getAllCompany } from "../Companies/CompaniApiHelper";
import RequestedMawthooqDataTable from "./RequestedMawthooqDataTable";
import RequestModal from "./RequestModal";
import Select from "react-select";
import "./index.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const RequestMawthooqWrapper = () => {
  const [dataList, setDataList] = useState();
  const [isLoader, setIsLoader] = useState(false);
  const [requestState, setRequestState] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [isRequestModal, setIsRequestModal] = useState(false);
  const { push } = useHistory();
  const [requestInfo, setRequestInfo] = useState({
    status: "",
    id: null,
  });
  const closeModal = () => {
    setIsRequestModal(false);
    getPage(pageNumber);
  };
  const getPage = async (page) => {
    setIsLoader(true);
    const res = await getAllCompany(
      `verification/mawthooq-requests?status=${requestState}&page_no=${page}`,
      "GET"
    );
    if (res.data.status === true) {
      setIsLoader(false);
      setDataList(res.data.data);
      setTotalRecord(res.data.pagination.totalItems);
    }
  };
  const headerSpan = (column, colIndex) => {
    switch (column.dataField) {
      default:
        return (
          <span
            className={
              column.sort
                ? "header_span sort text-center"
                : "header_span text-center"
            }
          >
            {column.text}
          </span>
        );
    }
  };
  const statusField = (cell, row) => {
    return (
      <button
        type="button"
        className={"btn-status orange margin_0auto text-center"}
        disabled={cell !== "pending"}
      >
        {cell}
      </button>
    );
  };
  const ActionFiled = (cell, row) => {
    return (
      <>
        <div className="d-flex justify-content-center align-items-center">
          <button
            disabled={row?.status !== "pending"}
            type="button"
            className={`btn-status blue mr-2`}
            onClick={() => {
              setRequestInfo({
                status: "approved",
                id: row?.id,
              });
              setIsRequestModal(true);
            }}
          >
            {"Approve"}
          </button>
          <button
            disabled={row?.status !== "pending"}
            type="button"
            className={"btn-status red ml-2"}
            onClick={() => {
              setRequestInfo({
                status: "rejected",
                id: row?.id,
              });
              setIsRequestModal(true);
            }}
          >
            {"Reject"}
          </button>
        </div>
      </>
    );
  };
  const columns = [
    {
      dataField: "agent_first_name",
      text: "Star name",
      sort: true,
      headerFormatter: headerSpan,
      formatter: (cell, row) => {
        return (
          <div
            onClick={() => push(`usersDetails/${row.profile_id}?type=agents`)}
            className="text-center"
            style={{ cursor: "pointer" }}
          >
            {cell}
          </div>
        );
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      headerFormatter: headerSpan,
      formatter: statusField,
    },
    {
      dataField: "mawthooq_id",
      text: "Mawthooq ID",
      sort: true,
      headerFormatter: headerSpan,
      formatter: (cell, row) => {
        return <div className="text-center">{cell}</div>;
      },
    },
    {
      dataField: "created_at",
      text: "Request Date",
      sort: true,
      headerFormatter: headerSpan,
      formatter: (cell, row) => {
        const date = new Date(cell);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        return <div className="text-center">{formattedDate}</div>;
      },
    },
    {
      dataField: "",
      text: "Action",
      sort: false,
      headerFormatter: headerSpan,
      formatter: ActionFiled,
    },
  ];
  const handleTableChange = (
    type,
    { page, sizePerPage, searchText, sortField, sortOrder, data }
  ) => {
    let result;
    if (sortOrder === "asc") {
      result = data.sort((a, b) => {
        if (a[sortField] > b[sortField]) {
          return 1;
        } else if (b[sortField] > a[sortField]) {
          return -1;
        }
        return 0;
      });
    } else {
      result = data.sort((a, b) => {
        if (a[sortField] > b[sortField]) {
          return -1;
        } else if (b[sortField] > a[sortField]) {
          return 1;
        }
        return 0;
      });
    }
    setDataList(result);
    if (type === "pagination") {
      setPageNumber(page);
      getPage(page);
    }
  };
  const tableData = {
    columns: columns,
    data: dataList,
    keyId: "id",
    loading: isLoader,
    searching: false,
    page: pageNumber,
    totalRecord: totalRecord,
    sizePerPage: 10,
    handleTableChange: handleTableChange,
  };
  useEffect(() => {
    getPage(1);
  }, [requestState]);
  const statusTypes = [
    { value: 1, label: "approved" },
    { value: 2, label: "pending" },
    { value: 3, label: "rejected" },
  ];
  const rowitem = dataList?.filter((item) => item?.id === requestInfo?.id);
  return (
    <>
      <div className="tab_container">
        <div style={{ marginBottom: "20px" }}>
          <Select
            placeholder={"Select Status type"}
            onChange={(e) => {
              setRequestState(e?.label);
            }}
            options={statusTypes}
          />
        </div>
        {isLoader ? (
          <div>loading...</div>
        ) : (
          <>
            {dataList?.length > 0 ? (
              <RequestedMawthooqDataTable TableData={tableData} />
            ) : (
              <div>no request at the current time</div>
            )}
          </>
        )}
        <RequestModal
          isRequestModal={isRequestModal}
          closeModal={closeModal}
          requestInfo={requestInfo}
          title={`${rowitem?.[0]?.agent_first_name} mawthooq request details`}
        />
      </div>
    </>
  );
};
export default RequestMawthooqWrapper;
