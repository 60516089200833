import { useEffect, useState } from "react";
import { jwtDecode } from "../../helpers/jwt_helper";
import { getAllCompany } from "../Companies/CompaniApiHelper";
import RMDataTable from "./RMDataTable";
import RequestModal from "../RequestedSocialMedia/RequestModal";
import { apiHalper } from "../../helpers/ApiHelper";
import { toast } from "react-toastify";
import ConfirmModal from "../ConfirmModal";
const RequestMedia = () => {
  const [dataList, setDataList] = useState();
  const [approveData, setApproveData] = useState();
  const [isLoader, setIsLoader] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [isRequestModal, setIsRequestModal] = useState(false);
  const [isUserProModal, setIsUserProModal] = useState(false);
  const [rejectData, setRejectData] = useState();
  const closeModal = () => {
    setIsRequestModal(false);
  };
  const getPage = async (page) => {
    setIsLoader(true);
    const res = await getAllCompany(
      `getRequestedSocialPlatforms?page_no=${page}`,
      "GET"
    );
    if (res.data.status === true) {
      setIsLoader(false);
      const data = jwtDecode(res.data.data);
      console.log(data, "data");
      setDataList(data.data);
      setTotalRecord(data.count);
    }
  };
  const headerSpan = (column, colIndex) => {
    switch (column.dataField) {
      default:
        return (
          <span className={column.sort ? "header_span sort" : "header_span"}>
            {column.text}
          </span>
        );
    }
  };
  const statusField = (cell, row) => {
    if (cell === 1) {
      return (
        <button
          type="button"
          className={"btn-status green margin_0auto"}
          disabled
        >
          <span className="text-white">{"Approved"}</span>
        </button>
      );
    }
    if (cell === 2) {
      return (
        <button
          type="button"
          className={"btn-status orange  margin_0auto"}
          disabled
        >
          {"Pending"}
        </button>
      );
    } else {
      return (
        <button
          type="button"
          className={"btn-status red  margin_0auto"}
          disabled
        >
          {"Rejected"}
        </button>
      );
    }
  };
  const RejectRequest = async () => {
    const formData = new FormData();
    formData.append("id", rejectData.id);
    const headers = {
      Authorization: localStorage.getItem("token")
    };
    const res1 = await apiHalper(
      "rejectRequestedSocialPlatforms",
      "POST",
      formData,
      headers
    );
    if (res1.data.status === true) {
      getPage(1);
      toast.success("Reject Request Successfully!");
      setIsUserProModal(false);
    } else {
      toast.error("Reject Request  Failed");
      setIsUserProModal(false);
    }
  };
  const ActionFiled = (cell, row) => {
    console.log(cell, row);
    if (cell === 2) {
      return (
        <>
          <div className="d-flex">
            <button
              type="button"
              className={"btn-status blue margin_0auto"}
              onClick={() => {
                setApproveData(row);
                setIsRequestModal(true);
              }}
            >
              {"Approve"}
            </button>
            <button
              type="button"
              className={"btn-status red margin_0auto"}
              onClick={() => {
                setIsUserProModal(true);
                setRejectData(row);
              }}
            >
              {"Reject"}
            </button>
          </div>
        </>
      );
    } else {
      return <div className="text-center">{"-"}</div>;
    }
  };
  const imageField = (cell, row) => {
    console.log(cell, row, "cell");
    const url = jwtDecode(localStorage.getItem("filePath"));
    return (
      <>
        {row.filename ? (
          <div
            className="action_group text-center"
            style={{ height: "75px", width: "75px" }}
          >
            <img
              src={`${url.company}${row.filename}`}
              className="w-100 h-100"
            />
          </div>
        ) : (
          "-"
        )}
      </>
    );
  };
  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      headerFormatter: headerSpan
    },
    {
      dataField: "filename",
      text: "Image",
      sort: false,
      headerFormatter: headerSpan,
      formatter: imageField
    },
    {
      dataField: "link",
      text: "Link",
      sort: true,
      headerFormatter: headerSpan
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      headerFormatter: headerSpan,
      formatter: statusField
    },
    {
      dataField: "status",
      text: "Action",
      sort: false,
      headerFormatter: headerSpan,
      formatter: ActionFiled
    }
    // {
    //   dataField: "link",
    //   text: "Link",
    //   sort: true,
    //   headerFormatter: headerSpan,
    // },
    // {
    //   dataField: "status",
    //   text: "Status",
    //   sort: true,
    //   headerFormatter: headerSpan,
    // },
    // {
    //   dataField: "action",
    //   text: "Action",
    //   headerFormatter: headerSpan,
    // },
  ];
  const handleTableChange = (
    type,
    { page, sizePerPage, searchText, sortField, sortOrder, data }
  ) => {
    console.log(
      type,
      page,
      sizePerPage,
      searchText,
      sortField,
      sortOrder,
      data
    );
    let result;
    // if (type === "search") {
    //   if (!searchText) {
    //     getList();
    //     getPage(1);
    //   } else {
    //     const getdata = async () => {
    //       const res = await getAllCompany(
    //         `getCompany/${page}/${searchText}`,
    //         "GET"
    //       );
    //       if (res.data.status === true) {
    //         setIsLoader(false);
    //         const data = jwtDecode(res.data.data);
    //         setTotalRecord(data.data.length);
    //         setCompanyList(data.data);
    //       }
    //     };
    //     getdata();
    //   }
    // }
    if (sortOrder === "asc") {
      result = data.sort((a, b) => {
        if (a[sortField] > b[sortField]) {
          return 1;
        } else if (b[sortField] > a[sortField]) {
          return -1;
        }
        return 0;
      });
    } else {
      result = data.sort((a, b) => {
        if (a[sortField] > b[sortField]) {
          return -1;
        } else if (b[sortField] > a[sortField]) {
          return 1;
        }
        return 0;
      });
    }
    setDataList(result);
    // this.setState(() => ({
    //     data: result
    // }))
    if (type === "pagination") {
      setPageNumber(page);
      getPage(page);
      // this.setState(
      //   {
      //     page,
      //     sizePerPage,
      //   },
      //   () => {
      //     getCoupList(page, searchText);
      //     this.getAllCoupon();
      //   }
      // );
    }
  };
  const tableData = {
    columns: columns,
    data: dataList,
    keyId: "id",
    loading: isLoader,
    searching: false,
    // message: messageApi !== '' ? messageApi : 'Table is Empty',
    // noDataIndication: <NoDataIndication />,
    page: pageNumber,
    totalRecord: totalRecord,
    sizePerPage: 10,
    handleTableChange: handleTableChange
    // overlay:overlayFactory({ spinner: true, styles: { overlay: (base) => ({...base, background: 'rgba(255, 0, 0, 0.5)'}) } })
  };
  useEffect(() => {
    // getList();
    getPage(1);
    // getData()
  }, []);
  const modalCloseConfirm = () => {
    setIsUserProModal(false);
  };
  const confirmModalData = {
    isOpen: isUserProModal,
    modalClose: modalCloseConfirm,
    text: "Reject user Request?",
    confirmHandler: RejectRequest
  };
  return (
    <>
      <div className="tab_container">
        {/* <button type="button" className="btn_addUsers" onClick={()=>addUser()}>Add</button> */}
        {/* <div className="select_wrap users_filters">
                    <Select value={selectedOption} isClearable={isClearable} placeholder={"Select"} onChange={this.handleChange} options={options} />
                </div> */}
        {/* <TableView TableData={tableData} />   */}
        {/* <DataTable TableData={tableData} />*/}
        {dataList && <RMDataTable TableData={tableData} />}
        <RequestModal
          isRequestModal={isRequestModal}
          closeModal={closeModal}
          approveData={approveData}
          getPage={getPage}
        />
        <ConfirmModal modalData={confirmModalData} />
      </div>
    </>
  );
};
export default RequestMedia;
