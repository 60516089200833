import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL || "https://fs6jtboen7.execute-api.us-east-2.amazonaws.com/dev/";

const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

axiosInstance.interceptors.request.use(
    config => {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers.Authorization = token;
        }
        return config;
    },
    error => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
    response => response,
    error => Promise.reject(error)
);

export default axiosInstance;

