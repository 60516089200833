import { useState } from "react";
import { Modal } from "react-bootstrap";
import { jwtDecode } from "../../helpers/jwt_helper";
import { ToastContainer, toast } from "react-toastify";
import { apiHalper } from "../../helpers/ApiHelper";
const RequestModal = ({ isRequestModal, closeModal, requestInfo, title }) => {
  const [note, setNote] = useState("");
  const user = jwtDecode(localStorage.getItem("token"));
  const handleSubmit = async () => {
    const body = {
      reviewer_id: user?.id,
      review_notes: "note",
      status: requestInfo?.status
    };
    const headers = {
      Authorization: localStorage.getItem("token")
    };
    await apiHalper(
      `verification/mawthooq/request-status/${requestInfo?.id}`,
      "PATCH",
      body,
      headers
    )
      .then(() => {
        toast.success("Request Updated Successfully");
        closeModal();
      })
      .catch(() => {
        toast.error("Failed to Update");
        closeModal();
      })
      .finally(() => {
        closeModal();
      });
  };
  return (
    <>
      <Modal
        show={isRequestModal}
        className="modal_design"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="modal_view">
            <div className="modal_header">
              <h4 className="model_title">
                {title}
                <button
                  type="button"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  <span className="close_icon"></span>
                </button>
              </h4>
            </div>
            <div className="body_modals body_modals2">
              <div>
                <div className="form_body" style={{ minHeight: "auto" }}>
                  <div className="form_group2">
                    <label>Review Note</label>
                    <textarea
                      type="text"
                      style={{ height: "100px" }}
                      placeholder="please write your details"
                      className={"form_control2"}
                      value={note}
                      onChange={(e) => {
                        setNote(e.target.value);
                      }}
                      autoComplete="off"
                    ></textarea>
                  </div>
                </div>
                <div className="bg_submit2 mr-top">
                  <button
                    onClick={handleSubmit}
                    type="submit"
                    className="btn-submit"
                    disabled={note.length === 0}
                  >
                    confirm
                  </button>
                  <p>
                    Or{" "}
                    <button
                      type="button"
                      className="btn_cancel2"
                      onClick={() => closeModal()}
                    >
                      Cancel
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default RequestModal;
