import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import BalanceIcon from "../../assets/images/balance-icon.svg";
import UsersIcon from "../../assets/images/users_icon.svg";
import JobsIcon from "../../assets/images/all_jobs-icon.svg";
import OffersIcon from "../../assets/images/offers-icon.svg";
import NotificationIcon from "../../assets/images/notification-icon.svg";
import ReportsIcon from "../../assets/images/reports-icon.svg";
import SEOIcon from "../../assets/images/seo-icon.svg";
import ServicesIcon from "../../assets/images/services-icon.svg";
import ReviewsIcon from "../../assets/images/reviews_icon.svg";
import PartnerIcon from "../../assets/images/partner-icon.svg";
import SettingsIcon from "../../assets/images/settings_icon.svg";
import LocationIcon from "../../assets/images/location-sign.svg";
import AllChatIcons from "../../assets/images/allchat.svg";
import ContactUSIcons from "../../assets/images/contact_icon.svg";
import Dropdown from "react-bootstrap/Dropdown";
import "./index.css";
import { jwtDecode } from "../../helpers/jwt_helper";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      showDropdown: false,
      showButtonName: ""
    };
  }

  dropDownClick = () => {
    this.setState((prevState) => ({
      isActive: !prevState.isActive
    }));
  };
  toggleButton = (id) => {
    console.log(id);

    this.setState({
      showDropdown: true
    });
  };

  renderMenu = (data, i) => {
    // console.log(i);
    if (!data.isSubMenu) {
      return (
        <li key={i}>
          <NavLink activeClassName="active" to={data.url}>
            <img src={data.icon} alt={data.alt} /> <span>{data.name}</span>
          </NavLink>
        </li>
      );
    } else {
      if (data.subMenu.length > 0) {
        return (
          <li key={i}>
            <Dropdown>
              <Dropdown.Toggle className={"menLink"} id={"mainMenu" + i}>
                <img src={data.icon} alt={data.alt} />
                <span>{data.name}</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="subMenu_list">
                {data.subMenu.map((info, index) => (
                  <NavLink key={index} activeClassName="active" to={info.url}>
                    <span>{info.name}</span>
                  </NavLink>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </li>
        );
      }
    }
  };

  render() {
    const { isActive } = this.props;

    const user = jwtDecode(localStorage.getItem("token"));
    // console.log(user)
    var profileType = user.profile_type_id;
    var menuList = [];
    if (profileType === 6) {
      menuList = [
        {
          name: "Reports",
          url: "/reports",
          icon: ReportsIcon,
          alt: "Reports",
          isSubMenu: false,
          subMenu: []
        },
        {
          name: "Push Notifications",
          url: "/pushoffers",
          icon: NotificationIcon,
          alt: "Push Notifications",
          isSubMenu: false,
          subMenu: []
        },
        {
          name: "Coupons",
          url: "/offers",
          icon: OffersIcon,
          alt: "Coupons",
          isSubMenu: false,
          subMenu: []
        }
        // {
        //     name: 'Hire', url: '/seo/services', icon: SEOIcon, alt: 'seo', isSubMenu: true, subMenu: [
        //         { name: 'Hire by Category', url: '/seo/menu' },
        //         { name: 'Hire by Skills', url: '/seo/services' },
        //         { name: 'Page Content', url: '/seo/content' },
        //         { name: 'NFT Page Content', url: '/seo/NFT-content' },
        //         { name: 'Top Skills', url: '/seo/topskills' },
        //         { name: 'Trending Skills', url: '/seo/trendingskills' },
        //         { name: 'Featured Categories', url: '/seo/featuredcategories' },
        //     ],

        // },
        // {
        //     name: 'Services', url: '/seo/services', icon: ServicesIcon, alt: 'seo', isSubMenu: true, subMenu: [
        //         { name: 'Services by Category', url: '/seo/services-category' },
        //         { name: 'Services by Skills', url: '/seo/services-skills' },
        //     ]
        // }
      ];
    } else if (profileType === 9) {
      menuList = [
        {
          name: "All Jobs",
          url: "/allJobs",
          icon: JobsIcon,
          alt: "All Jobs",
          isSubMenu: false,
          subMenu: []
        },
        {
          name: "All Gigs",
          url: "/all-gigs",
          icon: UsersIcon,
          alt: "All Gigs",
          isSubMenu: false,
          subMenu: []
        },
        {
          name: "Users",
          url: "/users",
          icon: UsersIcon,
          alt: "Users",
          isSubMenu: false,
          subMenu: []
        },
        {
          name: "Best Gigs",
          url: "/best-gigs",
          icon: UsersIcon,
          alt: "Best Gigs",
          isSubMenu: false,
          subMenu: []
        },
        {
          name: "Coupons",
          url: "/offers",
          icon: OffersIcon,
          alt: "Coupons",
          isSubMenu: false,
          subMenu: []
        },
        {
          name: "Reviews",
          url: "/reviews",
          icon: ReviewsIcon,
          alt: "Reviews",
          isSubMenu: false,
          subMenu: []
        }
        //     {
        //         name: 'Hire', url: '/seo/services', icon: SEOIcon, alt: 'seo', isSubMenu: true, subMenu: [
        //             { name: 'Hire by Category', url: '/seo/menu' },
        //             { name: 'Hire by Skills', url: '/seo/services' },
        //             { name: 'Page Content', url: '/seo/content' },
        //             { name: 'NFT Page Content', url: '/seo/NFT-content' },
        //             { name: 'Top Skills', url: '/seo/topskills' },
        //             { name: 'Trending Skills', url: '/seo/trendingskills'},
        //             { name: 'Featured Categories', url: '/seo/featuredcategories' },
        //         ],
        //     },
        //     {
        //         name: 'Services', url: '/seo/services', icon: ServicesIcon, alt: 'seo', isSubMenu: true, subMenu: [
        //             { name: 'Services by Category', url: '/seo/services-category' },
        //             { name: 'Services by Skills', url: '/seo/services-skills' },
        //         ]
        //     }
      ];
    } else if (profileType === 7) {
      menuList = [
        {
          name: "All Jobs",
          url: "/allJobs",
          icon: JobsIcon,
          alt: "All Jobs",
          isSubMenu: false,
          subMenu: []
        },
        {
          name: "All Gigs",
          url: "/all-gigs",
          icon: UsersIcon,
          alt: "All Gigs",
          isSubMenu: false,
          subMenu: []
        },
        {
          name: "Location",
          url: "/location",
          icon: LocationIcon,
          alt: "Location",
          isSubMenu: false,
          subMenu: []
        },
        {
          name: "Users",
          url: "/users",
          icon: UsersIcon,
          alt: "Users",
          isSubMenu: false,
          subMenu: []
        },
        {
          name: "Balance",
          url: "/balance",
          icon: BalanceIcon,
          alt: "Balance",
          isSubMenu: false,
          subMenu: []
        },
        {
          name: "Best Gigs",
          url: "/best-gigs",
          icon: UsersIcon,
          alt: "Best Gigs",
          isSubMenu: false,
          subMenu: []
        },
        {
          name: "Job Reports",
          url: "/reports",
          icon: ReportsIcon,
          alt: "Reports",
          isSubMenu: false,
          subMenu: []
        },
        {
          name: "Gig Reports",
          url: "/gig-reports",
          icon: ReportsIcon,
          alt: "Gig Reports",
          isSubMenu: false,
          subMenu: []
        },
        {
          name: "Profile Picture",
          url: "/profile-picture",
          icon: UsersIcon,
          alt: "Profile Picture"
        },
        {
          name: "Reviews",
          url: "/reviews",
          icon: ReviewsIcon,
          alt: "Reviews",
          isSubMenu: false,
          subMenu: []
        },
        {
          name: "Partner",
          url: "/partners",
          icon: PartnerIcon,
          alt: "Partner",
          isSubMenu: false,
          subMenu: []
        },
        {
          name: "Settings",
          url: "/settings/reported_users",
          icon: SettingsIcon,
          alt: "settings",
          isSubMenu: true,
          subMenu: [
            // { name:'Reported Users', url:'/settings/' },
            // { name:'Unverified Users', url:'/settings/' }
            { name: "Reported Users", url: "/settings/reported-user" },
            { name: "Unverified Users", url: "/settings/unverified-user" }
            // { name: 'Hide 24hours', url: '/settings/hide24-user' }
          ]
        }
      ];
    } else if (profileType === 8) {
      // menuList = [
      //     {
      //         name: 'SEO', url: '/seo/services', icon: SEOIcon, alt: 'seo', isSubMenu: true, subMenu: [
      //             { name: 'NFT Page Content', url: '/seo/NFT-content' },
      //         ]
      //     }
      // ]
    } else {
      menuList = [
        {
          name: "All Jobs",
          url: "/allJobs",
          icon: JobsIcon,
          alt: "All Jobs",
          isSubMenu: false,
          subMenu: []
        },
        {
          name: "All Gigs",
          url: "/all-gigs",
          icon: UsersIcon,
          alt: "All Gigs",
          isSubMenu: false,
          subMenu: []
        },
        {
          name: "Balance",
          url: "/balance",
          icon: BalanceIcon,
          alt: "Balance",
          isSubMenu: false,
          subMenu: []
        },
        {
          name: "Location",
          url: "/location",
          icon: LocationIcon,
          alt: "Location",
          isSubMenu: false,
          subMenu: []
        },
        {
          name: "Users",
          url: "/users",
          icon: UsersIcon,
          alt: "Users",
          isSubMenu: false,
          subMenu: []
        },
        {
          name: "Best Gigs",
          url: "/best-gigs",
          icon: UsersIcon,
          alt: "Best Gigs",
          isSubMenu: false,
          subMenu: []
        },
        {
          name: "Job Reports",
          url: "/reports",
          icon: ReportsIcon,
          alt: "Reports",
          isSubMenu: false,
          subMenu: []
        },
        {
          name: "Gig Reports",
          url: "/gig-reports",
          icon: ReportsIcon,
          alt: "Gig Reports",
          isSubMenu: false,
          subMenu: []
        },
        {
          name: "Push Notifications",
          url: "/pushoffers",
          icon: NotificationIcon,
          alt: "Push Notifications",
          isSubMenu: false,
          subMenu: []
        },
        {
          name: "Coupons",
          url: "/offers",
          icon: OffersIcon,
          alt: "Coupons",
          isSubMenu: false,
          subMenu: []
        },
        {
          name: "Categories",
          url: "/categories",
          icon: OffersIcon,
          alt: "categories",
          isSubMenu: false,
          subMenu: []
        },
        {
          name: "Tags",
          url: "/tags",
          icon: OffersIcon,
          alt: "Tags",
          isSubMenu: false,
          subMenu: []
        },
        {
          name: "Companies",
          url: "/companies",
          icon: OffersIcon,
          alt: "Compnies",
          isSubMenu: false,
          subMenu: []
        },
        {
          name: "Profile Picture",
          url: "/profile-picture",
          icon: UsersIcon,
          alt: "Profile Picture"
        },
        {
          name: "Reviews",
          url: "/reviews",
          icon: ReviewsIcon,
          alt: "Reviews",
          isSubMenu: false,
          subMenu: []
        },
        {
          name: "Partner",
          url: "/partners",
          icon: PartnerIcon,
          alt: "Partner",
          isSubMenu: false,
          subMenu: []
        },

        // { name:'Social Survey', url:'/social-survey', icon:SurveyIcon, alt:'Social Survey', isSubMenu:false, subMenu:[] },
        // {
        //     name: 'Hire', url: '/seo/services', icon: SEOIcon, alt: 'seo', isSubMenu: true, subMenu: [
        //         { name: 'Hire by Category', url: '/seo/menu' },
        //         { name: 'Hire by Skills', url: '/seo/services' },
        //         { name: 'Page Content', url: '/seo/content' },
        //         { name: 'NFT Page Content', url: '/seo/NFT-content' },
        //         { name: 'Top Skills', url: '/seo/topskills' },
        //         { name: 'Trending Skills', url: '/seo/trendingskills'},
        //         { name: 'Featured Categories', url: '/seo/featuredcategories' },
        //     ],
        // },
        // {
        //     name: 'Services', url: '/seo/services', icon: ServicesIcon, alt: 'seo', isSubMenu: true, subMenu: [
        //         { name: 'Services by Category', url: '/seo/services-category' },
        //         { name: 'Services by Skills', url: '/seo/services-skills' },
        //     ]
        // },
        {
          name: "Settings",
          url: "/settings/reported_users",
          icon: SettingsIcon,
          alt: "settings",
          isSubMenu: true,
          subMenu: [
            // { name:'Reported Users', url:'/settings/' },
            // { name:'Unverified Users', url:'/settings/' }
            { name: "Reported Users", url: "/settings/reported-user" },
            { name: "Unverified Users", url: "/settings/unverified-user" }
            // { name: 'Hide 24hours', url: '/settings/hide24-user' }
          ]
        },
        {
          name: "All Chat",
          url: "/allchatlist",
          icon: AllChatIcons,
          alt: "AllChatIcons",
          isSubMenu: false,
          subMenu: []
        },
        {
          name: "ContactUs",
          url: "/contactlist",
          icon: ContactUSIcons,
          alt: "ContactUSIcons",
          isSubMenu: false,
          subMenu: []
        },
        {
          name: "Feedbacks",
          url: "/feedbacks",
          icon: ContactUSIcons,
          alt: "ContactUSIcons",
          isSubMenu: false,
          subMenu: []
        },
        {
          name: "Requested Social Media",
          url: "/requestMedia",
          icon: OffersIcon,
          alt: "RequestMedia",
          isSubMenu: false,
          subMenu: []
        },
        {
          name: "Requested Company",
          url: "/requestCompany",
          icon: OffersIcon,
          alt: "RequestCompany",
          isSubMenu: false,
          subMenu: []
        },
        {
          name: "Social Platform",
          url: "/socialPlatform",
          icon: OffersIcon,
          alt: "SocialPlatform",
          isSubMenu: false,
          subMenu: []
        },
        {
          name: "Requested Mawthooq",
          url: "/mawthooqRequestList",
          icon: ContactUSIcons,
          alt: "SocialPlatform",
          isSubMenu: false,
          subMenu: []
        }

        // { name:'', url:'' },
      ];
    }

    return (
      <aside className={!isActive ? "sidebar_wrap" : "sidebar_wrap active"}>
        <ul className="main_sliMenu">
          {menuList.map((data, i) => this.renderMenu(data, i))}
        </ul>
      </aside>
    );
  }
}

const mapStateToProps = (state) => ({
  isActive: state.header.isSidebarActive
});

export default withRouter(connect(mapStateToProps)(Sidebar));
