import React, { Component, Fragment } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Login from "../components/Login";
import Header from "../components/Header";
import Balance from "../components/Balance";
import IncrementDetails from "../components/IncrementDetails";
import userDetails from "../components/userDetails";
import Users from "../components/Users";
import Reports from "../components/Reports";
import PushOffers from "../components/PushOffers";
import ManageNotificaiton from "../components/PushOffers/ManageNotification";
import AllJobs from "../components/AllJobs";
import Offers from "../components/Offers";
import Companies from "../components/Companies";
import PrivateRoute from "./PrivateRoutes";
// import UsedCoupon from '../components/UsedCoupon';
// import CouponReport from '../components/CouponReport';
// import CoupRepDetails from '../components/CoupRepDetails';
import AdminProfile from "../components/AdminProfile";
import Chats from "../components/Chats";
import ChatViews from "../components/ChatViews";
import ProfilePicture from "../components/ProfilePicture";
import { ToastContainer } from "react-toastify";
import Reviews from "../components/Reviews";
import Partners from "../components/Partners";
import PartnerDetail from "../components/Partners/partnerDetail";
import SocialSurvey from "../components/SocialSurvey";
import ServiceSkillsComponent from "../components/SEO/ServiceSkillsComponent";
import addSubcategory from "../components/SEO/ServiceSkillsComponent/addSubcategory";
import addSimilarService from "../components/SEO/ServiceSkillsComponent/addSimilarService";
import PageContent from "../components/SEO/PageContent";
import NFTPageContent from "../components/SEO/NFTPageContent";
import ContentDetails from "../components/SEO/ContentDetails";
import NFTContentDetails from "../components/SEO/NFTContentDetails";
import MenuComponent from "../components/SEO/MenuComponent";
import AddAllServices from "../components/SEO/AddAllServices";
import PageContents from "../components/SEO/AddAllServices/pageContent";
import MakeSkillsTop from "../components/SEO/MakeSkillsTopTrade/topSkill";
import MakeSkillsTrade from "../components/SEO/MakeSkillsTopTrade/trendingSkills";
import FeaturedCategories from "../components/SEO/MakeSkillsTopTrade/featuredCategories";
import pageFAQs from "../components/SEO/AddAllServices/pageFAQs";
import metaTag from "../components/SEO/AddAllServices/metaTag";
import EditPageContentMain from "../components/SEO/EditMainPageContent/EditPageContentMain";
import ReportedUser from "../components/Settings/ReportedUser";
import UnverifiedUser from "../components/Settings/UnverifiedUser";
import hide24hours from "../components/Settings/hide24hours";
import { jwtDecode } from "../helpers/jwt_helper";
import BestSellers from "../components/BestSeller";
import AgentList from "../components/BestSeller/agent";
import MawthooqRequestList from "../components/RequestedMawthooq/RequestedMawthooqList";
// import ApprovedList from '../components/ProfilePicture/ApprovedList';
// import RejectedProfile from '../components/ProfilePicture/RejectedProfile';
// import NoProfileList from '../components/ProfilePicture/NoProfileList';
import AllGigs from "../components/AllGigs";

import ServicesByCategory from "../components/SEO/ServicesByCategory";
import ServicesBySkillPage from "../components/SEO/ServicesBySkillPage";

import GigDetail from "../components/GigsDetails";

import Location from "../components/Location";
import GigReport from "../components/Reports/GigReport";
import ChatHistory from "../components/chathistory/ChatHistory";
import AllChatList from "../components/allchatlist/AllChatList";
import ContactList from "../components/ContactUsList";
import FeedbackList from "../components/Feedbacks";
import RequestMedia from "../components/RequestedSocialMedia/RequestMedia";
import RequestCompany from "../components/RequestedCompany/RequestCompany";
import SocialPlatform from "../components/SocialPlatform/SocialPlatform";
import Categories from "../components/Categories/Categories";
import Tags from "../components/Tags/Tags";

class Routes extends Component {
  render() {
    const { isActive } = this.props;
    const token = localStorage.getItem("token");
    const user = token ? jwtDecode(token) : null;

    const routes = [
      { path: "/allJobs", component: AllJobs },
      { path: "/chathistory", component: ChatHistory },
      { path: "/allchatlist", component: AllChatList },
      { path: "/contactlist", component: ContactList },
      { path: "/feedbacks", component: FeedbackList },
      { path: "/requestMedia", component: RequestMedia },
      { path: "/requestCompany", component: RequestCompany },
      { path: "/socialPlatform", component: SocialPlatform },
      { path: "/mawthooqRequestList", component: MawthooqRequestList },
      { path: "/balance", component: Balance },
      { path: "/jobsDetails/:cid", component: IncrementDetails },
      { path: "/users", component: Users },
      { path: "/best-gigs", component: BestSellers },
      { path: "/best-sellers", component: AgentList },
      { path: "/usersDetails/:id", component: userDetails },
      { path: "/reports", component: Reports },
      { path: "/gig-reports", component: GigReport },
      { path: "/pushoffers", component: PushOffers },
      { path: "/manage-notification", component: ManageNotificaiton },
      { path: "/offers", component: Offers },
      { path: "/companies", component: Companies },
      { path: "/categories", component: Categories },
      { path: "/tags", component: Tags },
      // { path:'/offers/usedcoupon', component: UsedCoupon },
      // { path:'/offers/reportcoupon', component: CouponReport },
      // { path:'/offers/reportcoupon/:cid', component: CoupRepDetails },
      { path: "/profile", component: AdminProfile },
      { path: "/chats", component: Chats },
      { path: "/profile-picture", component: ProfilePicture },
      // { path:'/profile-picture/approved', component: ApprovedList },
      // { path:'/profile-picture/rejected', component: RejectedProfile },
      // { path:'/profile-picture/noProfile', component: NoProfileList },
      // { path:'/profile-picture/:id', component: ProfilePicture },
      { path: "/reviews", component: Reviews },
      { path: "/partners", component: Partners },
      { path: "/partner-detail", component: PartnerDetail },
      { path: "/social-survey", component: SocialSurvey },
      { path: "/seo/services", component: ServiceSkillsComponent },
      { path: "/seo/topskills", component: MakeSkillsTop },
      { path: "/seo/trendingskills", component: MakeSkillsTrade },
      { path: "/seo/featuredCategories", component: FeaturedCategories },
      { path: "/seo/content", component: PageContent },
      { path: "/seo/NFT-content", component: NFTPageContent },
      { path: "/seo/content-details", component: ContentDetails },
      { path: "/seo/nft-content-details", component: NFTContentDetails },
      { path: "/seo/menu", component: MenuComponent },
      { path: "/seo/add-main-services", component: AddAllServices },
      { path: "/seo/add-pagecontent", component: PageContents },
      { path: "/seo/add-faqs", component: pageFAQs },
      { path: "/seo/add-metatag", component: metaTag },
      { path: "/seo/add-subcategory", component: addSubcategory },
      { path: "/seo/add-similar-services", component: addSimilarService },
      { path: "/seo/edit-pagecontent", component: EditPageContentMain },

      { path: "/seo/services-category", component: ServicesByCategory },
      { path: "/seo/services-skills", component: ServicesBySkillPage },

      { path: "/settings/reported-user", component: ReportedUser },
      { path: "/settings/unverified-user", component: UnverifiedUser },
      { path: "/settings/hide24-user", component: hide24hours },
      { path: "/chats/:id", component: ChatViews },

      { path: "/all-gigs", component: AllGigs },
      { path: "/gigDetail/:cid", component: GigDetail },

      { path: "/location", component: Location }
    ];

    // let { path, url } = this.props.match;
    // console.log(this.props);

    return (
      <Fragment>
        {user && <Header />}

        <Switch>
          <Route exact path="/" component={Login} />
          <Fragment>
            <div className={!isActive ? "body_wrap2" : "body_wrap2 active"}>
              {routes.map((route, i) => (
                <PrivateRoute key={i} {...route} />
              ))}
              {/* <PrivateRoute exact path="/allJobs" component={AllJobs} />
                                <PrivateRoute exact path="/balance" component={Balance} />
                                <PrivateRoute exact path="/balance/:id" component={IncrementDetails} />
                                <PrivateRoute exact path="/users" component={Users} />
                                <PrivateRoute exact path="/users/:id" component={userDetails} /> */}
              {/* <PrivateRoute exact path="/users/agents/:id" component={userDetails} /> */}
              {/* <PrivateRoute exact path="/reports" component={Reports} />
                                <PrivateRoute exact path="/pushoffers" component={PushOffers} />
                                <PrivateRoute exact path="/offers" component={Offers} />
                                <PrivateRoute exact path="/offers/usedcoupon" component={UsedCoupon} />
                                <PrivateRoute exact path="/offers/reportcoupon" component={CouponReport} />
                                <PrivateRoute exact path="/offers/reportcoupon/:cid" component={CoupRepDetails} />
                                <PrivateRoute exact path="/profile" component={AdminProfile} />
                                <PrivateRoute exact path="/chats" component={Chats} />
                                <PrivateRoute exact path="/chats/:id" component={ChatViews} />
                                <PrivateRoute exact path="/profile-picture" component={ProfilePicture} />  */}
              {/* <Route exact path="**" component={NotFound} /> */}
            </div>
          </Fragment>
        </Switch>
        <ToastContainer />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isActive: state.header.isSidebarActive
});

export default withRouter(connect(mapStateToProps)(Routes));
